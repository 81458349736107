import { ChartCategory, ContractType, SupplyType } from '@/ui-toolkit/common/types';
import { Period } from '../../Consumption.interface';
import { TariffNode } from '../../Consumption.interfaces';
import { fromFloatToCommaFixedTwo, getTitle, units } from './generate-tooltip';
import { PRICE, CONSUMPTION } from '@utils/constants/category-types';
import { KeyValueString } from '../Consumption.utils';

interface helperProps {
  category: ChartCategory;
  node: TariffNode;
  peak_hours_can_be_used: boolean;
}

const getRows = ({ category, node, peak_hours_can_be_used }: helperProps): KeyValueString => {
  const { base, peak, off_peak, subscription } = node;
  const unit = ' ' + units[category];
  const displayBase = (peak === 0 && off_peak === 0) || !peak_hours_can_be_used;

  if (category === PRICE) {
    if (displayBase) {
      // Return BASE:
      return {
        base: fromFloatToCommaFixedTwo(base) + unit,
        subscription: fromFloatToCommaFixedTwo(subscription) + unit,
      };
    }

    return {
      peak: fromFloatToCommaFixedTwo(peak) + unit,
      off_peak: fromFloatToCommaFixedTwo(off_peak) + unit,
      subscription: fromFloatToCommaFixedTwo(subscription) + unit,
    };
  }

  if (category === CONSUMPTION) {
    if (displayBase) {
      // Return BASE:
      return {
        base: fromFloatToCommaFixedTwo(base) + unit,
      };
    }

    return {
      peak: fromFloatToCommaFixedTwo(peak) + unit,
      off_peak: fromFloatToCommaFixedTwo(off_peak) + unit,
    };
  }

  return {};
};

const getTotal = ({ category, node, peak_hours_can_be_used }: helperProps): string => {
  const { base, peak, off_peak, subscription } = node;
  const unit = ' ' + units[category];
  const displayBase = (peak === 0 && off_peak === 0) || !peak_hours_can_be_used;
  let sum = 0;

  if (category === PRICE) {
    if (displayBase) {
      // Return BASE:
      sum = base + subscription;
    } else {
      sum = peak + off_peak + subscription;
    }
  }

  if (category === CONSUMPTION) {
    if (displayBase) {
      // Return BASE:
      sum = base;
    } else {
      sum = peak + off_peak;
    }
  }

  return fromFloatToCommaFixedTwo(sum) + unit;
};

export const hphcTooltip = ({
  category,
  date,
  node,
  peak_hours_can_be_used,
  period,
}: {
  category: ChartCategory;
  contract: ContractType;
  date: string;
  node: TariffNode;
  peak_hours_can_be_used: boolean;
  period: Period;
  supplyType: SupplyType;
}) => {
  return {
    rows: getRows({ category, node, peak_hours_can_be_used }),
    total: getTotal({ category, node, peak_hours_can_be_used }),
    title: getTitle(date, period),
  };
};
