import axios from 'axios';
import { call, takeLatest, put } from 'redux-saga/effects';
import { ResponseGenerator } from '@/shared/interfaces/ResponseGenerator';
import { resetEnedisResponse, updateAddressData, updateAddressIsLoading } from './AddressSlice';
import { updateAddressError, clearAddressError } from './AddressSlice';
import { clearAddress } from './AddressSlice';
import { runtimeConfig } from '@/runtime-config';
import { ENDPOINTS } from '@utils/constants/endpoints';
const { GOV_ADDRESS_API } = runtimeConfig;

export const addressActions = {
  CHECK_ADDRESS: 'CHECK_ADDRESS',
  ADD_SUPPLY: 'ADD_SUPPLY',
};

export function* checkAddress({ address }: ReturnType<any>) {
  try {
    yield put(resetEnedisResponse());
    yield put(clearAddressError());
    yield put(updateAddressIsLoading(true));

    const instance = axios.create();
    delete instance.defaults.headers.common['Authorization'];
    const response: ResponseGenerator = yield call(instance.get, GOV_ADDRESS_API, { params: { q: address } });

    yield put(updateAddressData(response.data?.features));
  } catch (error: any) {
    yield put(clearAddress());
    yield put(updateAddressError(error));
  } finally {
    yield put(updateAddressIsLoading(false));
  }
}

export function* addSupply({ addressId, supplyType, pod, postal_code, owner_last_name, callback }: ReturnType<any>) {
  try {
    yield call(axios.post, `${ENDPOINTS.ADDRESS_API}/${addressId}/supply`, {
      type: supplyType,
      pod,
      postal_code,
      owner_last_name,
    });

    callback({ result: 'success' });
  } catch (error: any) {
    callback({ result: 'error', code: error?.response?.status });
  }
}

export default function* addressSagas() {
  yield takeLatest(addressActions.CHECK_ADDRESS, checkAddress);
  yield takeLatest(addressActions.ADD_SUPPLY, addSupply);
}
