import axios from 'axios';
import { call, takeLatest, put } from 'redux-saga/effects';
import { ResponseGenerator } from '@/shared/interfaces/ResponseGenerator';
import { clearTariffError, updateTariffData, updateTariffError, updateTariffIsLoading } from './TariffSlice';
import { clearTariff } from './TariffSlice';
import { ENDPOINTS } from '@utils/constants/endpoints';

export const TariffActions = {
  GET_TARIFF_OPTION_ANALYSIS: 'GET_TARIFF_OPTION_ANALYSIS',
};

export interface getTariffAction {
  type: typeof TariffActions.GET_TARIFF_OPTION_ANALYSIS;
  addressId: string;
}

export function* getTariff({ addressId }: getTariffAction) {
  try {
    yield put(clearTariff());
    yield put(clearTariffError());
    yield put(updateTariffIsLoading(true));

    const endpoint = `${ENDPOINTS.ADDRESS_API}/electricity/${addressId}/contract-type`;
    const response: ResponseGenerator = yield call(axios.get, endpoint);

    if (response.status === 204) {
      yield put(updateTariffError(response as any));
      return;
    }

    yield put(updateTariffData(response?.data?.data));
  } catch (error: any) {
    console.error(error);

    yield put(clearTariff());
    yield put(updateTariffError(error));
  } finally {
    yield put(updateTariffIsLoading(false));
  }
}

export default function* consumptionAnalysisSagas() {
  yield takeLatest(TariffActions.GET_TARIFF_OPTION_ANALYSIS, getTariff);
}
