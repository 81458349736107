import { ConsumptionResponse } from '@/containers/Consumption/Consumption.interfaces';
import { ChartSetup, setupConsumptionChart } from '@/containers/Consumption/utils/ConsumptionChart.utils';
import { WEEK } from '@utils/constants/consumption-periods';
import { BASE } from '@utils/constants/contract-types';
import { GAS } from '@utils/constants/supply-types';
import { ChartCategory, ContractType, Period } from '@/ui-toolkit/common/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface GasState {
  category: ChartCategory;
  chart: ChartSetup[] | null;
  consumptionIndex: number;
  contract: ContractType;
  data: ConsumptionResponse | null;
  error: any;
  isLoading: boolean;
  period: Period;
}

const initialState: GasState = {
  category: 'price',
  chart: null,
  consumptionIndex: 0,
  contract: BASE,
  data: null,
  error: null,
  isLoading: false,
  period: WEEK,
};

const updateGasHandler = (state: GasState, action: PayloadAction<{ data: any }>) => {
  const { data } = action.payload;

  const chart = setupConsumptionChart({ data, supplyType: GAS });

  state.chart = chart;
  state.contract = (data?.contract_details?.type || BASE) as ContractType;
  state.data = data;
  state.error = null;
  state.isLoading = false;
};

const updateGasPeriodHandler = (state: GasState, action: PayloadAction<{ period: Period }>) => {
  state.period = action.payload.period;
};

const updateGasIndexHandler = (state: GasState, action: PayloadAction<{ index: number }>) => {
  state.consumptionIndex = action.payload.index;
};

const updateGasCategoryHandler = (state: GasState, action: PayloadAction<{ category: ChartCategory }>) => {
  state.category = action.payload.category;
};

const updateGasErrorHandler = (state: GasState, action: PayloadAction<{ error: any }>) => {
  const { error } = action.payload;

  state.data = null;
  state.isLoading = false;
  state.error = error;
};

const resetGasHandler = (state: GasState) => {
  state.data = null;
  state.isLoading = true;
  state.error = null;
};

export const gasSlice = createSlice({
  name: 'gas',
  initialState,
  reducers: {
    clearGas: () => initialState,
    resetGas: resetGasHandler,
    updateGas: updateGasHandler,
    updateGasCategory: updateGasCategoryHandler,
    updateGasError: updateGasErrorHandler,
    updateGasIndex: updateGasIndexHandler,
    updateGasPeriod: updateGasPeriodHandler,
  },
});

export const { updateGasError, updateGas, resetGas, updateGasPeriod, updateGasIndex, updateGasCategory, clearGas } =
  gasSlice.actions;

export default gasSlice.reducer;
