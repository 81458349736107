import { ReactElement, useState } from 'react';

import IconExpandMore from '@icons/IconExpandMore';
import styles from './Accordion.module.scss';

export interface AccordionProps {
  title: string;
  content: ReactElement;
  onOpen?: () => void;
  onClose?: () => void;
}

const Accordion = ({ title, content, onOpen, onClose }: AccordionProps) => {
  const [open, setOpen] = useState(false);
  const styleOpen = open ? styles.opened : styles.closed;

  const toggle = () => {
    const nextState = !open;

    setOpen(nextState);

    nextState ? onOpen?.() : onClose?.();
  };

  return (
    <div className={styles.Accordion}>
      <div className={styles.Accordion__header} onClick={toggle}>
        <h3>{title}</h3>
        <button className={`${styles.Accordion__header__close} ${styleOpen}`}>
          <IconExpandMore />
        </button>
      </div>

      <div className={`${styles.Accordion__body} ${styleOpen}`}>{content}</div>
    </div>
  );
};

export default Accordion;
