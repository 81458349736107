const IconNotification = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_784_9144)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.75 -1H23.75V0H24.75V-1ZM4.287 18.712C4.479 18.904 4.71667 19 5 19H19C19.2833 19 19.5207 18.904 19.712 18.712C19.904 18.5207 20 18.2833 20 18C20 17.7167 19.904 17.4793 19.712 17.288C19.5207 17.096 19.2833 17 19 17H18V10C18 8.61667 17.5833 7.38733 16.75 6.312C15.9167 5.23733 14.8333 4.53333 13.5 4.2V3.5C13.5 3.08333 13.354 2.72933 13.062 2.438C12.7707 2.146 12.4167 2 12 2C11.5833 2 11.2293 2.146 10.938 2.438C10.646 2.72933 10.5 3.08333 10.5 3.5V4.2C9.16667 4.53333 8.08333 5.23733 7.25 6.312C6.41667 7.38733 6 8.61667 6 10V17H5C4.71667 17 4.479 17.096 4.287 17.288C4.09567 17.4793 4 17.7167 4 18C4 18.2833 4.09567 18.5207 4.287 18.712ZM10.588 21.413C10.9793 21.8043 11.45 22 12 22C12.55 22 13.021 21.8043 13.413 21.413C13.8043 21.021 14 20.55 14 20H10C10 20.55 10.196 21.021 10.588 21.413ZM16 17H8V10C8 8.9 8.39167 7.95833 9.175 7.175C9.95833 6.39167 10.9 6 12 6C13.1 6 14.0417 6.39167 14.825 7.175C15.6083 7.95833 16 8.9 16 10V17Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_784_9144'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconNotification;
