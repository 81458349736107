import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  appIsInstalled: false,
  deferredPrompt: null,
  supportsPWA: false,
};

const updateDeferredPromptHandler = (state: any, action: PayloadAction<any>) => {
  state.deferredPrompt = action.payload;
};

const updateAppIsInstalledHandler = (state: any, action: PayloadAction<boolean>) => {
  state.appIsInstalled = action.payload;
};

const updateSupportPwaHandler = (state: any, action: PayloadAction<boolean>) => {
  state.supportsPWA = action.payload;
};

export const installAppCtaSlice = createSlice({
  name: 'installAppCta',
  initialState,
  reducers: {
    updateAppIsInstalled: updateAppIsInstalledHandler,
    updateDeferredPrompt: updateDeferredPromptHandler,
    updateSupportPwa: updateSupportPwaHandler,
    clearInstallAppCta: () => initialState,
  },
});

export const { updateDeferredPrompt, updateAppIsInstalled, updateSupportPwa, clearInstallAppCta } =
  installAppCtaSlice.actions;

export default installAppCtaSlice.reducer;
