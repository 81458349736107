import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectCurrentDeviceType } from '@/store/Common/WindowResizeTool/WindowResizeToolSelector';
import { SCREEN_MIN_1024 } from '@/store/Common/WindowResizeTool/WindowResizeToolSlice';
import LogoTablet from '@/ui-toolkit/logos/LogoTablet';
import MySelectra from '@/ui-toolkit/logos/MySelectra';
import IconHelpCircle from '@icons/IconHelpCircle';
import IconPerson from '@icons/IconPerson';
import IconPowerSwitch from '@icons/IconPowerSwitch';
import InstallAppCta from '../MenuDesktop/components/InstallAppCta/InstallAppCta';
import useMenuDesktop from '../MenuDesktop/useMenuDesktop';
import styles from './Menu.module.scss';
import MenuItem, { MenuItemProps } from './MenuItem';

const footerItems: MenuItemProps[] = [
  {
    id: 'settings',
    icon: <IconPerson />,
    label: 'Mon compte',
    link: '/profile',
  },
];

const Menu = () => {
  const navigate = useNavigate();
  const { deviceType, isMobile } = useSelector(selectCurrentDeviceType);
  const { items, isLoggedIn, openLogoutModal, openFaqsDrawer } = useMenuDesktop({ mode: 'desktop' });

  const Logo = () => {
    if (isMobile) return null;
    if (deviceType === SCREEN_MIN_1024) return <LogoTablet />;
    return <MySelectra />;
  };

  return (
    <div className={styles.Menu}>
      <div className={styles.Menu__logo}>
        <span onClick={() => navigate('/')}>
          <Logo />
        </span>
      </div>

      <div className={styles.Menu__menu}>
        {items.map((item: any) => (
          <MenuItem key={item.label} {...item} />
        ))}

        {!isMobile && <InstallAppCta />}
      </div>

      <div className={styles.Menu__submenu}>
        {!isMobile && isLoggedIn && (
          <>
            {footerItems.map(item => (
              <MenuItem key={item.label} {...item} />
            ))}

            <MenuItem id='faqs' icon={<IconHelpCircle />} label='Questions fréquentes' onClick={openFaqsDrawer} />

            <MenuItem
              id='logout'
              icon={<IconPowerSwitch />}
              label='Déconnexion'
              variant='danger'
              onClick={openLogoutModal}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Menu;
