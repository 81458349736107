/// <reference types="vite/client" />
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { setupListeners } from '@reduxjs/toolkit/query';
import createSagaMiddleware from 'redux-saga';
import Sagas from './Sagas';
import storage from 'redux-persist/lib/storage';

import { rootReducer } from './Reducer';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'addressForm',
    'alerts',
    'consumption',
    'contracts',
    'dynamicPage',
    'favourites',
    'hints',
    'home',
    'tips',
    'installAppCta',
    'modal',
    'onboarding',
    'pdlForm',
    'powerAnalysis',
    'profile',
    'householdComparison',
    'proposals',
    'Tariff',
    'tooltip',
    'drawer',
    'windowResizeTool',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer as any);

const sagaMiddleware = createSagaMiddleware();

export default (preloadedState?: any) => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
    preloadedState,
    devTools: !import.meta.env.PROD,
  });

  sagaMiddleware.run(Sagas);

  const persistor = persistStore(store);
  setupListeners(store.dispatch);

  return {
    store,
    persistor,
  };
};
