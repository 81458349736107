import { StatusType } from '@/ui-toolkit/common/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactElement } from 'react';

export interface ModalStateHeaderProps {
  title?: string;
  subtitle?: string;
  icon?: ReactElement;
  children?: ReactElement | null;
  showCloseButton?: boolean;
}

export interface ModalStateFooterProps {
  status?: StatusType;
  button?: {
    disabled?: boolean;
    icon?: ReactElement;
    label: string;
    onClick: (v?: any) => void;
  } | null;
  messages?: {
    error: string;
  };
  children?: ReactElement[] | null;
}

export interface ModalState {
  isOpen: boolean;
  component: string | null;
  header: ModalStateHeaderProps;
  footer: ModalStateFooterProps;
  props: { [key: string]: any };
}

const initialState: ModalState = {
  isOpen: false,
  component: null,
  header: {
    title: '',
    children: null,
    showCloseButton: true,
  },
  footer: {
    button: null,
    children: null,
  },
  props: {},
};

const updateModalComponentHandler = (
  state: ModalState,
  action: PayloadAction<{ component: string | null; props?: { [key: string]: any } }>,
) => {
  const { component, props } = action.payload;

  const isOpen: boolean = component !== null;

  state.isOpen = isOpen;
  state.component = component;
  state.props = props || {};
};

const updateModalIsOpenHandler = (state: ModalState, action: PayloadAction<any>) => {
  state.isOpen = action.payload;
};

const updateModalPropsHandler = (state: ModalState, action: PayloadAction<any>) => {
  state.props = { ...state.props, ...action.payload };
};

const updateModalHeaderHandler = (state: ModalState, action: PayloadAction<ModalStateHeaderProps>) => {
  const { title, icon, subtitle, children, showCloseButton = true } = action.payload;

  if (!state?.header) {
    return;
  }

  state.header.children = children;
  state.header.icon = icon;
  state.header.showCloseButton = showCloseButton;
  state.header.subtitle = subtitle;
  state.header.title = title;
};

const updateModalFooterHandler = (state: ModalState, action: PayloadAction<ModalStateFooterProps>) => {
  state.footer = action.payload;
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    updateModalComponent: updateModalComponentHandler,
    updateModalIsOpen: updateModalIsOpenHandler,
    updateModalProps: updateModalPropsHandler,
    updateModalFooter: updateModalFooterHandler,
    updateModalHeader: updateModalHeaderHandler,
    clearModal: () => initialState,
  },
});

export const {
  clearModal,
  updateModalComponent,
  updateModalFooter,
  updateModalHeader,
  updateModalIsOpen,
  updateModalProps,
} = modalSlice.actions;

export default modalSlice.reducer;
