const IconExpandMore = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1_434)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25 -1H24V0H25V-1ZM11.6266 14.887C11.7433 14.929 11.8683 14.95 12.0016 14.95C12.135 14.95 12.26 14.929 12.3766 14.887C12.4933 14.8456 12.6016 14.775 12.7016 14.675L17.3016 10.075C17.485 9.89164 17.5766 9.65831 17.5766 9.37498C17.5766 9.09164 17.485 8.85831 17.3016 8.67498C17.1183 8.49164 16.8893 8.39564 16.6146 8.38698C16.3393 8.37898 16.11 8.46664 15.9266 8.64998L12.0016 12.575L8.10165 8.67498C7.91832 8.49164 7.68498 8.39998 7.40165 8.39998C7.11832 8.39998 6.88498 8.49164 6.70165 8.67498C6.51832 8.85831 6.42265 9.08731 6.41465 9.36198C6.40598 9.63731 6.49332 9.86664 6.67665 10.05L11.3016 14.675C11.4016 14.775 11.51 14.8456 11.6266 14.887Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1_434'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconExpandMore;
