import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ConsumptionResponse, GetConsumptionEndpointParams } from '@/containers/Consumption/Consumption.interfaces';
import { ENDPOINTS } from '@utils/constants/endpoints';
import { resetGas, updateGas, updateGasError } from './GasSlice';
import { ResponseGenerator } from '@/shared/interfaces/ResponseGenerator';

export const gasActions = {
  GET_GAS_CONSUMPTION: 'GET_GAS_CONSUMPTION',
};

const removeEmptyValuesFromConsumptionNodes = (apiResponseData: ConsumptionResponse) => ({
  ...apiResponseData,
  consumption: apiResponseData.consumption.filter(
    node => node.total_consumption.base > 0 || node.total_consumption.peak > 0 || node.total_consumption.off_peak > 0,
  ),
});

export function* getGasConsumption({ addressId, period }: any) {
  // console.log(`%c Call GAS ${period}`, 'background: #222; color: #bada55');

  try {
    yield put(resetGas());

    const params: GetConsumptionEndpointParams = { period };
    const endpoint = `${ENDPOINTS.GAS_CONSUMPTION}/${addressId}/consumption`;
    const response: ResponseGenerator = yield call(axios.get, endpoint, { params });

    if (response.status === 204) {
      yield put(updateGasError({ error: 204 }));
      return;
    }

    const { data } = response;
    const cleanApiData = removeEmptyValuesFromConsumptionNodes(data.data);

    yield put(updateGas({ data: cleanApiData }));
  } catch (error: any) {
    console.error(error);
    const parsedError = !error?.response ? { response: { status: 0 } } : error;
    yield put(updateGasError({ error: parsedError }));
  }
}

export default function* consumptionSagas() {
  yield takeLatest(gasActions.GET_GAS_CONSUMPTION, getGasConsumption);
}
