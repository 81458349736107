import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const MOBILE_SMALL = 'mobile-small';
export const MOBILE_MEDIUM = 'mobile-medium';
export const MOBILE_LARGE = 'mobile-large';
export const SCREEN_MIN_768 = 'SCREEN_MIN_768';
export const SCREEN_MIN_1024 = 'SCREEN_MIN_1024';
export const SCREEN_MIN_1280 = 'SCREEN_MIN_1280';
export const SCREEN_MIN_1440 = 'SCREEN_MIN_1440';
export const LAPTOP_LARGE = 'LAPTOP_LARGE';

const getWindowtype = (windowWidth: number) => {
  if (windowWidth >= 1440) return SCREEN_MIN_1440;
  if (windowWidth >= 1280) return SCREEN_MIN_1280;
  if (windowWidth >= 1024) return SCREEN_MIN_1024;
  if (windowWidth >= 768) return SCREEN_MIN_768;
  if (windowWidth >= 425) return MOBILE_LARGE;
  if (windowWidth >= 375) return MOBILE_MEDIUM;
  return MOBILE_SMALL;
};

export interface WindowResizeToolState {
  deviceType:
    | typeof MOBILE_SMALL
    | typeof MOBILE_MEDIUM
    | typeof MOBILE_LARGE
    | typeof SCREEN_MIN_768
    | typeof SCREEN_MIN_1280
    | typeof SCREEN_MIN_1024
    | typeof SCREEN_MIN_1440
    | typeof LAPTOP_LARGE;
}

const initialState: WindowResizeToolState = {
  deviceType: getWindowtype(window?.innerWidth),
};

const updateCurrentDeviceTypeHandler = (state: WindowResizeToolState, action: PayloadAction<number>) => {
  const windowWidth = action.payload;
  state.deviceType = getWindowtype(windowWidth);
};

export const windowResizeToolSlice = createSlice({
  name: 'windowResizeTool',
  initialState,
  reducers: {
    updateCurrentDeviceType: updateCurrentDeviceTypeHandler,
    clearWindowResizeTool: () => initialState,
  },
});

export const { clearWindowResizeTool, updateCurrentDeviceType } = windowResizeToolSlice.actions;

export default windowResizeToolSlice.reducer;
