import { ReactElement } from 'react';

import { Size, Variant } from '@/ui-toolkit/common/types';
import styles from './Button.module.scss';

export interface ButtonProps {
  id: string;
  border?: 'pill' | 'normal';
  disabled?: boolean;
  fill?: 'plain' | 'ghost';
  fullwidth?: boolean;
  iconLeft?: ReactElement;
  iconRight?: ReactElement;
  label: string;
  size?: Size;
  variant?: Variant;
  onClick: (value?: any) => void;
}

const Button = ({
  id,
  border = 'normal',
  disabled = false,
  fill = 'plain',
  fullwidth,
  iconLeft,
  iconRight,
  label,
  size = 'md',
  variant = 'primary',
  onClick,
}: ButtonProps) => {
  const widthStyle = fullwidth ? styles.fullwidth : '';
  return (
    <button
      id={id}
      className={`
        ${styles.Button} 
        ${styles[`Button__variant-${variant}`]} 
        ${styles[`fill-${fill}`]}
        ${styles[`Button__size-${size}`]}
        ${styles[`border-${border}`]}
        ${widthStyle}
        `}
      disabled={disabled}
      onClick={onClick}
    >
      {iconLeft && <span className={styles.iconLeft}>{iconLeft}</span>}

      <span className={styles.label}>{label}</span>

      <span className={styles.background} />

      {iconRight && <span className={styles.iconRight}>{iconRight}</span>}
    </button>
  );
};

export default Button;
