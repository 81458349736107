import { Size, Variant } from '@/ui-toolkit/common/types';
import styles from './NotificationBubble.module.scss';

export interface NotificationBubbleProps {
  label: string;
  variant?: Variant;
  size?: Size;
}

const NotificationBubble = ({ label, variant = 'danger', size = 'md' }: NotificationBubbleProps) => {
  return (
    <div className={`${styles.NotificationBubble} ${styles[variant]} ${styles[`NotificationBubble__size-${size}`]}`}>
      <span>{label}</span>
    </div>
  );
};

export default NotificationBubble;
