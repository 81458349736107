import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DynamicPageProps } from './DynamicPage.interface';

export interface DynamicPageState {
  data: DynamicPageProps | object;
  error: any;
  isLoading: boolean;
}

const initialState: DynamicPageState = {
  data: {},
  error: null,
  isLoading: false,
};

const updateDynamicPageIsLoadingHandler = (state: DynamicPageState, action: PayloadAction<boolean>) => {
  state.isLoading = action.payload;
};

const updateDynamicPageDataHandler = (state: DynamicPageState, action: any) => {
  state.data = action.payload;
};

const updateDynamicPageErrorHandler = (state: DynamicPageState, action: any) => {
  state.error = action.payload;
};

export const dynamicPageSlice = createSlice({
  name: 'dynamicPage',
  initialState,
  reducers: {
    clearDynamicPage: () => initialState,
    updateDynamicPageData: updateDynamicPageDataHandler,
    updateDynamicPageError: updateDynamicPageErrorHandler,
    updateDynamicPageIsLoading: updateDynamicPageIsLoadingHandler,
  },
});

export const { clearDynamicPage, updateDynamicPageData, updateDynamicPageError, updateDynamicPageIsLoading } =
  dynamicPageSlice.actions;

export default dynamicPageSlice.reducer;
