import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { authActions } from '@/containers/Auth/LoginModal/LoginSagas';

const useLoginTracking = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: authActions.LOGIN_TRACKING });
  }, [pathname]);

  return {};
};

export default useLoginTracking;
