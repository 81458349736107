import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import { updateAlertsData, updateAlertsIsLoading } from './AlertsSlice';
import { postAlertAnswerProps } from './AlertsInterfaces';
import { ResponseGenerator } from '@/shared/interfaces/ResponseGenerator';
import { ENDPOINTS } from '@utils/constants/endpoints';

export const alertsActions = {
  GET_ALERTS: 'GET_ALERTS',
  MARK_ALERTS_AS_READ: 'MARK_ALERTS_AS_READ',
  POST_ALERT_ANSWER: 'POST_ALERT_ANSWER',
  DELETE_ALERT: 'DELETE_ALERT',
};

export function* getAlerts() {
  try {
    yield put(updateAlertsIsLoading(true));
    const { data }: ResponseGenerator = yield call(axios.get, ENDPOINTS.ALERTS);

    yield put(updateAlertsData(data.data.alerts));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(updateAlertsIsLoading(false));
  }
}

export function* markAlertsAsRead() {
  try {
    yield call(axios.patch, `${ENDPOINTS.ALERTS}/status`);
  } catch (error) {
    console.error(error);
  }
}

export function* postAlertAnswer({ reply, id }: postAlertAnswerProps) {
  try {
    yield call(axios.post, `${ENDPOINTS.ALERTS}/${id}`, { reply });
  } catch (error) {
    console.error(error);
  }
}

export function* deleteAlert({ alertId }: any) {
  try {
    yield call(axios.delete, `${ENDPOINTS.ALERTS}/${alertId}`);
  } catch (error) {
    console.error(error);
  }
}

export default function* alertsSagas() {
  yield takeLatest(alertsActions.GET_ALERTS, getAlerts);
  yield takeLatest(alertsActions.MARK_ALERTS_AS_READ, markAlertsAsRead);
  yield takeLatest(alertsActions.POST_ALERT_ANSWER, postAlertAnswer);
  yield takeLatest(alertsActions.DELETE_ALERT, deleteAlert);
}
