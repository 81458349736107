import { useNavigate } from 'react-router-dom';

import GenericIllustration from '@/assets/GenericIllustration';
import LogoSelectra from '@/assets/LogoSelectra';
import Button from '@/ui-toolkit/components/Button/Button';
import IconArrowForward from '@icons/IconArrowForward';
import { PATHS } from '@utils/constants/paths';
import styles from './NotFound.module.scss';

const NotFound = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(PATHS.LOGIN);
  };

  return (
    <div className={styles.NotFound} data-test='not-found-page'>
      <a href='/dashboard' className={styles.NotFound__header}>
        <LogoSelectra />
      </a>
      <div className={styles.NotFound__card}>
        <GenericIllustration />
        <h1 className={styles.NotFound__card__title}>Oops ! Vous vous êtes égaré</h1>
        <Button
          id='button-404-cta'
          fullwidth
          label='Revenir sur mon espace'
          onClick={handleClick}
          iconRight={<IconArrowForward />}
        />
      </div>
    </div>
  );
};
export default NotFound;
