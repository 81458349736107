import { createPriceDisabledBar } from './disabled-bar';
import { generateBarsProps, generateBarsReturn } from './generate-bars';
import { HPHC } from '@utils/constants/contract-types';
import { perc } from '../Consumption.utils';
import { PRICE, CONSUMPTION } from '@utils/constants/category-types';

export const hphcBar = (props: generateBarsProps): generateBarsReturn => {
  const { category, node, max, peak_hours_can_be_used, averageSubscription } = props;
  const { base, peak, off_peak, subscription } = node;

  let sum = 0;

  if (category === PRICE) {
    if (base + peak + off_peak === 0) {
      return createPriceDisabledBar(averageSubscription, max, HPHC);
    }

    if ((peak === 0 && off_peak === 0) || !peak_hours_can_be_used) {
      sum = base + subscription;

      return {
        percentages: {
          base: perc(base, sum),
          subscription: perc(subscription, sum),
        },
        totalPercentage: perc(sum, max),
      };
    }

    // Node has values:
    sum = peak + off_peak + subscription;
    return {
      percentages: {
        peak: perc(peak, sum),
        off_peak: perc(off_peak, sum),
        subscription: perc(subscription, sum),
      },
      totalPercentage: perc(sum, max),
    };
  }

  if (category === CONSUMPTION) {
    if (base + peak + off_peak === 0) {
      return {
        percentages: {
          base: 100,
        },
        totalPercentage: 70,
      };
    }

    if ((peak === 0 && off_peak === 0) || !peak_hours_can_be_used) {
      return {
        percentages: {
          base: 100,
        },
        totalPercentage: perc(base, max),
      };
    }

    // Node has values:
    sum = peak + off_peak;

    return {
      percentages: {
        peak: perc(peak, sum),
        off_peak: perc(off_peak, sum),
      },
      totalPercentage: perc(sum, max),
    };
  }

  // Default:
  return {
    percentages: {},
    totalPercentage: 0,
  };
};
