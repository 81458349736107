import { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import useAxiosInterceptor from '@/api/useAxiosInterceptor';
import Drawer from '@/containers/Common/Drawer/Drawer';
import Layout from '@/containers/Common/Layout/Layout';
import useInstallAppCta from '@/containers/Common/MenuDesktop/components/InstallAppCta/useInstallAppCta';
import NotFound from '@/shared/components/NotFound';
import useAppleLogin from '@/shared/hooks/useAppleLogin';
import useLastNameDetection from '@/shared/hooks/useLastNameDetection';
import ScrollTop from '@/ui-toolkit/components/ScrollTop';
import useAppInitializer from '@hooks/useAppInitializer';
import useCurrentAddress from '@hooks/useCurrentAddress';
import { PATHS } from '@utils/constants/paths';
import PageHeaderTag from '../shared/components/PageHeaderTag/PageHeaderTag';
import LazyWithRetry from './LazyWithRetry';
import { ProtectedRoute } from './ProtectedRoute';
import RedirectWithQueryParams from './RedirectWithQueryParams';

const Alerts = LazyWithRetry(() => import('@/containers/Alerts'));
const ConsentForm = LazyWithRetry(() => import('@/containers/Auth/ConsentForm/ConsentForm'));
const Dashboard = LazyWithRetry(() => import('@/containers/Dashboard/Dashboard'));
const Devices = LazyWithRetry(() => import('@/containers/Consumption/Devices/Devices'));
const Dual = LazyWithRetry(() => import('@/containers/Consumption/Dual/Dual'));
const DynamicPage = LazyWithRetry(() => import('@/containers/DynamicPage/DynamicPage'));
const Modal = LazyWithRetry(() => import('@modal/Modal'));
const PasswordRecovery = LazyWithRetry(() => import('@/containers/Auth/PasswordRecovery/PasswordRecovery'));
const Profile = LazyWithRetry(() => import('@/containers/Profile/Profile'));
const SignupPrefill = LazyWithRetry(() => import('@/containers/Auth/SignupPrefill/SignupPrefill'));
const Tips = LazyWithRetry(() => import('@tips/Tips'));
const Validated = LazyWithRetry(() => import('@/containers/Auth/Validated/Validated'));

const authRoutes = () => {
  const { DASHBOARD, LOGIN } = PATHS;

  return (
    <Route path='/auth/'>
      <Route path='' element={<Navigate to='login/' />} />
      <Route path='login' element={<RedirectWithQueryParams to={DASHBOARD} addParams='login=true' />} />
      <Route path='signup' element={<RedirectWithQueryParams to={DASHBOARD} addParams='register=true' />} />
      <Route path='signup-prefill' element={<SignupPrefill />} />
      <Route path='forgot-password' element={<Navigate to={`${DASHBOARD}/?forgot-password=true`} />} />
      <Route path='recovery' element={<PasswordRecovery />} />
      <Route path='verify-email' element={<Validated />} />
      <Route path='token-expired' element={<Navigate to={LOGIN} />} />
    </Route>
  );
};

const dynamicRoutes = () => {
  return <Route path='/suivi-conso-energie/:slug' element={<DynamicPage />} />;
};

const legacyRoutes = () => {
  return (
    <Route path='/fr'>
      <Route path='' element={<Navigate to={PATHS.MARKETPLACE} />} />
      <Route path='consumption-consent-elec' element={<ConsentForm />} />
      <Route path='conseils' element={<Navigate to={PATHS.MARKETPLACE} />} />
    </Route>
  );
};

const AppRoutes = () => {
  useAxiosInterceptor();
  useInstallAppCta();
  useCurrentAddress();
  useLastNameDetection();
  useAppInitializer();
  useAppleLogin();

  return (
    <BrowserRouter>
      <PageHeaderTag />

      <ScrollTop>
        <Drawer />

        <Modal />

        <Layout>
          <Suspense fallback={<span />}>
            <Routes>
              <Route path='/' element={<RedirectWithQueryParams to={PATHS.DASHBOARD} />} />

              <Route path='/dashboard/' element={<Dashboard />} />

              <Route path='/dashboard/global-consumption' element={<Dashboard />} />

              <Route path='/home' element={<Navigate to={PATHS.DASHBOARD} />} />

              <Route path='/conseils/individual/:tipIdUrlParam' element={<Tips />} />

              <Route path='/conseils/*' element={<Tips />} />

              <Route path='/register_success/' element={<Dashboard />} />

              <Route
                path='consumption/devices-consumption/*'
                element={
                  <ProtectedRoute useParams>
                    <Devices />
                  </ProtectedRoute>
                }
              />

              <Route
                path='consumption/global-consumption/'
                element={
                  <ProtectedRoute useParams>
                    <Dual />
                  </ProtectedRoute>
                }
              />

              <Route path='consumption/*' element={<Navigate to='/consumption/global-consumption/' />} />

              <Route
                path='/profile'
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />

              <Route path='/onboarding/' element={<Navigate to={`${PATHS.DASHBOARD}/?onboarding=0`} />} />

              <Route
                path='/alerts'
                element={
                  <ProtectedRoute>
                    <Alerts />
                  </ProtectedRoute>
                }
              />

              {legacyRoutes()}

              {authRoutes()}

              {dynamicRoutes()}

              <Route path='*' element={<NotFound />} />
            </Routes>
          </Suspense>
        </Layout>
      </ScrollTop>
    </BrowserRouter>
  );
};

export default AppRoutes;
