import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { months, monthsShort } from './PowerAnalysis.utils';
import { PowerAnalysisState } from './PowerAnalysis.interface';

const initialState: PowerAnalysisState = {
  analysis: '',
  categories: [],
  error: null,
  is_adapted: false,
  is_three_phase: false,
  isLoading: false,
  labels: [],
  maxPowerDate: '',
  power_subscribed: '',
  series: [],
  start_date: '',
  timesExceeded: 0,
  yearly_price: 0,
};

const clearPowerAnalysisErrorHandler = (state: PowerAnalysisState) => {
  state.error = null;
};

const updatePowerAnalysisIsLoadingHandler = (state: PowerAnalysisState, action: PayloadAction<boolean>) => {
  state.isLoading = action.payload;
};

const updatePowerAnalysisDataHandler = (state: PowerAnalysisState, action: any) => {
  const { analysis, historical, start_date, max_power_date, power_subscribed, is_adapted, is_three_phase } =
    action.payload;

  const historicalKeys = !historical ? [] : Object.keys(historical);
  const mainSerie: number[] = [];
  const comparisonSerie: number[] = [];
  const categories: string[] = [];
  const labels: { title: string; exceeded?: string | null | undefined }[] = [];
  let timesExceeded = 0;

  historicalKeys.forEach((key: string) => {
    const { number_exceeded, max_power } = historical[key];

    // Series:
    mainSerie.push(historical[key].max_power?.toFixed(1));
    comparisonSerie.push(power_subscribed);

    // x axis labels:
    const split = key.split('-');
    const year = split[0].substring(2, 4);
    const monthIndex = parseInt(split[1]) - 1;
    const month = monthsShort[monthIndex];
    categories.push(month + ' ' + year);

    // tooltip labels:
    const powerSubscribedNumber = parseInt(power_subscribed.replace(' kVA', ''));
    const exceeded = number_exceeded ? (max_power - powerSubscribedNumber).toFixed(3) : null;
    labels.push({ title: months[monthIndex] + ' ' + year, exceeded });

    if (number_exceeded) timesExceeded++;
  });

  state.categories = categories;
  state.labels = labels;
  state.maxPowerDate = max_power_date.split('-').reverse().join('-');
  state.power_subscribed = power_subscribed;
  state.series = [
    { name: 'Puissance max. atteinte', data: mainSerie },
    { name: 'Puissance souscrite', data: comparisonSerie },
  ];
  state.start_date = start_date.split('-').reverse().join('-');
  state.timesExceeded = timesExceeded;
  state.is_adapted = is_adapted;
  state.analysis = analysis.replace('\n', '<br></br>');
  state.is_three_phase = is_three_phase;
};

const updatePowerAnalysisErrorHandler = (state: any, action: any) => {
  const error = action.payload;

  state.error = error?.response?.status?.toString() || error?.status?.toString() || '424';
};

export const powerAnalysisSlice = createSlice({
  name: 'powerAnalysis',
  initialState,
  reducers: {
    clearPowerAnalysis: () => initialState,
    updatePowerAnalysisIsLoading: updatePowerAnalysisIsLoadingHandler,
    updatePowerAnalysisData: updatePowerAnalysisDataHandler,
    updatePowerAnalysisError: updatePowerAnalysisErrorHandler,
    clearPowerAnalysisError: clearPowerAnalysisErrorHandler,
  },
});

export const {
  clearPowerAnalysis,
  clearPowerAnalysisError,
  updatePowerAnalysisData,
  updatePowerAnalysisError,
  updatePowerAnalysisIsLoading,
} = powerAnalysisSlice.actions;

export default powerAnalysisSlice.reducer;
