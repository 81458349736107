import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import { KeyValueString } from '@/containers/Consumption/utils/Consumption.utils';
import { runtimeConfig } from '@/runtime-config';
import { ResponseGenerator } from '@/shared/interfaces/ResponseGenerator';
import { getProfile } from '@/store/Common/Profile/ProfileSagas';
import { clearProfile } from '@/store/Common/Profile/ProfileSlice';
import { clearAuth, updateAuthProvider, updateAuthStatus, updateToken } from '@auth/AuthSlice';
import { ENDPOINTS } from '@utils/constants/endpoints';
import { PATHS } from '@utils/constants/paths';
import { STATUS } from '@utils/constants/status';
import { getStatusByCode } from '@utils/status';
import {
  loginProps,
  loginWithAppleProps,
  loginWithGoogleProps,
  loginWithHashProps,
  setTokenProps,
} from './Login.interfaces';

const { APP_API_URL, TOKEN_ID } = runtimeConfig;

export const authActions = {
  LOGIN_TRACKING: 'LOGIN_TRACKING',
  LOGIN_WITH_HASH: 'LOGIN_WITH_HASH',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  LOGIN_WITH_APPLE: 'LOGIN_WITH_APPLE',
  LOGIN_WITH_GOOGLE: 'LOGIN_WITH_GOOGLE',
};

export function* setToken({ headers }: setTokenProps) {
  const { token_type, access_token } = headers;

  if (!token_type || !access_token) return false;

  const newToken = `${headers.token_type} ${headers.access_token}`;

  axios.defaults.headers.common = { Authorization: newToken };

  yield put(updateToken(newToken));

  localStorage.setItem(TOKEN_ID, newToken);

  return true;
}

export function* loginTracking() {
  try {
    const token = localStorage?.getItem(TOKEN_ID);

    if (!token) {
      return;
    }

    const lastLogin: string | null = yield call(() => localStorage.getItem('selectra-last-login'));
    const lastLoginDate = lastLogin && new Date(lastLogin).setHours(0, 0, 0, 0);
    const today = new Date().setHours(0, 0, 0, 0);

    if (today === lastLoginDate) {
      return;
    }

    localStorage.setItem('selectra-last-login', new Date().toString());

    const instance = axios.create();
    instance.defaults.headers['Authorization'] = token;
    yield call(instance.patch, APP_API_URL + ENDPOINTS.LOGIN_TRACKING);

    //
  } catch (error: any) {
    console.log('Login tracking error. ', error);
  }
}

export function* loginSaga({ email, password }: loginProps) {
  try {
    yield put(updateAuthStatus(STATUS.SENDING));

    const response: ResponseGenerator = yield call(axios.post, ENDPOINTS.LOGIN, { email, password });

    if (response.data.error) {
      yield put(updateAuthStatus(STATUS.AUTH_ERROR));
      return;
    }

    yield put(updateAuthStatus(STATUS.SUCCESS));
    yield put(updateAuthProvider('web'));

    const headers = response.data.data;

    yield call(setToken, { headers });
  } catch (error: any) {
    console.error(error);

    yield put(updateAuthStatus(getStatusByCode(error?.response?.status)));
  }
}

export function* loginWithGoogle({ access_token, source }: loginWithGoogleProps) {
  try {
    yield put(updateAuthStatus(STATUS.SENDING));

    const endpoint = APP_API_URL + ENDPOINTS.LOGIN_WITH_GOOGLE;

    let params: KeyValueString = { access_token, source };

    if (source === 'native-app-login') {
      params = { json_web_token: access_token, source };
    }

    const response: ResponseGenerator = yield call(axios.post, endpoint, { ...params });

    if (response.data.error) {
      yield put(updateAuthStatus(STATUS.AUTH_WITH_GOOGLE_ERROR));
      return;
    }

    yield put(updateAuthStatus(STATUS.SUCCESS));
    yield put(updateAuthProvider('google'));

    const headers = response.data.data;

    yield call(setToken, { headers });
  } catch (error: any) {
    console.error(error);

    yield put(updateAuthStatus(STATUS.AUTH_WITH_GOOGLE_ERROR));
  }
}

export function* loginWithApple({ id_token, source }: loginWithAppleProps) {
  try {
    yield put(updateAuthStatus(STATUS.SENDING));

    const appleUserItem = localStorage?.getItem('selectra-apple-login');
    const appleUserItemExists = appleUserItem !== null && appleUserItem !== undefined;

    let params: KeyValueString = {
      id_token,
      source,
    };

    if (appleUserItemExists) {
      const appleUserData = JSON.parse(appleUserItem);

      params = {
        id_token,
        first_name: appleUserData?.name?.firstName || 'n/a',
        last_name: appleUserData?.name?.lastName || 'n/a',
        source,
      };
    }

    const response: ResponseGenerator = yield call(axios.post, ENDPOINTS.LOGIN_WITH_APPLE, params);

    if (response.data.error) {
      yield put(updateAuthStatus(STATUS.AUTH_WITH_APPLE_ERROR));
      return;
    }

    yield put(updateAuthStatus(STATUS.SUCCESS));
    yield put(updateAuthProvider('apple'));

    const headers = response.data.data;

    yield call(setToken, { headers });

    localStorage?.removeItem('selectra-apple-login');
  } catch (error: any) {
    console.error(error);

    yield put(updateAuthStatus(STATUS.AUTH_WITH_APPLE_ERROR));
  }
}

export function* loginWithHashSaga({ email, subscriberType, hash }: loginWithHashProps) {
  const parsedEmail = email.trim().replace(' ', '+');

  try {
    const endpoint = APP_API_URL + ENDPOINTS.LOGIN_WITH_HASH;
    const response: ResponseGenerator = yield call(axios.post, endpoint, {
      email: parsedEmail,
      hash,
      subscriber_type: subscriberType,
    });

    const headers = response.data.data;

    yield call(setToken, { headers });
    yield call(getProfile);
  } catch (error: any) {
    console.error(error);

    if (error?.response?.status === 406) {
      yield put(updateAuthStatus(getStatusByCode(STATUS.AUTH_ERROR)));
    }
  }
}

export function* logOutSaga() {
  try {
    localStorage.removeItem(TOKEN_ID);
    localStorage.removeItem('selectra-user-id');

    sessionStorage.clear();

    yield put(updateToken(null));
    yield put(clearProfile());
    yield put(clearAuth());
    yield call(axios.post, ENDPOINTS.LOGOUT, {});
  } catch (error) {
    console.error(error);
    window.location.replace(PATHS.DASHBOARD);
  }
}

export default function* loginSagas() {
  yield takeLatest(authActions.LOGIN_TRACKING, loginTracking);
  yield takeLatest(authActions.LOGIN_WITH_APPLE, loginWithApple);
  yield takeLatest(authActions.LOGIN_WITH_GOOGLE, loginWithGoogle);
  yield takeLatest(authActions.LOGIN_WITH_HASH, loginWithHashSaga);
  yield takeLatest(authActions.LOGIN, loginSaga);
  yield takeLatest(authActions.LOGOUT, logOutSaga);
}
