import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { PATHS } from '@utils/constants/paths';
import { updateModalComponent } from '@modal/ModalSlice';
import { selectAuthToken } from '@/store/Common/Auth/AuthSelectors';

export const ProtectedRoute = ({ children, useParams }: any) => {
  const token = useSelector(selectAuthToken);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hash = searchParams.get('hash');
  const email = searchParams.get('email');
  const hasHashAndEmailParams = useParams && hash && email;

  useEffect(() => {
    if (token || hasHashAndEmailParams) return;

    navigate(PATHS.HOME);

    dispatch(updateModalComponent({ component: 'loginModal', props: { isOpen: true, wide: 'sm' } }));
  }, [token]);

  return children;
};
