const IconChair = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1920_3008)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.75 -1H23.75V0H24.75V-1ZM4.2875 20.7125C4.47917 20.9042 4.71667 21 5 21C5.28333 21 5.52083 20.9042 5.7125 20.7125C5.90417 20.5208 6 20.2833 6 20V19H18V20C18 20.2833 18.0958 20.5208 18.2875 20.7125C18.4792 20.9042 18.7167 21 19 21C19.2833 21 19.5208 20.9042 19.7125 20.7125C19.9042 20.5208 20 20.2833 20 20V19C20.8333 19 21.5417 18.7083 22.125 18.125C22.7083 17.5417 23 16.8333 23 16V11C23 10.1667 22.7083 9.45833 22.125 8.875C21.5417 8.29167 20.8333 8 20 8V6C20 5.16667 19.7083 4.45833 19.125 3.875C18.5417 3.29167 17.8333 3 17 3H7C6.16667 3 5.45833 3.29167 4.875 3.875C4.29167 4.45833 4 5.16667 4 6V8C3.16667 8 2.45833 8.29167 1.875 8.875C1.29167 9.45833 1 10.1667 1 11V16C1 16.8333 1.29167 17.5417 1.875 18.125C2.45833 18.7083 3.16667 19 4 19V20C4 20.2833 4.09583 20.5208 4.2875 20.7125ZM20 17H4C3.71667 17 3.47917 16.9042 3.2875 16.7125C3.09583 16.5208 3 16.2833 3 16V11C3 10.7167 3.09583 10.4792 3.2875 10.2875C3.47917 10.0958 3.71667 10 4 10C4.28333 10 4.52083 10.0958 4.7125 10.2875C4.90417 10.4792 5 10.7167 5 11V15H19V11C19 10.7167 19.0958 10.4792 19.2875 10.2875C19.4792 10.0958 19.7167 10 20 10C20.2833 10 20.5208 10.0958 20.7125 10.2875C20.9042 10.4792 21 10.7167 21 11V16C21 16.2833 20.9042 16.5208 20.7125 16.7125C20.5208 16.9042 20.2833 17 20 17ZM17 13H7V11C7 10.55 6.90833 10.1417 6.725 9.775C6.54167 9.40833 6.3 9.08333 6 8.8V6C6 5.71667 6.09583 5.47917 6.2875 5.2875C6.47917 5.09583 6.71667 5 7 5H17C17.2833 5 17.5208 5.09583 17.7125 5.2875C17.9042 5.47917 18 5.71667 18 6V8.8C17.7 9.08333 17.4583 9.40833 17.275 9.775C17.0917 10.1417 17 10.55 17 11V13Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1920_3008'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconChair;
