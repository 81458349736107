const IconCross = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_6_1431)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25 -1H24V0H25V-1ZM7.1 18.3L12 13.4L16.9 18.3C17.0833 18.4833 17.3167 18.575 17.6 18.575C17.8833 18.575 18.1167 18.4833 18.3 18.3C18.4833 18.1167 18.575 17.8833 18.575 17.6C18.575 17.3167 18.4833 17.0833 18.3 16.9L13.4 12L18.3 7.1C18.4833 6.91667 18.575 6.68333 18.575 6.4C18.575 6.11667 18.4833 5.88333 18.3 5.7C18.1167 5.51667 17.8833 5.425 17.6 5.425C17.3167 5.425 17.0833 5.51667 16.9 5.7L12 10.6L7.1 5.7C6.91667 5.51667 6.68334 5.425 6.4 5.425C6.11667 5.425 5.88334 5.51667 5.7 5.7C5.51667 5.88333 5.425 6.11667 5.425 6.4C5.425 6.68333 5.51667 6.91667 5.7 7.1L10.6 12L5.7 16.9C5.51667 17.0833 5.425 17.3167 5.425 17.6C5.425 17.8833 5.51667 18.1167 5.7 18.3C5.88334 18.4833 6.11667 18.575 6.4 18.575C6.68334 18.575 6.91667 18.4833 7.1 18.3Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_6_1431'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconCross;
