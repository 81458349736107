import axios from 'axios';
import { call, takeLatest, put, select } from 'redux-saga/effects';
import { ENDPOINTS } from '@utils/constants/endpoints';
import { ResponseGenerator } from '@/shared/interfaces/ResponseGenerator';
import { updateTipsData, updateTipsIsLoading, updateTipsError, clearTipsError, updateCurrentTip } from './TipsSlice';
import { selectTipsData, selectTipsPage } from './TipsSelectors';
import { clearTips } from './TipsSlice';
import { fillFavouritesState, updateTipViewCount } from '../../store/Common/Favourites/FavouritesSlice';
import { runtimeConfig } from '@/runtime-config';
import { clearModal } from '../Common/Modal/ModalSlice';
const { APP_API_URL } = runtimeConfig;

export const TipsActions = {
  GET_TIPS: 'GET_TIPS',
  GET_TIP_BY_ID: 'GET_TIP_BY_ID',
  INCREASE_TIP_VIEW_COUNT: 'INCREASE_TIP_VIEW_COUNT',
};

export function* getTips({ categories, purchaseType }: ReturnType<any>) {
  try {
    yield put(clearTipsError());
    yield put(updateTipsIsLoading(true));

    let endpoint = '';

    switch (purchaseType) {
      case 'favoris':
        endpoint = ENDPOINTS.FAVOURITES;
        break;
      case 'guides':
        endpoint = `${ENDPOINTS.TIPS}/group/list`;
        break;
      case 'comparatifs':
        endpoint = `${ENDPOINTS.TIPS}/carbon-footprint/list`;
        break;
      default:
        endpoint = `${ENDPOINTS.TIPS}/individual/list`;
        break;
    }

    const page: number = yield select(selectTipsPage);
    const take = 50;
    const from = page * take;
    const previousTips: TipsData = yield select(selectTipsData);
    const isFavourite = purchaseType === 'favoris';
    const params = isFavourite ? {} : { from, take, categories: categories.toString() };

    const { data }: ResponseGenerator = yield call(axios.get, APP_API_URL + endpoint, { params });

    let newTips = data.data;

    newTips = {
      items: [...previousTips.items, ...newTips.items],
      total: data.data.total,
    };

    yield put(updateTipsData(newTips));

    yield put(fillFavouritesState({ tipsList: newTips.items }));
  } catch (error: any) {
    console.error('Tips error:', error);

    yield put(clearTips());
    yield put(updateTipsError(error));
  } finally {
    yield put(updateTipsIsLoading(false));
  }
}

export function* getTipById({ tipId }: any) {
  try {
    const endpoint = `${APP_API_URL}${ENDPOINTS.SINGLE_TIP}/${tipId}`;
    yield call(axios.get, endpoint);

    const { data }: ResponseGenerator = yield call(axios.get, endpoint);
    const currentTip = data.data;

    yield put(updateCurrentTip(currentTip));
  } catch (error: any) {
    yield put(clearModal());
    console.error(error);
  }
}

export function* increaseTipViewCount({ tipId }: any) {
  try {
    yield put(updateTipViewCount({ tipId }));

    const endpoint = `${ENDPOINTS.SINGLE_TIP}/${tipId}/view`;
    yield call(axios.patch, endpoint);
  } catch (error: any) {
    console.error(error);
  }
}

export default function* tipsSagas() {
  yield takeLatest(TipsActions.GET_TIPS, getTips);
  yield takeLatest(TipsActions.GET_TIP_BY_ID, getTipById);
  yield takeLatest(TipsActions.INCREASE_TIP_VIEW_COUNT, increaseTipViewCount);
}
