import axios from 'axios';
import { call, takeLatest } from 'redux-saga/effects';

import { ENDPOINTS } from '@utils/constants/endpoints';
import { getTips } from '@tips/TipsSagas';
import { PATHS } from '@utils/constants/paths';
import { toggleFavouriteProps } from './FavouritesInterfaces';

export const favouritesActions = {
  TOGGLE_FAVOURITES: 'TOGGLE_FAVOURITES',
};

export function* toggleFavourite({ tipId, pathname }: toggleFavouriteProps) {
  try {
    yield call(axios.post, `${ENDPOINTS.TOGGLE_TIP_FAVOURITES}/${tipId}`);
  } catch (error) {
    console.error(error);
  } finally {
    if (pathname === PATHS.FAVOURITES) {
      yield call(getTips, { purchaseType: 'favourites' });
    }
  }
}

export default function* favouritesSagas() {
  yield takeLatest(favouritesActions.TOGGLE_FAVOURITES, toggleFavourite);
}
