import axios from 'axios';
import { call, takeLatest, put } from 'redux-saga/effects';

import { updateTipsIsLoading } from './DashboardSlice';
import { ENDPOINTS } from '@utils/constants/endpoints';
import { ResponseGenerator } from '@/shared/interfaces/ResponseGenerator';
import { runtimeConfig } from '@/runtime-config';
import { updateTipsData } from '@tips/TipsSlice';
const { APP_API_URL } = runtimeConfig;

export const homeActions = {
  GET_HOME_TIPS_DATA: 'GET_HOME_TIPS_DATA',
};

export function* getTips() {
  try {
    yield put(updateTipsIsLoading(true));
    const { data }: ResponseGenerator = yield call(axios.get, `${APP_API_URL}${ENDPOINTS.HOME_TIPS}`);

    yield put(updateTipsData(data.data));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(updateTipsIsLoading(false));
  }
}

export default function* homeSagas() {
  yield takeLatest(homeActions.GET_HOME_TIPS_DATA, getTips);
}
