import { authActions } from '@/containers/Auth/LoginModal/LoginSagas';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useSourceParam from './useSourceParam';

const useGoogleToken = () => {
  const { hash } = useLocation();
  const dispatch = useDispatch();
  const { source } = useSourceParam();

  useEffect(() => {
    const result: any = {};
    hash
      .replace('#', '')
      .split('&')
      .forEach(item => {
        result[item.split('=')[0]] = decodeURIComponent(item.split('=')[1]);
      });

    if (result?.access_token) {
      const { access_token } = result;

      dispatch({ type: authActions.LOGIN_WITH_GOOGLE, access_token, source });
    }
  }, [hash]);
};

export default useGoogleToken;
