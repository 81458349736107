const IconHome = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_2617_33)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.75 -1H23.75V0H24.75V-1ZM9 19H6V10L12 5.5L18 10V19H15V14C15 13.7167 14.9042 13.4792 14.7125 13.2875C14.5208 13.0958 14.2833 13 14 13H10C9.71667 13 9.47917 13.0958 9.2875 13.2875C9.09583 13.4792 9 13.7167 9 14V19ZM4 10V19C4 19.55 4.19583 20.0208 4.5875 20.4125C4.97917 20.8042 5.45 21 6 21H10C10.2833 21 10.5208 20.9042 10.7125 20.7125C10.9042 20.5208 11 20.2833 11 20V15H13V20C13 20.2833 13.0958 20.5208 13.2875 20.7125C13.4792 20.9042 13.7167 21 14 21H18C18.55 21 19.0208 20.8042 19.4125 20.4125C19.8042 20.0208 20 19.55 20 19V10C20 9.68333 19.9292 9.38333 19.7875 9.1C19.6458 8.81667 19.45 8.58333 19.2 8.4L13.2 3.9C12.85 3.63333 12.45 3.5 12 3.5C11.55 3.5 11.15 3.63333 10.8 3.9L4.8 8.4C4.55 8.58333 4.35417 8.81667 4.2125 9.1C4.07083 9.38333 4 9.68333 4 10Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_2617_33'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconHome;
