import { Navigate, useSearchParams } from 'react-router-dom';

export interface RedirectWithQueryParamsProps {
  to: string;
  addParams?: string;
}

const RedirectWithQueryParams = ({ to, addParams = '' }: RedirectWithQueryParamsProps) => {
  const [searchParams] = useSearchParams();

  let inheritParams = '';
  searchParams.forEach((value, key) => (inheritParams += `&${key}=${value}`));

  const urlWithParams = `${to}/?${addParams}${inheritParams}`;

  return <Navigate to={urlWithParams} />;
};

export default RedirectWithQueryParams;
