import { ReactElement, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentDeviceType } from '@/store/Common/WindowResizeTool/WindowResizeToolSelector';
import useOutsideAlerter from '@hooks/useOutsideAlerter';
import IconExpandMore from '@icons/IconExpandMore';
import Button from '../Button/Button';
import styles from './AddressDropdown.module.scss';
import useAddressDropdown from './useAddressDropdown';

export interface AddressDropdownProps {
  iconLeft?: ReactElement;
  label?: string;
}

const AddressDropdown = ({ iconLeft }: AddressDropdownProps) => {
  const { isMobile } = useSelector(selectCurrentDeviceType);
  const [expanded, setExpanded] = useState<boolean>(false);
  const wrapperRef = useRef(null);
  const ulRef = useRef<HTMLUListElement>(null);
  const { addressesList, onSelect, openAddNewAddressModal } = useAddressDropdown({ ulRef });
  useOutsideAlerter(wrapperRef, () => setExpanded(false));

  const buttonSize = (() => {
    if (isMobile) return 'xs';
    if (!isMobile) return 'md';
    return 'md';
  })();

  const expandedStyle = expanded ? styles.expanded : styles.contracted;

  if (addressesList?.length === 0)
    return (
      <Button
        id='button-add-address-cta'
        label='Ajouter un logement'
        border='pill'
        fill='ghost'
        size={buttonSize}
        onClick={openAddNewAddressModal}
      />
    );

  if (!addressesList) {
    return null;
  }

  return (
    <div className={styles.AddressDropdown} onClick={() => setExpanded(v => !v)} ref={wrapperRef}>
      {iconLeft && <span className={styles.iconLeft}>{iconLeft}</span>}

      {addressesList.map(address => {
        if (address.selected) {
          return (
            <p key={address.id} className={styles.AddressDropdown__firstOption}>
              {address.icon && (
                <span className={`${styles.optionIcon} ${styles[address.variant]}`}>{address.icon}</span>
              )}
              {address.text}
            </p>
          );
        }
      })}

      <button>
        <IconExpandMore />
      </button>

      <ul className={expandedStyle} ref={ulRef}>
        {addressesList.map(({ id, text, icon, selected, variant }) => (
          <li key={text + id} className={selected ? styles.selected : styles.normal} onClick={() => onSelect(id)}>
            {icon && <span className={`${styles.optionIcon} ${styles[variant || 'primary']}`}>{icon}</span>}

            {text}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AddressDropdown;
