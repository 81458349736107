import { BASE } from '@utils/constants/contract-types';
import { ChartCategory, ContractType, Period } from '@/ui-toolkit/common/types';
import { ChartSetup, setupConsumptionChart } from '@/containers/Consumption/utils/ConsumptionChart.utils';
import { ConsumptionResponse } from '@/containers/Consumption/Consumption.interfaces';
import { ELECTRICITY } from '@utils/constants/supply-types';
import { MONTH, WEEK, YEAR } from '@utils/constants/consumption-periods';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ElectricityState {
  category: ChartCategory;
  chart: ChartSetup[] | null;
  consumptionIndex: number;
  contract: ContractType;
  data: ConsumptionResponse | null;
  devicesMode: boolean;
  error: any;
  isLoading: boolean;
  period: Period;
}

const initialState: ElectricityState = {
  category: 'price',
  chart: null,
  consumptionIndex: 0,
  contract: BASE,
  data: null,
  devicesMode: false,
  error: null,
  isLoading: false,
  period: WEEK,
};

const updateElectricityHandler = (state: ElectricityState, action: PayloadAction<{ data: any }>) => {
  const { data } = action.payload;

  const chart = setupConsumptionChart({ data, supplyType: ELECTRICITY });

  state.chart = chart;
  state.contract = (data?.contract_details?.type || BASE) as ContractType;
  state.data = data;
  state.error = null;
  state.isLoading = false;
};

const updateElectricityIsLoadingHandler = (state: ElectricityState, action: PayloadAction<{ isLoading: any }>) => {
  state.isLoading = action.payload.isLoading;
};

const resetElectricityHandler = (state: ElectricityState) => {
  state.data = null;
  state.isLoading = true;
  state.error = null;
};

const updateElectricityErrorHandler = (state: ElectricityState, action: PayloadAction<{ error: any }>) => {
  const { error } = action.payload;

  state.data = null;
  state.isLoading = false;
  state.error = error;
};

const updateElectricityPeriodHandler = (state: ElectricityState, action: PayloadAction<{ period: Period }>) => {
  state.period = action.payload.period;
};

const updateElectricityIndexHandler = (state: ElectricityState, action: PayloadAction<{ index: number }>) => {
  state.consumptionIndex = action.payload.index;
};

const updateElectricityCategoryHandler = (
  state: ElectricityState,
  action: PayloadAction<{ category: ChartCategory }>,
) => {
  const { category } = action.payload;

  state.category = category;

  if (category === 'devices' && state.period !== MONTH && state.period !== YEAR) {
    state.period = MONTH;
  }
};

const updateDevicesModeHandler = (state: ElectricityState, action: PayloadAction<{ devicesMode: boolean }>) => {
  state.devicesMode = action.payload.devicesMode;
};

export const consumptionSlice = createSlice({
  name: 'electricity',
  initialState,
  reducers: {
    resetElectricity: resetElectricityHandler,
    updateDevicesMode: updateDevicesModeHandler,
    updateElectricity: updateElectricityHandler,
    updateElectricityCategory: updateElectricityCategoryHandler,
    updateElectricityError: updateElectricityErrorHandler,
    updateElectricityIndex: updateElectricityIndexHandler,
    updateElectricityIsLoading: updateElectricityIsLoadingHandler,
    updateElectricityPeriod: updateElectricityPeriodHandler,
    clearElectricity: () => initialState,
  },
});

export const {
  clearElectricity,
  resetElectricity,
  updateDevicesMode,
  updateElectricity,
  updateElectricityCategory,
  updateElectricityError,
  updateElectricityIndex,
  updateElectricityIsLoading,
  updateElectricityPeriod,
} = consumptionSlice.actions;

export default consumptionSlice.reducer;
