import { ConsumptionNode } from '../Consumption.interfaces';
import { ContractType } from '@/ui-toolkit/common/types';
import { EJP, HPHC, TEMPO } from '@utils/constants/contract-types';
import { KeyValueNumber } from './Consumption.utils';

interface calculateComparisonProps {
  contract: ContractType;
  prevNode: ConsumptionNode;
  currNode: ConsumptionNode;
}

const getComparisonPercentage = ({ prevTotal, prevValidCount, currTotal, currValidCount }: KeyValueNumber) => {
  const prevDivided = Number((prevTotal / prevValidCount).toFixed(2));
  const currDivided = Number((currTotal / currValidCount).toFixed(2));
  const diff = currDivided - prevDivided;
  const average = (currDivided + prevDivided) / 2;
  const percentage = Math.floor((diff / average) * 100);

  return percentage;
};

const getBaseComparison = ({ prevNode, currNode }: { prevNode: ConsumptionNode; currNode: ConsumptionNode }) => {
  const prevTotal = Math.floor(prevNode.total_consumption.base);
  const currTotal = Math.floor(currNode.total_consumption.base);

  // console.log('\n\n');
  // console.log('prevNode:', prevNode);
  // console.log('prevTotal:', prevTotal);
  // console.log('currNode:', currNode);
  // console.log('currTotal:', currTotal);

  let prevValidCount = 0;
  Object.keys(prevNode.values).forEach(key => {
    const { base } = prevNode.values[key].consumption;
    base > 0 && prevValidCount++;
  });

  let currValidCount = 0;
  Object.keys(currNode.values).forEach(key => {
    const { base } = currNode.values[key].consumption;
    base > 0 && currValidCount++;
  });

  return getComparisonPercentage({ prevTotal, prevValidCount, currTotal, currValidCount });
};

const getHphcComparison = ({ prevNode, currNode }: { prevNode: ConsumptionNode; currNode: ConsumptionNode }) => {
  const prevTotal = Math.floor(
    prevNode.total_consumption.base + prevNode.total_consumption.peak + prevNode.total_consumption.off_peak,
  );

  const currTotal = Math.floor(
    currNode.total_consumption.base + currNode.total_consumption.peak + currNode.total_consumption.off_peak,
  );

  // console.log('\n\n');
  // console.log('prevNode:', prevNode);
  // console.log('prevTotal:', prevTotal);
  // console.log('currNode:', currNode);
  // console.log('currTotal:', currTotal);

  let prevValidCount = 0;
  Object.keys(prevNode.values).forEach(key => {
    const { base, peak, off_peak } = prevNode.values[key].consumption;
    base + peak + off_peak > 0 && prevValidCount++;
  });

  let currValidCount = 0;
  Object.keys(currNode.values).forEach(key => {
    const { base, peak, off_peak } = currNode.values[key].consumption;
    base + peak + off_peak > 0 && currValidCount++;
  });

  return getComparisonPercentage({ prevTotal, prevValidCount, currTotal, currValidCount });
};

const getEjpComparison = ({ prevNode, currNode }: { prevNode: ConsumptionNode; currNode: ConsumptionNode }) => {
  let prevTotal = 0;
  Object.values(prevNode.total_consumption.ejp as KeyValueNumber).forEach(value => (prevTotal += value));
  prevTotal = Math.floor(prevTotal);

  let currTotal = 0;
  Object.values(currNode.total_consumption.ejp as KeyValueNumber).forEach(value => (currTotal += value));
  currTotal = Math.floor(currTotal);

  // console.log('\n\n');
  // console.log('prevNode:', prevNode);
  // console.log('prevTotal:', prevTotal);
  // console.log('currNode:', currNode);
  // console.log('currTotal:', currTotal);

  let prevValidCount = 0;
  Object.keys(prevNode.values).forEach(key => {
    let prevTmp = 0;
    Object.values(prevNode.values[key].consumption.ejp as KeyValueNumber).forEach(value => (prevTmp += value));
    if (prevTmp > 0) prevValidCount++;
  });

  let currValidCount = 0;
  Object.keys(currNode.values).forEach(key => {
    let currTmp = 0;
    Object.values(currNode.values[key].consumption.ejp as KeyValueNumber).forEach(value => (currTmp += value));
    if (currTmp > 0) currValidCount++;
  });

  return getComparisonPercentage({ prevTotal, prevValidCount, currTotal, currValidCount });
};

const getTempoComparison = ({ prevNode, currNode }: { prevNode: ConsumptionNode; currNode: ConsumptionNode }) => {
  let prevTotal = 0;
  Object.values(prevNode.total_consumption.tempo as KeyValueNumber).forEach(value => (prevTotal += value));
  prevTotal = Math.floor(prevTotal);

  let currTotal = 0;
  Object.values(currNode.total_consumption.tempo as KeyValueNumber).forEach(value => (currTotal += value));
  currTotal = Math.floor(currTotal);

  // console.log('\n\n');
  // console.log('prevNode:', prevNode);
  // console.log('prevTotal:', prevTotal);
  // console.log('currNode:', currNode);
  // console.log('currTotal:', currTotal);

  let prevValidCount = 0;
  Object.keys(prevNode.values).forEach(key => {
    let prevTmp = 0;
    Object.values(prevNode.values[key].consumption.tempo as KeyValueNumber).forEach(value => (prevTmp += value));
    if (prevTmp > 0) prevValidCount++;
  });

  let currValidCount = 0;
  Object.keys(currNode.values).forEach(key => {
    let currTmp = 0;
    Object.values(currNode.values[key].consumption.tempo as KeyValueNumber).forEach(value => (currTmp += value));
    if (currTmp > 0) currValidCount++;
  });

  return getComparisonPercentage({ prevTotal, prevValidCount, currTotal, currValidCount });
};

export const calculateComparison = ({ contract, prevNode, currNode }: calculateComparisonProps): number => {
  if (!prevNode || !currNode) {
    return 0;
  }

  switch (contract) {
    case HPHC:
      return getHphcComparison({ prevNode, currNode });

    case EJP:
      return getEjpComparison({ prevNode, currNode });

    case TEMPO:
      return getTempoComparison({ prevNode, currNode });

    default:
      return getBaseComparison({ prevNode, currNode });
  }
};
