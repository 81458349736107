import axios from 'axios';

import { call, put, takeLatest } from 'redux-saga/effects';
import { ENDPOINTS } from '@utils/constants/endpoints';
import { ResponseGenerator } from '@/shared/interfaces/ResponseGenerator';
import { updateProposals, updateProposalIsLoading } from './ProposalSlice';

export const proposalActions = {
  GET_PROPOSAL: 'GET_PROPOSAL',
};

export function* getProposal({ sectionId }: ReturnType<any>) {
  try {
    yield put(updateProposalIsLoading({ sectionId, isLoading: true }));

    const { data }: ResponseGenerator = yield call(axios.get, `${ENDPOINTS.PROPOSAL}/${sectionId}`);

    yield put(updateProposals({ sectionId, proposal: data.data }));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(updateProposalIsLoading({ sectionId, isLoading: false }));
  }
}

export default function* proposalSagas() {
  yield takeLatest(proposalActions.GET_PROPOSAL, getProposal);
}
