import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import IconCross from '@icons/IconCross';
import IconHelpCircle from '@icons/IconHelpCircle';
import styles from './Drawer.module.scss';
import drawerComponents from './DrawerComponents';
import { selectDrawer } from './DrawerSelectors';
import { clearDrawer } from './DrawerSlice';

export interface DrawerProps {
  headerTitle?: string;
  isOpen?: boolean;
  preventScroll?: boolean;
  preventClose: boolean;
  onClose?: () => void;
}

const Drawer = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const { componentId, props = {} } = useSelector(selectDrawer);
  const { onClose, preventScroll, preventClose, headerTitle } = props as DrawerProps;

  useEffect(() => {
    if (!componentId) return;

    setVisible(true);
    const timeout = setTimeout(() => setDocumentScrollbar('hidden'), 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [componentId]);

  useEffect(() => {
    if (!ref) return;

    const headerHeight = document.getElementById('drawer-header')?.scrollHeight;
    ref.style.marginTop = `${headerHeight}px`;
  }, [ref]);

  const setDocumentScrollbar = (visibility: 'visible' | 'hidden') => {
    const body = document.querySelector('body');

    if (!body) return;
    body.style.overflowY = visibility;
  };

  const handleCloseDrawer = () => {
    if (preventClose) return;

    setVisible(false);
    setTimeout(() => dispatch(clearDrawer()), 750);
    setTimeout(() => setDocumentScrollbar('visible'), 1000);
    onClose?.();
  };

  if (!componentId) {
    return null;
  }

  const styleVisible = visible ? styles.show : styles.hide;
  const componentsList = drawerComponents as any;
  const ComponentToRender = componentsList[componentId];
  const scrollStyle = preventScroll ? styles.preventScroll : '';

  return (
    <div className={`${styles.Drawer} ${scrollStyle}`}>
      <div className={`${styles.Drawer__clickableLayer} ${styleVisible}`} onClick={handleCloseDrawer} />

      <div className={`${styles.Drawer__body} ${styleVisible} ${scrollStyle}`}>
        <div id='drawer-header' className={`${styles.Drawer__body__header}`}>
          <span className={styles.Drawer__body__header__icon}>
            <IconHelpCircle />
          </span>

          {headerTitle && <h1>{headerTitle}</h1>}

          {!preventClose && (
            <button className={styles.Drawer__body__header__close} onClick={handleCloseDrawer}>
              <IconCross />
            </button>
          )}
        </div>

        <div
          id='drawer-content'
          className={styles.Drawer__body__content}
          ref={(newRef: HTMLDivElement) => setRef(newRef)}
        >
          <ComponentToRender onClosed={handleCloseDrawer} {...props} />
        </div>
      </div>
    </div>
  );
};

export default Drawer;
