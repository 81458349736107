import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { ELECTRICITY, GAS } from '@utils/constants/supply-types';
import { ENDPOINTS } from '@utils/constants/endpoints';
import { runtimeConfig } from '@/runtime-config';
import { SupplyType } from '@/ui-toolkit/common/types';
import { updateElectricityError } from '@/containers/Consumption/Electricity/state/ElectricitySlice';
import { updateGasError } from '@/containers/Consumption/Gas/state/GasSlice';
import { PATHS } from '@utils/constants/paths';

const { TOKEN_ID } = runtimeConfig;
const { GAS_CONSUMPTION, ELECTRICITY_CONSUMPTION } = ENDPOINTS;

const errorUpdaters = {
  [ELECTRICITY]: updateElectricityError,
  [GAS]: updateGasError,
};

const useAxiosInterceptor = () => {
  const dispatch = useDispatch();

  const handleConsumptionError = ({ url, status }: { url: string; status: number }) => {
    if (status === 424) {
      let supplyType: SupplyType | null = null;
      if (url.includes(GAS_CONSUMPTION)) supplyType = GAS;
      if (url.includes(ELECTRICITY_CONSUMPTION)) supplyType = ELECTRICITY;
      if (supplyType) dispatch(errorUpdaters[supplyType]({ error: 424 }));
    }

    if (status === 401) {
      if (!url.includes('logout')) {
        localStorage.clear();
      }

      if (!window.location.href.includes(PATHS.DASHBOARD)) {
        window.location.href = `${PATHS.DASHBOARD}/?login=true`;
      }
    }
  };

  useEffect(() => {
    const setupAxiosInterceptor = async () => {
      axios.defaults.baseURL = runtimeConfig.APP_API_URL || '';
      axios.defaults.headers['Accept'] = 'application/json';
      axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

      axios.interceptors.request.use(
        config => {
          config.headers['Authorization'] = localStorage?.getItem(TOKEN_ID);
          return config;
        },

        error => {
          return Promise.reject(error);
        },
      );

      axios.interceptors.response.use(
        response => {
          return response;
        },

        error => {
          const { url } = error.config;
          const status = error.response ? error.response.status : 0;

          handleConsumptionError({ url, status });

          return Promise.reject(error);
        },
      );
    };

    setupAxiosInterceptor();
  }, []);

  return {};
};

export default useAxiosInterceptor;
