import { ChartCategory } from '@/ui-toolkit/common/types';
import { CONSUMPTION, PRICE } from '@utils/constants/category-types';
import { fromFloatToCommaFixedTwo, getTitle, units } from './generate-tooltip';
import { KeyValueString } from '../Consumption.utils';
import { Period } from '../../Consumption.interface';
import { TariffNode } from '../../Consumption.interfaces';

const getRows = ({ category, node }: { category: ChartCategory; node: TariffNode }): KeyValueString => {
  const unit = ' ' + units[category];
  const { ejp } = node;
  let result: KeyValueString = {};

  if (!ejp) return {};

  if (category === PRICE) {
    result = {
      red: fromFloatToCommaFixedTwo(ejp.red) + unit,
      blue: fromFloatToCommaFixedTwo(ejp.blue) + unit,
      subscription: fromFloatToCommaFixedTwo(node.subscription) + unit,
    };
  }

  if (category === CONSUMPTION) {
    result = {
      red: fromFloatToCommaFixedTwo(ejp.red) + unit,
      blue: fromFloatToCommaFixedTwo(ejp.blue) + unit,
    };
  }

  for (const i in result) {
    if (result[i] === '0 kWh' || result[i] === '0 €') {
      delete result[i];
    }
  }

  return result;
};

const getTotal = ({ category, node }: { category: ChartCategory; node: TariffNode }): string => {
  let sum = 0;
  const unit = ' ' + units[category];
  const { ejp } = node;

  if (!ejp) return 'n/a';

  if (category === PRICE) {
    sum = ejp.red + ejp.blue + node.subscription;
  }

  if (category === CONSUMPTION) {
    sum = ejp.red + ejp.blue;
  }

  return fromFloatToCommaFixedTwo(sum) + unit;
};

export const ejpTooltip = ({
  category,
  date,
  node,
  period,
}: {
  category: ChartCategory;
  date: string;
  node: TariffNode;
  period: Period;
}): {
  rows: KeyValueString;
  total: string;
  title: string;
} => {
  return {
    rows: getRows({ category, node }),
    total: getTotal({ category, node }),
    title: getTitle(date, period),
  };
};
