export const toEuro = (v = 0, estimated = false) => (estimated ? '~' : '') + v.toFixed(2).replace('.', ',') + ' €';

export const toKwh = (v = 0, estimated = false) => (estimated ? '~' : '') + v.toFixed(2).replace('.', ',') + ' kWh';

export const toFrenchDate = date =>
  new Date(date).toLocaleDateString('fr-FR', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

export const dateToYearPhrase = date => {
  const localeDate = toFrenchDate(date).split(' ').slice(2).join(' ');
  return localeDate.charAt(0).toUpperCase() + localeDate.slice(1);
};

export const dateToWeekPhrase = date => {
  const localeDate = toFrenchDate(date);
  return localeDate.charAt(0).toUpperCase() + localeDate.slice(1);
};

export const dateToHoursPhrase = date => {
  const initialHour = Number(date.slice(-3));
  const endingHour = initialHour === 23 ? '00' : initialHour + 1;
  return `Ma conso entre ${initialHour}h et ${endingHour}h`;
};

export const abbreviateNumber = value => {
  if (value === undefined) return 0;

  if (value < 1000) return value;

  let newValue = value;
  let suffixes = ['', 'k', 'm', 'b', 't'];
  let suffixNum = Math.floor(('' + value).length / 3);
  let shortValue = '';

  for (let precision = 2; precision >= 1; precision--) {
    shortValue = parseFloat((suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision));

    let dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');

    if (dotLessShortValue.length <= 2) break;
  }

  if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);

  newValue = shortValue + suffixes[suffixNum];

  return newValue;
};

export const fromFloatToComma = value => value?.toString().replace('.', ',');

export const convertCtaToAnchor = props => {
  if (!props) return '';

  const { link, label } = props;

  return '&nbsp;<a href="' + link + '" target="_blank" rel="noopener noreferrer">' + label + '.</a>';
};
