import { PATHS } from '@utils/constants/paths';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TipsState {
  data: TipsData;
  error: any;
  isLoading: boolean;
  page: number;
  currentTip: TipsItem | null;
}

const initialState: TipsState = {
  data: {
    items: [],
    total: 0,
  },
  error: {},
  isLoading: false,
  page: 0,
  currentTip: null,
};

const appendOneTipHandler = (state: TipsState, action: PayloadAction<TipsItem>) => {
  const merged = [...state.data.items, action.payload];
  state.data.items = merged;
};

const clearTipsErrorHandler = (state: TipsState) => {
  state.error = {};
};

const updateTipsIsLoadingHandler = (state: TipsState, action: PayloadAction<boolean>) => {
  state.isLoading = action.payload;
};

const updateTipsDataHandler = (state: TipsState, action: any) => {
  state.data = action.payload;
};

const updateTipsErrorHandler = (state: TipsState, action: any) => {
  state.error = action.payload;
};

const updateTipsPageHandler = (state: TipsState, action: PayloadAction<number>) => {
  state.page = action.payload;
};

const updateCurrentTipHandler = (state: TipsState, action: PayloadAction<TipsItem | null>) => {
  state.currentTip = action.payload;
};

const updateCurrentTipArrowHandler = (state: TipsState, action: PayloadAction<any>) => {
  const { index, navigate, purchaseType } = action.payload;

  if (!state.currentTip) return;
  const tipIndex: number = state.data.items.map(({ id }) => id).indexOf(state.currentTip?.id || '');
  const nextTipIndex: number = tipIndex + index;

  if (nextTipIndex < 0 || nextTipIndex >= 50) return;

  const nextTip = state.data.items[nextTipIndex];

  navigate(`${PATHS.MARKETPLACE}/${purchaseType}/${nextTip.id}`);

  state.currentTip = nextTip;
};

export const tipsSlice = createSlice({
  name: 'tips',
  initialState,
  reducers: {
    appendOneTip: appendOneTipHandler,
    updateTipsIsLoading: updateTipsIsLoadingHandler,
    updateTipsData: updateTipsDataHandler,
    updateTipsError: updateTipsErrorHandler,
    updateTipsPage: updateTipsPageHandler,
    clearTipsError: clearTipsErrorHandler,
    updateCurrentTip: updateCurrentTipHandler,
    updateCurrentTipArrow: updateCurrentTipArrowHandler,
    clearTips: () => initialState,
  },
});

export const {
  appendOneTip,
  clearTips,
  clearTipsError,
  updateCurrentTip,
  updateCurrentTipArrow,
  updateTipsData,
  updateTipsError,
  updateTipsIsLoading,
  updateTipsPage,
} = tipsSlice.actions;

export default tipsSlice.reducer;
