const IconStarCircle = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_784_9236)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.75 -1H23.75V0H24.75V-1ZM14.775 17.05L12 14.95L9.225 17.05C9.025 17.1833 8.825 17.1873 8.625 17.062C8.425 16.9373 8.36667 16.7583 8.45 16.525L9.5 13.05L6.775 11.1C6.575 10.95 6.51667 10.7623 6.6 10.537C6.68333 10.3123 6.84167 10.2 7.075 10.2H10.4L11.525 6.55C11.6083 6.31667 11.7667 6.2 12 6.2C12.2333 6.2 12.3917 6.31667 12.475 6.55L13.6 10.2H16.925C17.1583 10.2 17.3167 10.3123 17.4 10.537C17.4833 10.7623 17.425 10.95 17.225 11.1L14.5 13.05L15.55 16.525C15.6333 16.7583 15.575 16.9373 15.375 17.062C15.175 17.1873 14.975 17.1833 14.775 17.05ZM8.1 21.212C9.31667 21.7373 10.6167 22 12 22C13.3833 22 14.6833 21.7373 15.9 21.212C17.1167 20.6873 18.175 19.975 19.075 19.075C19.975 18.175 20.6873 17.1167 21.212 15.9C21.7373 14.6833 22 13.3833 22 12C22 10.6167 21.7373 9.31667 21.212 8.1C20.6873 6.88333 19.975 5.825 19.075 4.925C18.175 4.025 17.1167 3.31233 15.9 2.787C14.6833 2.26233 13.3833 2 12 2C10.6167 2 9.31667 2.26233 8.1 2.787C6.88333 3.31233 5.825 4.025 4.925 4.925C4.025 5.825 3.31267 6.88333 2.788 8.1C2.26267 9.31667 2 10.6167 2 12C2 13.3833 2.26267 14.6833 2.788 15.9C3.31267 17.1167 4.025 18.175 4.925 19.075C5.825 19.975 6.88333 20.6873 8.1 21.212ZM17.663 17.663C16.1043 19.221 14.2167 20 12 20C9.78333 20 7.896 19.221 6.338 17.663C4.77933 16.1043 4 14.2167 4 12C4 9.78333 4.77933 7.89567 6.338 6.337C7.896 4.779 9.78333 4 12 4C14.2167 4 16.1043 4.779 17.663 6.337C19.221 7.89567 20 9.78333 20 12C20 14.2167 19.221 16.1043 17.663 17.663Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_784_9236'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconStarCircle;
