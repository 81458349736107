import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ENDPOINTS } from '@utils/constants/endpoints';
import { updateAuthIsLoading, updateAuthStatus } from '@auth/AuthSlice';
import { STATUS } from '@utils/constants/status';

export const passwordActions = {
  SEND_PASSWORD_LINK: 'SEND_PASSWORD_LINK',
};

export function* sendPasswordEmail({ email }: ReturnType<any>) {
  try {
    yield put(updateAuthIsLoading(true));
    yield call(axios.post, ENDPOINTS.PASS_FORGOT, { email });

    yield put(updateAuthStatus(STATUS.SUCCESS));
  } catch (error) {
    console.error(error);

    yield put(updateAuthStatus(STATUS.AUTH_ERROR));
  } finally {
    yield put(updateAuthIsLoading(false));
  }
}

export default function* passwordSagas() {
  yield takeLatest(passwordActions.SEND_PASSWORD_LINK, sendPasswordEmail);
}
