export const PATHS = {
  ADDRESS_FEATURES_FORM: '/address-features-form',
  ALERTS: '/alerts',
  CARBON_FOOTPRINT: '/tips/carbon-footprint',
  CONSUMPTION: '/consumption',
  CONTACTS: '/profile/contacts',
  CONTRACTS: '/profile/contracts',
  DASHBOARD: '/dashboard',
  DEVICES_CONSUMPTION: '/consumption/devices-consumption',
  FAVOURITES: '/tips/favourites',
  FORGOT_PASS: '/auth/forgot-password',
  GLOBAL_CONSUMPTION: '/consumption/global-consumption',
  GROUP: '/tips/group',
  HELP: '/profile/help',
  INDIVIDUAL: '/tips/individual',
  HOME: '/home',
  LOGIN: '/auth/login',
  MARKETPLACE: '/conseils',
  PROFILE: '/profile',
  ONBOARDING: '/onboarding',
  ONBOARDING_SUCCESS: '/onboarding/success',
  ONBOARDING_SET_ADDRESS: '/onboarding/set-address',
  ONBOARDING_SET_FULL_NAME: '/onboarding/set-full-name',
  ONBOARDING_SET_PASSWORD: '/onboarding/set-password',
  ONBOARDING_SET_PCE: '/onboarding/set-pce',
  ONBOARDING_SET_PDL: '/onboarding/set-pdl',
  SETTINGS: '/profile/settings',
  SIGNUP: '/auth/signup',
  SIGNUP_SUCCESS: '/auth/signup-success',
  TOKEN_EXPIRED: '/auth/token-expired',
};
