import { Link } from 'react-router-dom';
import { ReactElement } from 'react';
import { Variant } from '@/ui-toolkit/common/types';
import NotificationBubble from '../NotificationBubble/NotificationBubble';

import styles from './MenuItem.module.scss';

export interface MenuItemProps {
  id: string;
  icon: ReactElement;
  label: string;
  link?: string;
  selected?: boolean;
  variant?: Variant;
  notification?: {
    label: string;
    variant: Variant;
  };
  onClick?: () => void;
}

const MenuItem = ({
  icon,
  label,
  link,
  notification,
  selected = false,
  variant = 'primary',
  onClick,
}: MenuItemProps) => {
  const selectedStyle = selected && styles.selected;

  const MenuButton = () => {
    return (
      <span className={`${styles.MenuItem__container} ${styles[`MenuItem__container__${variant}`]} ${selectedStyle}`}>
        <span>{icon}</span>
        <p className={styles.MenuItem__container__label}>{label}</p>
        {notification && <NotificationBubble label={notification.label} />}
        {selected && <span className={styles.selectedBar} />}
      </span>
    );
  };

  return (
    <div className={styles.MenuItem} onClick={() => onClick?.()}>
      {link ? (
        <Link to={link}>
          <MenuButton />
        </Link>
      ) : (
        <MenuButton />
      )}
    </div>
  );
};

export default MenuItem;
