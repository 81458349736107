import { ReactElement } from 'react';
import styles from './ButtonCircular.module.scss';

export interface ButtonCircularProps {
  icon: ReactElement;
  onClick: (v?: any) => void;
}

const ButtonCircular = ({ icon, onClick }: ButtonCircularProps) => {
  return (
    <button className={styles.ButtonCircular} onClick={onClick}>
      {icon}
    </button>
  );
};

export default ButtonCircular;
