import TagManager from 'react-gtm-module';

import { runtimeConfig } from '@/runtime-config';

const props = {
  gtmId: runtimeConfig.GTM_KEY,
};

const useAnalytics = () => {
  const init = () => {
    if (!runtimeConfig.GTM_KEY) {
      console.error('ERROR: Missing gtm key');
      return;
    }

    TagManager.initialize(props);
  };

  const track = (event, data) => {
    try {
      window.dataLayer.push({ event, ...data });
    } catch (error) {
      console.error(error);
    }
  };

  return {
    init,
    track,
  };
};

export default useAnalytics;
