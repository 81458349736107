import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TariffState } from './Tariff.interface';

const initialState: TariffState = {
  is_adapted: false,
  contract_type: '',
  yearly_price: 0,
  off_peak_hours: {
    hours: [],
    percentage: 0,
    itip_percentage: 0,
  },
  recommended: {
    contract_type: null,
    advantage: 0,
    advice: '',
    cta: {
      label: '',
      link: '',
    },
    days_used: 0,
    yearly_price: 0,
  },
  error: null,
  isLoading: false,
  provider: null,
};

const clearTariffErrorHandler = (state: TariffState) => {
  state.error = null;
};

const updateTariffIsLoadingHandler = (state: TariffState, action: PayloadAction<boolean>) => {
  state.isLoading = action.payload;
};

const updateTariffDataHandler = (state: TariffState, action: any) => {
  const { contract_type, yearly_price, off_peak_hours, recommended, is_adapted, provider } = action.payload;

  state.is_adapted = is_adapted;
  state.contract_type = contract_type;
  state.yearly_price = yearly_price;
  state.off_peak_hours = off_peak_hours;
  state.recommended = recommended;
  state.provider = provider;
};

const updateTariffErrorHandler = (state: TariffState, action: any) => {
  const error = action.payload;
  state.error = error?.response?.status?.toString() || error?.status?.toString() || '424';
};

export const TariffSlice = createSlice({
  name: 'Tariff',
  initialState,
  reducers: {
    clearTariff: () => initialState,
    updateTariffIsLoading: updateTariffIsLoadingHandler,
    updateTariffData: updateTariffDataHandler,
    updateTariffError: updateTariffErrorHandler,
    clearTariffError: clearTariffErrorHandler,
  },
});

export const { clearTariff, clearTariffError, updateTariffData, updateTariffError, updateTariffIsLoading } =
  TariffSlice.actions;

export default TariffSlice.reducer;
