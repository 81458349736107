import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/store/Reducer';
import {
  MOBILE_SMALL,
  MOBILE_MEDIUM,
  MOBILE_LARGE,
  SCREEN_MIN_768,
  SCREEN_MIN_1024,
  SCREEN_MIN_1280,
  SCREEN_MIN_1440,
  WindowResizeToolState,
} from './WindowResizeToolSlice';

export const selectWindowResizeTool = (state: RootState) => state?.windowResizeTool as WindowResizeToolState;

export const selectCurrentDeviceType = createSelector(selectWindowResizeTool, windowResizeToolState => {
  const { deviceType } = windowResizeToolState;

  return {
    deviceType,
    isMobile: deviceType.includes('mobile'),
    isMobileSmall: deviceType === MOBILE_SMALL,
    isMobileMedium: deviceType === MOBILE_MEDIUM,
    isMobileLarge: deviceType === MOBILE_LARGE,
    isTablet: deviceType === SCREEN_MIN_768,
    isIpad: deviceType === SCREEN_MIN_1024,
    isDesktop: deviceType === SCREEN_MIN_1280 || deviceType === SCREEN_MIN_1440,
  };
});
