const IconChartBars = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_363_6307)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.75 -1H23.75V0H24.75V-1ZM4.588 19.413C4.97933 19.8043 5.45 20 6 20C6.55 20 7.02067 19.8043 7.412 19.413C7.804 19.021 8 18.55 8 18V11C8 10.45 7.804 9.979 7.412 9.587C7.02067 9.19567 6.55 9 6 9C5.45 9 4.97933 9.19567 4.588 9.587C4.196 9.979 4 10.45 4 11V18C4 18.55 4.196 19.021 4.588 19.413ZM10.588 19.413C10.9793 19.8043 11.45 20 12 20C12.55 20 13.021 19.8043 13.413 19.413C13.8043 19.021 14 18.55 14 18V6C14 5.45 13.8043 4.97933 13.413 4.588C13.021 4.196 12.55 4 12 4C11.45 4 10.9793 4.196 10.588 4.588C10.196 4.97933 10 5.45 10 6V18C10 18.55 10.196 19.021 10.588 19.413ZM16.587 19.413C16.979 19.8043 17.45 20 18 20C18.55 20 19.021 19.8043 19.413 19.413C19.8043 19.021 20 18.55 20 18V15C20 14.45 19.8043 13.979 19.413 13.587C19.021 13.1957 18.55 13 18 13C17.45 13 16.979 13.1957 16.587 13.587C16.1957 13.979 16 14.45 16 15V18C16 18.55 16.1957 19.021 16.587 19.413Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_363_6307'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconChartBars;
