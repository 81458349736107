import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { selectTotalUnreadedAlerts } from '@/containers/Alerts/AlertsSelectors';
import { selectAuthToken } from '@/store/Common/Auth/AuthSelectors';
import { Variant } from '@/ui-toolkit/common/types';
import IconChair from '@icons/IconChair';
import IconChartBars from '@icons/IconChartBars';
import IconHelpCircle from '@icons/IconHelpCircle';
import IconLightBulb from '@icons/IconLightBulb';
import IconNotification from '@icons/IconNotification';
import { updateDrawerComponent } from '../Drawer/DrawerSlice';
import { updateModalComponent } from '../Modal/ModalSlice';

export interface MenuItem {
  id: string;
  icon: ReactElement;
  label: string;
  link?: string;
}

const items: MenuItem[] = [
  {
    id: 'dashboard',
    icon: <IconChair />,
    label: 'Accueil',
    link: '/dashboard',
  },
  {
    id: 'consumption',
    icon: <IconChartBars />,
    label: 'Conso',
    link: '/consumption/global-consumption',
  },
  {
    id: 'tips',
    icon: <IconLightBulb />,
    label: 'Conseils',
    link: '/conseils',
  },
  {
    id: 'alerts',
    icon: <IconNotification />,
    label: 'Notifs',
    link: '/alerts',
  },
  {
    id: 'fqas',
    icon: <IconHelpCircle />,
    label: 'FAQs',
  },
];

export interface useMenuDesktopProps {
  mode: 'mobile' | 'desktop' | 'tablet';
}

const useMenuDesktop = ({ mode }: useMenuDesktopProps) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const unreadedAlerts = useSelector(selectTotalUnreadedAlerts);
  const token = useSelector(selectAuthToken);

  const openLogoutModal = () => {
    dispatch(updateModalComponent({ component: 'logoutModal' }));
  };

  const openFaqsDrawer = () => {
    dispatch(
      updateDrawerComponent({
        componentId: 'Faqs',
        props: { faqsType: 'generalFaqs', headerTitle: 'Questions fréquentes' },
      }),
    );
  };

  const parseItems = () => {
    const _items = [...items];

    if (mode !== 'mobile') {
      _items.pop();
    }

    return _items.map(item => {
      const showNotification = item.id === 'notifications' && unreadedAlerts > 0;
      const notificationLabel = unreadedAlerts.toString();
      const notification = showNotification ? { label: notificationLabel, variant: 'danger' as Variant } : undefined;
      const selected = item.link && pathname.includes(item.id);

      return {
        ...item,
        notification,
        selected,
      };
    });
  };

  const handleCta = (itemId: string) => {
    if (itemId === 'fqas') {
      openFaqsDrawer();
    }
  };

  return {
    isLoggedIn: token,
    items: parseItems(),
    openLogoutModal,
    openFaqsDrawer,
    handleCta,
  };
};

export default useMenuDesktop;
