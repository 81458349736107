import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <div className={styles.Footer}>
      <div className={styles.links}>
        <a href='/suivi-conso-energie/mentions-legales-de-myselectra' rel='noreferrer' target='_blank'>
          Mentions légales
        </a>
      </div>

      <p className={styles.copyright}> © 2024 Selectra – Tous droits réservés</p>
    </div>
  );
};

export default Footer;
