import { useEffect, useState } from 'react';

const useOutsideAlerter = (ref: any, callback?: () => void) => {
  const [clickOutside, setClickOutside] = useState(false);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (!ref?.current) return;

      if (ref.current && !ref.current.contains(event.target)) {
        setClickOutside(true);
        callback?.();
      } else {
        setClickOutside(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return {
    clickOutside,
  };
};

export default useOutsideAlerter;
