import { call, takeLatest, put } from 'redux-saga/effects';
import axios from 'axios';

import { ENDPOINTS } from '@utils/constants/endpoints';
import { ResponseGenerator } from '@/shared/interfaces/ResponseGenerator';
import { updateDevices, updateDevicesIsLoading } from './DevicesSlice';
import { updateDevicesError } from './DevicesSlice';

export const devicesActions = {
  GET_DEVICES_CONSUMPTION: 'GET_DEVICES_CONSUMPTION',
};

export function* getDevicesConsumption({ addressId, period }: ReturnType<any>) {
  try {
    yield put(updateDevicesIsLoading(true));
    yield put(updateDevicesError(null));

    const { ADDRESS_API, DEVICES_CONSUMPTION } = ENDPOINTS;
    const endpoint = `${ADDRESS_API}/${addressId}${DEVICES_CONSUMPTION}/${period}`;
    const response: ResponseGenerator = yield call(axios.get, endpoint);

    if (response.status === 204) {
      yield put(updateDevicesError({ response: { status: 204 } }));
      return;
    }

    const { data } = response;

    yield put(updateDevices(data.data));
    yield put(updateDevicesError(null));
  } catch (error: any) {
    const parsedError = !error.response ? { response: { status: 0 } } : error;
    yield put(updateDevicesError(parsedError));
  } finally {
    yield put(updateDevicesIsLoading(false));
  }
}

export default function* devicesSagas() {
  yield takeLatest(devicesActions.GET_DEVICES_CONSUMPTION, getDevicesConsumption);
}
