const LogoSelectraMobile = () => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
    <path
      fill='url(#a)'
      fillRule='evenodd'
      d='m8.064 18.642-5.058-7.686c1.391-1.65 4.9-2.984 6.323-1.11 2.402 3.27 2.631 3.38 3.064 2.791C13.865 10.254 19.169.431 19.226.35H3.436C1.777.367-.046 2.087.005 4.125L0 19.888c.017 1.667 1.58 3.762 3.491 3.762l17.051-.008c1.912.008 3.458-1.675 3.43-3.507L24 4.499c-3.165 5.635-7.529 14.02-7.538 14.037-1.886 3.282-6.121 3.219-8.398.106'
      clipRule='evenodd'
    />
    <defs>
      <linearGradient id='a' x1='24' x2='0' y1='12' y2='12' gradientUnits='userSpaceOnUse'>
        <stop offset='.201' stopColor='#015AA3' />
        <stop offset='1' stopColor='#4196FB' />
      </linearGradient>
    </defs>
  </svg>
);

export default LogoSelectraMobile;
