import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PdlFormState {
  data: {
    pdlNumber: string;
    pdlIsLinky: boolean | 'unkwown';
  };
  pdlError: string | null;
  isLoading: boolean;
}

const initialState: PdlFormState = {
  data: {
    pdlNumber: '',
    pdlIsLinky: 'unkwown',
  },
  pdlError: null,
  isLoading: false,
};

const clearPdlFormErrorHandler = (state: PdlFormState) => {
  state.pdlError = null;
};

const updatePdlFormIsLoadingHandler = (state: PdlFormState, action: PayloadAction<boolean>) => {
  state.isLoading = action.payload;
};

const updatePdlFormIsLinkyHandler = (state: PdlFormState, action: PayloadAction<boolean | 'unknown'>) => {
  state.data.pdlIsLinky = action.payload as boolean;
};

const updatePdlFormDataHandler = (state: PdlFormState, action: any) => {
  const { pod } = action.payload;

  if (!pod) {
    state = initialState;
  } else {
    state.data.pdlNumber = pod;
  }
};

const updatePdlFormErrorHandler = (state: PdlFormState, action: any) => {
  state.pdlError = action.payload;
};

const updatePdlCheckedHandler = (state: PdlFormState, action: any) => {
  const { is_linky_meter, pdl } = action.payload;

  state.data.pdlIsLinky = is_linky_meter;
  state.data.pdlNumber = pdl;
};

export const pdlFormSlice = createSlice({
  name: 'pdlForm',
  initialState,
  reducers: {
    updatePdlChecked: updatePdlCheckedHandler,
    updatePdlFormIsLoading: updatePdlFormIsLoadingHandler,
    updatePdlFormIsLinky: updatePdlFormIsLinkyHandler,
    updatePdlFormData: updatePdlFormDataHandler,
    updatePdlFormError: updatePdlFormErrorHandler,
    clearPdlFormError: clearPdlFormErrorHandler,
    clearPdlForm: () => initialState,
  },
});

export const {
  clearPdlForm,
  clearPdlFormError,
  updatePdlChecked,
  updatePdlFormData,
  updatePdlFormError,
  updatePdlFormIsLinky,
  updatePdlFormIsLoading,
} = pdlFormSlice.actions;

export default pdlFormSlice.reducer;
