import Accordion from '@/ui-toolkit/components/Accordion/Accordion';
import tariffOptionFaqs from './locales/Faqs.locale.json';
import powerConsumptionFaqs from './locales/FaqsPowerConsumption.locale.json';
import generalFaqs from './locales/FaqsGeneral.locale.json';
import styles from './Faqs.module.scss';

const items: any = {
  tariffOptionFaqs,
  powerConsumptionFaqs,
  generalFaqs,
};

export interface FaqItem {
  partTitle?: string;
  title?: string;
  description?: string;
}

export interface FaqsProps {
  faqsType: 'tariffOptionFaqs' | 'powerConsumptionFaqs' | 'generalFaqs';
}

const Faqs = ({ faqsType }: FaqsProps) => {
  return (
    <div className={styles.Faqs}>
      <ul className={styles.Faqs__list}>
        {items[faqsType].map((item: FaqItem) => {
          const { partTitle, title, description } = item;

          return (
            <li
              key={partTitle ?? title}
              className={`${styles.Faqs__list__item} ${partTitle ? styles.containsTitle : styles.normal}`}
            >
              {partTitle && <h2 className={styles.Faqs__list__item__title}>{partTitle}</h2>}
              {title && description && (
                <Accordion title={title} content={<p dangerouslySetInnerHTML={{ __html: description }} />} />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Faqs;
