const LogoSelectra = ({ variant = 'primary', size = 'md' }) => {
  const variantFill = variant === 'primary' ? '#1460AA' : variant;
  const withLogo = size === 'xs' ? '90' : '140';
  const heightLogo = size === 'xs' ? '23' : 'auto';

  return (
    <svg
      id='selectra-logo'
      width={withLogo}
      height={heightLogo}
      fill={variantFill}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 312.6 57.2'
    >
      <path
        fill={variantFill}
        d='M35.2 46.2c-4 7-13.1 6.9-18 .2L6.4 30.1c3-3.5 10.5-6.4 13.5-2.4 5.1 7 5.6 7.2 6.6 5.9 3.1-5.1 14.5-26 14.6-26.2H7.3c-3.5 0-7.4 3.7-7.3 8v33.5c0 3.5 3.4 8 7.5 8H44c4.1 0 7.4-3.6 7.3-7.5l.1-33.3c-6.9 12.3-16.2 30.1-16.2 30.1M91.3 43c.7-9.7-23.4-6-22.9-21.3.7-20.5 28.4-13.2 31.6-11.1 0 0-3.4 8-3.5 7.8 0 0-16.9-5-17.1 2.4-.3 9.6 23.3 4.8 22.9 22.1-.5 22.7-33.2 11.3-34.7 10.5 0 0 3.6-8.2 3.8-8.3.1 0 19.3 6.3 19.9-2.1M233.8 8.4l5-.8v13.6h12.1V30h-12.1v11.9c-.1 9.3 12.3 4.3 12.3 4.2l2.5 8.7c-1.2.5-8.7 1.9-10.9 2.1-13.3 1.6-14-10.3-14.1-14.9l-.1-27.8c.3-2.9 2.4-5.3 5.3-5.8M148.3 1.6l5-.8.1 56.4h-10.1l-.1-49.8c0-2.9 2.1-5.3 5.1-5.8M279.7 30.9c-.9-.2-5.8-1.8-10.8-.5l.1 26.8h-9.6l-.1-33.3c9.7-4.3 21.5-1.5 22-1.3l-1.6 8.3zM303.9 49.4v-7.3c-.4-.1-9.6-.9-9.8 3.6-.3 5.3 9.3 3.8 9.8 3.7m-5.2-27.9c14.9-1 13.9 11.8 13.9 13.9v20.1c-1.3.3-10.5 1.7-13.8 1.7-2.1 0-13.2.5-13.4-11.2 0-2 .6-13.7 18.5-10.1 0 0 .8-6.6-6.3-6.3-2.6.1-8 1.2-9.7 1.4l-1.1-7.7c.6-.3 8.4-1.6 11.9-1.8M195.2 39.4c0-12.6 11.6-22.8 28.7-15.4l-2.1 7.4c-14.7-4.7-16.1 5.2-16.1 8 0 2.9 1 12.9 17.4 8l1.4 7.6c-19.9 6.7-29.2-3.5-29.3-15.6M180.8 36c-.8-6.3-5.1-6.6-6.2-6.6-5.8 0-6.4 5.8-6.6 6.6h12.8zm-22.7 3.7c-.1-3 1.1-18.5 16.4-18.3 16.2.2 15.8 17.9 15.5 21.2h-22c0 8.3 14.9 5.7 18.8 4.8l1.3 7.9c-4.7 1.2-29.1 7.7-30-15.6M129.2 36c-.8-6.3-5.1-6.6-6.2-6.6-5.8 0-6.4 5.8-6.6 6.6h12.8zm-22.7 3.7c-.1-3 1.1-18.5 16.4-18.3 16.2.2 15.8 17.9 15.5 21.2h-21.9c0 8.3 14.9 5.7 18.8 4.8l1.3 7.9c-4.8 1.2-29.2 7.7-30.1-15.6'
      />
    </svg>
  );
};

export default LogoSelectra;
