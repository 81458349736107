import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateAppIsInstalled, updateDeferredPrompt, updateSupportPwa } from './InstallAppCtaSlice';
import useBrowserDetection from '@hooks/useBrowserDetection';
import useAnalytics from '@hooks/useAnalytics';

const browsersNotAllowed = ['safari', 'not-detected', 'opera'];

const useInstallAppCta = () => {
  const { track } = useAnalytics();
  const dispatch = useDispatch();
  const { browserType } = useBrowserDetection();
  const _navigator = navigator as any;

  const handleAppInstalledEvent = () => dispatch(updateAppIsInstalled(true));

  const handleMatchMediaEvent = (e: any) => e.matches && dispatch(updateAppIsInstalled(true));

  useEffect(() => {
    const handleBeforeinstallprompt = (e: any) => {
      e.preventDefault();

      dispatch(updateDeferredPrompt(e));

      track('pwaAllowsInstallation', {});
    };

    window.addEventListener('beforeinstallprompt', handleBeforeinstallprompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeinstallprompt);
    };
  }, [window]);

  useEffect(() => {
    try {
      if (!browsersNotAllowed.includes(browserType) && 'serviceWorker' in navigator) {
        dispatch(updateSupportPwa(true));
      }

      window.addEventListener('appinstalled', handleAppInstalledEvent);
      window?.matchMedia('(display-mode: standalone)')?.addEventListener('change', handleMatchMediaEvent);
      window?.matchMedia('(display-mode: fullscreen)')?.addEventListener('change', handleMatchMediaEvent);
      window?.matchMedia('(display-mode: minimal-ui)')?.addEventListener('change', handleMatchMediaEvent);

      if (
        _navigator?.standalone ||
        window.matchMedia('(display-mode: standalone)').matches ||
        window.matchMedia('(display-mode: fullscreen)').matches ||
        window.matchMedia('(display-mode: minimal-ui)').matches
      ) {
        dispatch(updateAppIsInstalled(true));
      }
    } catch (error: any) {
      console.log(error);
    }

    return () => {
      window?.removeEventListener('appinstalled', handleAppInstalledEvent);
      window.matchMedia('(display-mode: standalone)')?.removeEventListener('change', handleMatchMediaEvent);
      window.matchMedia('(display-mode: fullscreen)')?.removeEventListener('change', handleMatchMediaEvent);
      window.matchMedia('(display-mode: minimal-ui)')?.removeEventListener('change', handleMatchMediaEvent);
    };
  }, [browserType]);

  return {};
};

export default useInstallAppCta;
