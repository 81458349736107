import { ReactElement } from 'react';

import { Size, Variant } from '@/ui-toolkit/common/types';
import styles from './ButtonIcon.module.scss';

export interface ButtonIconProps {
  id: string;
  border?: 'pill' | 'normal';
  disabled?: boolean;
  fill?: 'plain' | 'ghost';
  icon: ReactElement;
  label?: string;
  selected?: boolean;
  size?: Size;
  variant?: Variant;
  onClick: (value?: any) => void;
}

const ButtonIcon = ({
  id,
  icon,
  border = 'normal',
  disabled = false,
  fill = 'plain',
  size = 'md',
  label,
  selected,
  variant = 'white',
  onClick,
}: ButtonIconProps) => {
  const selectedStyle = selected ? styles.selected : styles.normal;

  return (
    <button
      id={id}
      className={`
        ${styles.ButtonIcon} 
        ${styles[`ButtonIcon__variant-${variant}`]} 
        ${styles[`fill-${fill}`]}
        ${styles[`ButtonIcon__size-${size}`]}
        ${styles[`border-${border}`]}
        ${selectedStyle}
        `}
      disabled={disabled}
      onClick={onClick}
    >
      {selected && <span className={styles.selectedBar} />}

      <span className={styles.icon}>{icon}</span>

      {label && <span className={styles.label}>{label}</span>}

      <span className={styles.background} />
    </button>
  );
};

export default ButtonIcon;
