const LogoTablet = () => {
  return (
    <svg width='86' height='40' viewBox='0 0 86 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.89646 24.4688C4.09625 24.8351 4.32379 25.2902 4.57908 25.8341C4.84547 26.3668 5.11741 26.9496 5.3949 27.5822C5.68349 28.2038 5.96653 28.842 6.24402 29.4969C6.52151 30.1518 6.78234 30.7678 7.02654 31.345C7.27073 30.7678 7.53156 30.1518 7.80905 29.4969C8.08654 28.842 8.36403 28.2038 8.64152 27.5822C8.93011 26.9496 9.20205 26.3668 9.45734 25.8341C9.72373 25.2902 9.95681 24.8351 10.1566 24.4688H12.5208C12.6318 25.2347 12.7317 26.0949 12.8205 27.0495C12.9204 27.9929 13.0036 28.9808 13.0702 30.013C13.1479 31.0342 13.2145 32.0609 13.27 33.0932C13.3366 34.1254 13.3921 35.0966 13.4365 36.0068H10.9058C10.8725 34.8857 10.8281 33.6648 10.7726 32.3439C10.7171 31.0231 10.6339 29.6911 10.5229 28.3481C10.3231 28.8143 10.1011 29.3304 9.85692 29.8965C9.61273 30.4626 9.36854 31.0286 9.12435 31.5947C8.89126 32.1608 8.66372 32.7047 8.44173 33.2264C8.21974 33.7369 8.03104 34.1754 7.87565 34.5416H6.06087C5.90548 34.1754 5.71679 33.7369 5.4948 33.2264C5.27281 32.7047 5.03972 32.1608 4.79553 31.5947C4.56244 31.0286 4.32379 30.4626 4.0796 29.8965C3.83541 29.3304 3.61342 28.8143 3.41363 28.3481C3.30264 29.6911 3.21939 31.0231 3.16389 32.3439C3.10839 33.6648 3.064 34.8857 3.0307 36.0068H0.5C0.544398 35.0966 0.594346 34.1254 0.649844 33.0932C0.716441 32.0609 0.783038 31.0342 0.849636 30.013C0.927333 28.9808 1.01058 27.9929 1.09938 27.0495C1.19927 26.0949 1.30472 25.2347 1.41571 24.4688H3.89646Z'
        fill='url(#paint0_linear_6522_24814)'
      />
      <path
        d='M23.0176 27.2492C22.5181 28.9364 21.9964 30.5181 21.4525 31.9943C20.9086 33.4705 20.3148 34.9024 19.671 36.2898C19.438 36.7893 19.2049 37.2111 18.9718 37.5552C18.7387 37.9104 18.4834 38.1989 18.2059 38.4209C17.9284 38.654 17.6121 38.8205 17.2569 38.9204C16.9128 39.0314 16.5077 39.0869 16.0415 39.0869C15.653 39.0869 15.2923 39.0481 14.9593 38.9704C14.6374 38.9038 14.371 38.8261 14.1601 38.7373L14.593 36.756C14.8483 36.8448 15.0758 36.9059 15.2756 36.9392C15.4754 36.9724 15.6863 36.9891 15.9083 36.9891C16.3523 36.9891 16.6908 36.867 16.9239 36.6228C17.1681 36.3897 17.3734 36.0623 17.5399 35.6405C16.9739 34.5305 16.4078 33.2874 15.8417 31.9111C15.2756 30.5236 14.7428 28.9697 14.2434 27.2492H16.874C16.985 27.6821 17.1126 28.1539 17.2569 28.6644C17.4123 29.1639 17.5732 29.6745 17.7397 30.1962C17.9062 30.7068 18.0727 31.2062 18.2392 31.6946C18.4168 32.183 18.5833 32.627 18.7387 33.0266C18.883 32.627 19.0328 32.183 19.1882 31.6946C19.3436 31.2062 19.4935 30.7068 19.6377 30.1962C19.7931 29.6745 19.9374 29.1639 20.0706 28.6644C20.2149 28.1539 20.3426 27.6821 20.4536 27.2492H23.0176Z'
        fill='url(#paint1_linear_6522_24814)'
      />
      <path
        d='M27.2944 34.0255C27.6606 34.0255 27.9603 33.9978 28.1934 33.9423C28.4376 33.8757 28.6318 33.7924 28.7761 33.6925C28.9204 33.5815 29.0203 33.4539 29.0758 33.3096C29.1313 33.1653 29.1591 33.0044 29.1591 32.8268C29.1591 32.4494 28.9815 32.1386 28.6263 31.8944C28.2711 31.6391 27.6606 31.3672 26.7949 31.0786C26.4175 30.9454 26.0401 30.7955 25.6627 30.6291C25.2853 30.4515 24.9468 30.235 24.6471 29.9797C24.3474 29.7133 24.1032 29.397 23.9145 29.0307C23.7258 28.6533 23.6315 28.1983 23.6315 27.6655C23.6315 27.1327 23.7314 26.6554 23.9312 26.2336C24.131 25.8008 24.414 25.4345 24.7803 25.1348C25.1466 24.8351 25.5906 24.6076 26.1123 24.4522C26.6339 24.2857 27.2222 24.2024 27.8771 24.2024C28.654 24.2024 29.3256 24.2857 29.8916 24.4522C30.4577 24.6187 30.9239 24.8018 31.2902 25.0016L30.541 27.0495C30.2191 26.883 29.8584 26.7387 29.4588 26.6166C29.0703 26.4834 28.5986 26.4168 28.0436 26.4168C27.422 26.4168 26.9725 26.5056 26.695 26.6832C26.4286 26.8497 26.2954 27.1105 26.2954 27.4657C26.2954 27.6766 26.3453 27.8542 26.4452 27.9985C26.5451 28.1428 26.6839 28.276 26.8615 28.398C27.0502 28.509 27.2611 28.6145 27.4941 28.7144C27.7383 28.8032 28.0047 28.8975 28.2933 28.9974C28.8927 29.2194 29.4144 29.4414 29.8584 29.6634C30.3023 29.8743 30.6686 30.124 30.9572 30.4126C31.2569 30.7012 31.4789 31.0397 31.6232 31.4282C31.7675 31.8167 31.8396 32.2884 31.8396 32.8434C31.8396 33.9201 31.4622 34.7581 30.7075 35.3575C29.9527 35.9457 28.815 36.2399 27.2944 36.2399C26.7838 36.2399 26.3231 36.2066 25.9125 36.14C25.5018 36.0845 25.1355 36.0123 24.8136 35.9235C24.5028 35.8347 24.2309 35.7404 23.9978 35.6405C23.7758 35.5406 23.5871 35.4463 23.4317 35.3575L24.1643 33.2929C24.5084 33.4816 24.9302 33.6537 25.4296 33.8091C25.9402 33.9534 26.5618 34.0255 27.2944 34.0255Z'
        fill='url(#paint2_linear_6522_24814)'
      />
      <path
        d='M32.889 31.6946C32.889 30.9176 33.0056 30.2406 33.2387 29.6634C33.4829 29.0751 33.7992 28.5867 34.1877 28.1983C34.5762 27.8098 35.0202 27.5156 35.5196 27.3158C36.0302 27.1161 36.5519 27.0162 37.0847 27.0162C38.3278 27.0162 39.3101 27.3991 40.0316 28.165C40.7531 28.9197 41.1138 30.0352 41.1138 31.5115C41.1138 31.6558 41.1083 31.8167 41.0972 31.9943C41.0861 32.1608 41.075 32.3106 41.0639 32.4438H35.4364C35.4919 32.9544 35.7305 33.3595 36.1523 33.6592C36.5741 33.9589 37.1402 34.1088 37.8505 34.1088C38.3056 34.1088 38.7496 34.0699 39.1825 33.9922C39.6265 33.9034 39.9872 33.798 40.2647 33.6759L40.5977 35.6905C40.4645 35.757 40.2869 35.8236 40.0649 35.8902C39.8429 35.9568 39.5932 36.0123 39.3157 36.0567C39.0493 36.1122 38.7607 36.1566 38.4499 36.1899C38.1391 36.2232 37.8283 36.2399 37.5176 36.2399C36.7295 36.2399 36.0413 36.1233 35.453 35.8902C34.8759 35.6572 34.393 35.3408 34.0046 34.9412C33.6272 34.5305 33.3441 34.0477 33.1554 33.4927C32.9778 32.9378 32.889 32.3384 32.889 31.6946ZM38.7163 30.7456C38.7052 30.5347 38.6664 30.3294 38.5998 30.1296C38.5443 29.9298 38.4499 29.7522 38.3167 29.5968C38.1946 29.4414 38.0337 29.3138 37.8339 29.2139C37.6452 29.114 37.4066 29.064 37.118 29.064C36.8405 29.064 36.6018 29.114 36.4021 29.2139C36.2023 29.3027 36.0358 29.4248 35.9026 29.5802C35.7694 29.7355 35.6639 29.9187 35.5862 30.1296C35.5196 30.3294 35.4697 30.5347 35.4364 30.7456H38.7163Z'
        fill='url(#paint3_linear_6522_24814)'
      />
      <path
        d='M46.1583 36.1733C45.4368 36.1622 44.8486 36.0845 44.3935 35.9402C43.9495 35.7959 43.5943 35.5961 43.3279 35.3408C43.0726 35.0744 42.895 34.7581 42.7951 34.3918C42.7063 34.0144 42.6619 33.5926 42.6619 33.1265V23.4865L45.1427 23.0869V32.627C45.1427 32.849 45.1593 33.0488 45.1926 33.2264C45.2259 33.4039 45.287 33.5538 45.3758 33.6759C45.4757 33.798 45.6144 33.8979 45.792 33.9756C45.9696 34.0533 46.2082 34.1032 46.5079 34.1254L46.1583 36.1733Z'
        fill='url(#paint4_linear_6522_24814)'
      />
      <path
        d='M47.1474 31.6946C47.1474 30.9176 47.2639 30.2406 47.497 29.6634C47.7412 29.0751 48.0575 28.5867 48.446 28.1983C48.8345 27.8098 49.2785 27.5156 49.778 27.3158C50.2885 27.1161 50.8102 27.0162 51.343 27.0162C52.5861 27.0162 53.5685 27.3991 54.2899 28.165C55.0114 28.9197 55.3721 30.0352 55.3721 31.5115C55.3721 31.6558 55.3666 31.8167 55.3555 31.9943C55.3444 32.1608 55.3333 32.3106 55.3222 32.4438H49.6947C49.7502 32.9544 49.9888 33.3595 50.4106 33.6592C50.8324 33.9589 51.3985 34.1088 52.1089 34.1088C52.5639 34.1088 53.0079 34.0699 53.4408 33.9922C53.8848 33.9034 54.2455 33.798 54.523 33.6759L54.856 35.6905C54.7228 35.757 54.5452 35.8236 54.3232 35.8902C54.1012 35.9568 53.8515 36.0123 53.574 36.0567C53.3076 36.1122 53.019 36.1566 52.7082 36.1899C52.3975 36.2232 52.0867 36.2399 51.7759 36.2399C50.9878 36.2399 50.2996 36.1233 49.7114 35.8902C49.1342 35.6572 48.6514 35.3408 48.2629 34.9412C47.8855 34.5305 47.6024 34.0477 47.4138 33.4927C47.2362 32.9378 47.1474 32.3384 47.1474 31.6946ZM52.9746 30.7456C52.9635 30.5347 52.9247 30.3294 52.8581 30.1296C52.8026 29.9298 52.7082 29.7522 52.575 29.5968C52.453 29.4414 52.292 29.3138 52.0922 29.2139C51.9035 29.114 51.6649 29.064 51.3763 29.064C51.0988 29.064 50.8602 29.114 50.6604 29.2139C50.4606 29.3027 50.2941 29.4248 50.1609 29.5802C50.0277 29.7355 49.9223 29.9187 49.8446 30.1296C49.778 30.3294 49.728 30.5347 49.6947 30.7456H52.9746Z'
        fill='url(#paint5_linear_6522_24814)'
      />
      <path
        d='M56.504 31.628C56.504 30.9953 56.6039 30.4015 56.8037 29.8465C57.0146 29.2805 57.3143 28.7921 57.7028 28.3814C58.0913 27.9596 58.563 27.6266 59.118 27.3824C59.6729 27.1382 60.3056 27.0162 61.016 27.0162C61.4822 27.0162 61.9095 27.0606 62.298 27.1493C62.6865 27.227 63.0639 27.3436 63.4301 27.499L62.914 29.4803C62.6809 29.3915 62.4256 29.3138 62.1482 29.2472C61.8707 29.1806 61.5599 29.1473 61.2158 29.1473C60.4832 29.1473 59.9338 29.3748 59.5675 29.8299C59.2123 30.285 59.0347 30.8843 59.0347 31.628C59.0347 32.4161 59.2012 33.0266 59.5342 33.4594C59.8783 33.8923 60.4721 34.1088 61.3157 34.1088C61.6154 34.1088 61.9373 34.081 62.2813 34.0255C62.6254 33.97 62.9418 33.8812 63.2304 33.7591L63.58 35.7903C63.2914 35.9124 62.9307 36.0179 62.4978 36.1067C62.0649 36.1955 61.5876 36.2399 61.0659 36.2399C60.2668 36.2399 59.5786 36.1233 59.0014 35.8902C58.4242 35.6461 57.947 35.3186 57.5696 34.9079C57.2033 34.4973 56.9314 34.0144 56.7538 33.4594C56.5873 32.8934 56.504 32.2829 56.504 31.628Z'
        fill='url(#paint6_linear_6522_24814)'
      />
      <path
        d='M64.7954 25.0682L67.2761 24.6686V27.2492H70.2564V29.3138H67.2761V32.3939C67.2761 32.9156 67.3649 33.3318 67.5425 33.6426C67.7312 33.9534 68.1031 34.1088 68.658 34.1088C68.9244 34.1088 69.1964 34.0866 69.4738 34.0422C69.7624 33.9867 70.0233 33.9145 70.2564 33.8257L70.606 35.757C70.3063 35.8791 69.9733 35.9846 69.607 36.0734C69.2408 36.1622 68.7912 36.2066 68.2585 36.2066C67.5814 36.2066 67.0209 36.1178 66.5769 35.9402C66.1329 35.7515 65.7777 35.4962 65.5113 35.1743C65.2449 34.8413 65.0562 34.4418 64.9452 33.9756C64.8453 33.5094 64.7954 32.9933 64.7954 32.4272V25.0682Z'
        fill='url(#paint7_linear_6522_24814)'
      />
      <path
        d='M77.1881 29.447C76.9661 29.3915 76.7053 29.336 76.4056 29.2805C76.1059 29.2139 75.784 29.1806 75.4399 29.1806C75.2845 29.1806 75.0958 29.1972 74.8738 29.2305C74.6629 29.2527 74.502 29.2805 74.391 29.3138V36.0068H71.9102V27.7154C72.3542 27.56 72.8759 27.4157 73.4753 27.2825C74.0858 27.1382 74.7628 27.0661 75.5065 27.0661C75.6397 27.0661 75.8006 27.0772 75.9893 27.0994C76.178 27.1105 76.3667 27.1327 76.5554 27.166C76.7441 27.1882 76.9328 27.2215 77.1215 27.2659C77.3102 27.2992 77.4711 27.3436 77.6043 27.3991L77.1881 29.447Z'
        fill='url(#paint8_linear_6522_24814)'
      />
      <path
        d='M81.7518 34.2753C81.996 34.2753 82.2291 34.2697 82.4511 34.2586C82.6731 34.2475 82.8507 34.2309 82.9839 34.2087V32.3273C82.884 32.3051 82.7341 32.2829 82.5343 32.2607C82.3345 32.2385 82.1514 32.2274 81.9849 32.2274C81.7518 32.2274 81.5298 32.244 81.3189 32.2773C81.1191 32.2995 80.9415 32.3495 80.7862 32.4272C80.6308 32.5049 80.5087 32.6103 80.4199 32.7435C80.3311 32.8767 80.2867 33.0432 80.2867 33.243C80.2867 33.6315 80.4143 33.9034 80.6696 34.0588C80.936 34.2031 81.2967 34.2753 81.7518 34.2753ZM81.552 27.0162C82.2846 27.0162 82.8951 27.0994 83.3835 27.2659C83.8718 27.4324 84.2603 27.671 84.5489 27.9818C84.8486 28.2926 85.0595 28.67 85.1816 29.114C85.3037 29.558 85.3647 30.0519 85.3647 30.5958V35.757C85.0095 35.8347 84.5156 35.9235 83.8829 36.0234C83.2503 36.1344 82.4844 36.1899 81.5853 36.1899C81.0192 36.1899 80.5031 36.14 80.0369 36.0401C79.5818 35.9402 79.1878 35.7792 78.8548 35.5573C78.5219 35.3242 78.2666 35.0245 78.089 34.6582C77.9114 34.2919 77.8226 33.8424 77.8226 33.3096C77.8226 32.799 77.9225 32.3661 78.1223 32.011C78.3332 31.6558 78.6106 31.3727 78.9547 31.1618C79.2988 30.9509 79.6928 30.8011 80.1368 30.7123C80.5808 30.6124 81.0414 30.5625 81.5187 30.5625C81.8406 30.5625 82.1236 30.5791 82.3678 30.6124C82.6231 30.6346 82.8285 30.6679 82.9839 30.7123V30.4792C82.9839 30.0574 82.8562 29.7189 82.6009 29.4636C82.3456 29.2083 81.9017 29.0807 81.269 29.0807C80.8472 29.0807 80.431 29.114 80.0203 29.1806C79.6096 29.2361 79.2544 29.3193 78.9547 29.4303L78.6384 27.4324C78.7827 27.388 78.9603 27.3436 79.1712 27.2992C79.3932 27.2437 79.6318 27.1993 79.8871 27.166C80.1424 27.1216 80.4088 27.0883 80.6863 27.0661C80.9748 27.0328 81.2634 27.0162 81.552 27.0162Z'
        fill='url(#paint9_linear_6522_24814)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39.7599 14.1997L35.8338 8.23346C36.9134 6.95171 39.6372 5.91663 40.7415 7.3713C42.6065 9.91002 42.784 9.99467 43.1198 9.53819C44.2626 7.68872 48.3802 0.0628786 48.4241 0H36.1678C34.8787 0.0133012 33.4639 1.34887 33.503 2.9305L33.5 15.1664C33.5132 16.4609 34.7259 18.0873 36.2099 18.0873L49.446 18.0806C50.93 18.0873 52.13 16.7807 52.1083 15.3581L52.13 3.22071C49.6728 7.595 46.2859 14.1041 46.2787 14.1168C44.8145 16.6652 41.5268 16.6157 39.7599 14.1997Z'
        fill='url(#paint10_linear_6522_24814)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_6522_24814'
          x1='-1.50598'
          y1='38.088'
          x2='92.1874'
          y2='38.088'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4D99FA' />
          <stop offset='0.394298' stopColor='#015AA3' />
          <stop offset='0.73836' stopColor='#4D99FA' />
          <stop offset='1' stopColor='#015AA3' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_6522_24814'
          x1='-1.50598'
          y1='38.088'
          x2='92.1874'
          y2='38.088'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4D99FA' />
          <stop offset='0.394298' stopColor='#015AA3' />
          <stop offset='0.73836' stopColor='#4D99FA' />
          <stop offset='1' stopColor='#015AA3' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_6522_24814'
          x1='-1.50598'
          y1='38.088'
          x2='92.1874'
          y2='38.088'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4D99FA' />
          <stop offset='0.394298' stopColor='#015AA3' />
          <stop offset='0.73836' stopColor='#4D99FA' />
          <stop offset='1' stopColor='#015AA3' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_6522_24814'
          x1='-1.50598'
          y1='38.088'
          x2='92.1874'
          y2='38.088'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4D99FA' />
          <stop offset='0.394298' stopColor='#015AA3' />
          <stop offset='0.73836' stopColor='#4D99FA' />
          <stop offset='1' stopColor='#015AA3' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_6522_24814'
          x1='-1.50598'
          y1='38.088'
          x2='92.1874'
          y2='38.088'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4D99FA' />
          <stop offset='0.394298' stopColor='#015AA3' />
          <stop offset='0.73836' stopColor='#4D99FA' />
          <stop offset='1' stopColor='#015AA3' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_6522_24814'
          x1='-1.50598'
          y1='38.088'
          x2='92.1874'
          y2='38.088'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4D99FA' />
          <stop offset='0.394298' stopColor='#015AA3' />
          <stop offset='0.73836' stopColor='#4D99FA' />
          <stop offset='1' stopColor='#015AA3' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_6522_24814'
          x1='-1.50598'
          y1='38.088'
          x2='92.1874'
          y2='38.088'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4D99FA' />
          <stop offset='0.394298' stopColor='#015AA3' />
          <stop offset='0.73836' stopColor='#4D99FA' />
          <stop offset='1' stopColor='#015AA3' />
        </linearGradient>
        <linearGradient
          id='paint7_linear_6522_24814'
          x1='-1.50598'
          y1='38.088'
          x2='92.1874'
          y2='38.088'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4D99FA' />
          <stop offset='0.394298' stopColor='#015AA3' />
          <stop offset='0.73836' stopColor='#4D99FA' />
          <stop offset='1' stopColor='#015AA3' />
        </linearGradient>
        <linearGradient
          id='paint8_linear_6522_24814'
          x1='-1.50598'
          y1='38.088'
          x2='92.1874'
          y2='38.088'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4D99FA' />
          <stop offset='0.394298' stopColor='#015AA3' />
          <stop offset='0.73836' stopColor='#4D99FA' />
          <stop offset='1' stopColor='#015AA3' />
        </linearGradient>
        <linearGradient
          id='paint9_linear_6522_24814'
          x1='-1.50598'
          y1='38.088'
          x2='92.1874'
          y2='38.088'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4D99FA' />
          <stop offset='0.394298' stopColor='#015AA3' />
          <stop offset='0.73836' stopColor='#4D99FA' />
          <stop offset='1' stopColor='#015AA3' />
        </linearGradient>
        <linearGradient
          id='paint10_linear_6522_24814'
          x1='33.2854'
          y1='18.0872'
          x2='53.6451'
          y2='18.0872'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4D99FA' />
          <stop offset='1' stopColor='#015AA3' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoTablet;
