const GenericIllustration = () => (
  <svg width='271' height='206' viewBox='0 0 271 206' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_2029_9286)'>
      <path
        d='M140.093 41.6448C142.748 40.8118 163.787 6.16687 217.517 18.4666L220.614 20.7697L215.698 21.8967L211.814 24.5429L210.241 30.4232L205.326 32.5794L204.047 27.336L197.46 28.7572L194.167 33.6574L186.498 32.8243L189.153 39.2437L181.189 42.5759L177.502 45.8591L174.061 43.7029L163.542 50.3183L162.362 56.5907L156.856 55.8066V50.2203L149.925 42.6249L145.009 46.4471L140.093 41.6448Z'
        fill='#F4C887'
      />
      <path
        d='M140.093 41.6449C140.093 41.6449 140.978 43.654 142.453 43.8991C143.927 44.1441 148.696 41.0079 148.696 41.0079L156.414 34.7845L159.855 34.4905L160.297 38.9008L162.952 40.6159L170.817 36.5976L174.553 38.9988L178.387 37.8717L179.124 33.5105L185.859 31.8444L189.054 25.18L195.691 23.4649L197.903 25.7681L201.885 23.7589L206.063 19.1527L215.157 18.0256C215.157 18.0256 170.866 4.30484 140.093 41.6449Z'
        fill='url(#paint0_linear_2029_9286)'
      />
      <path
        d='M185.613 155.723C188.602 161.422 190.723 167.533 191.905 173.854C192.79 179.539 191.02 186.203 193.921 191.348C197.924 197.148 204.016 201.184 210.929 202.619C217.565 199.434 225.185 196.837 231.674 199.532C234.306 196.627 236.203 193.136 237.203 189.35C238.204 185.565 238.28 181.595 237.425 177.775C235.803 172.09 231.575 166.945 232.509 160.771C233.657 156.377 236.364 152.546 240.129 149.99C244.24 145.617 247.403 140.445 249.42 134.799C250.649 132.349 252.369 130.291 253.696 127.939C255.02 125.091 256.057 122.12 256.793 119.069C259.367 112.488 259.965 105.304 258.514 98.3901C256.264 92.0242 254.618 85.4617 253.598 78.789C253.437 77.7105 253.682 76.6107 254.286 75.7018L252.517 68.1554L249.813 67.2734L247.109 64.7742L244.16 63.6472C242.499 65.2914 240.371 66.3863 238.064 66.7833L236.245 64.3332L236.688 58.6489C234.307 57.5249 231.636 57.1617 229.04 57.6091C226.444 58.0565 224.051 59.2925 222.186 61.1481L219.237 66.7833L215.157 67.2734L213.141 63.8432C210.376 64.6235 207.823 66.0136 205.67 67.9104L202.032 69.5275L199.771 72.4677L189.595 71.9776L184.384 76.8779L182.123 83.8853L176.224 87.0215L176.666 92.4608V95.4009L181.189 99.6642L180.058 101.477L176.421 102.408C172.423 101.265 168.139 101.668 164.426 103.535C159.215 106.672 161.034 106.231 161.034 106.231L162.411 109.857L154.939 112.111L156.512 115.933C156.512 115.933 150.318 118.089 151.596 121.078C151.596 121.078 158.773 139.503 165.704 145.58C169.695 148.874 173.899 151.902 178.289 154.645C178.798 154.14 179.419 153.76 180.102 153.536C180.785 153.312 181.511 153.25 182.222 153.355C182.933 153.459 183.61 153.728 184.198 154.139C184.787 154.55 185.272 155.093 185.613 155.723Z'
        fill='#F4C887'
      />
      <path
        d='M200.164 195.513L200.508 197.718C200.848 199.257 201.584 200.681 202.645 201.85C203.705 203.019 205.052 203.892 206.554 204.383C210.484 205.867 214.73 206.323 218.887 205.705C223.044 205.087 226.972 203.417 230.297 200.855C231.577 199.929 232.593 198.688 233.248 197.254C233.903 195.82 234.173 194.241 234.033 192.671L233.689 190.466L200.164 195.513Z'
        fill='#8E5D16'
      />
      <path
        d='M192.692 192.867C194.989 195.223 197.418 197.448 199.967 199.532C204.223 202.634 209.461 204.096 214.714 203.648C215.985 203.595 217.251 203.448 218.5 203.207C219.757 203.05 221.005 202.82 222.236 202.521C227.383 201.382 231.952 198.445 235.115 194.239C236.902 191.502 238.543 188.672 240.031 185.762L192.692 192.867Z'
        fill='#8E5D16'
      />
      <path
        d='M240.375 174.295L238.359 174.589C236.554 172.865 234.413 171.531 232.067 170.669C226.008 168.673 219.563 168.126 213.253 169.071C206.942 170.016 200.943 172.427 195.74 176.108C193.731 177.611 192.057 179.513 190.824 181.695L188.857 181.989L189.742 187.722C190.268 190.022 191.418 192.133 193.065 193.827C194.711 195.52 196.793 196.731 199.082 197.326C205.137 199.35 211.588 199.911 217.903 198.966C224.218 198.02 230.218 195.594 235.41 191.887C237.346 190.655 238.915 188.927 239.952 186.884C240.99 184.842 241.459 182.558 241.309 180.273L240.375 174.295Z'
        fill='url(#paint1_linear_2029_9286)'
      />
      <path
        d='M231.232 164.936C225.175 162.925 218.727 162.369 212.414 163.314C206.1 164.259 200.101 166.679 194.904 170.375C185.761 177.676 187.235 187.085 198.148 191.348C204.212 193.371 210.67 193.934 216.993 192.988C223.316 192.043 229.324 189.617 234.525 185.909C243.619 178.608 242.145 169.248 231.232 164.936Z'
        fill='#CA7B11'
      />
      <path
        d='M229.806 164.838C224.216 162.974 218.265 162.447 212.433 163.298C206.601 164.149 201.051 166.355 196.231 169.738C187.776 176.5 189.153 185.174 199.23 189.339C204.825 191.226 210.788 191.766 216.632 190.914C222.476 190.063 228.035 187.844 232.854 184.439C241.309 177.676 239.933 169.003 229.806 164.838Z'
        fill='#CA7B11'
      />
      <path
        d='M189.251 181.989C189.809 184.215 190.95 186.254 192.556 187.898C194.162 189.542 196.176 190.733 198.394 191.348C204.457 193.372 210.915 193.934 217.239 192.988C223.562 192.043 229.57 189.617 234.771 185.909C236.687 184.66 238.238 182.927 239.265 180.887C240.292 178.848 240.76 176.573 240.621 174.295'
        fill='#8E5D16'
      />
      <path
        d='M238.9 164.593L236.885 164.887C235.099 163.163 232.972 161.828 230.642 160.966C224.575 158.967 218.122 158.417 211.803 159.362C205.484 160.307 199.476 162.72 194.265 166.406C192.245 167.897 190.569 169.801 189.349 171.992L187.383 172.286L188.268 178.019C188.826 180.246 189.966 182.285 191.572 183.929C193.179 185.573 195.193 186.763 197.411 187.379C203.466 189.402 209.916 189.964 216.231 189.018C222.547 188.073 228.547 185.647 233.738 181.94C235.667 180.7 237.23 178.971 238.267 176.93C239.304 174.889 239.777 172.609 239.637 170.326L238.9 164.593Z'
        fill='url(#paint2_linear_2029_9286)'
      />
      <path
        d='M229.757 155.233C223.7 153.222 217.252 152.666 210.939 153.612C204.626 154.557 198.626 156.976 193.429 160.672C184.286 167.974 185.761 177.382 196.723 181.646C202.78 183.653 209.227 184.206 215.54 183.261C221.852 182.316 227.852 179.899 233.05 176.206C242.144 168.905 240.67 159.497 229.757 155.233Z'
        fill='#CA7B11'
      />
      <path
        d='M228.577 155.135C222.987 153.272 217.036 152.745 211.204 153.596C205.372 154.447 199.822 156.653 195.002 160.036C186.547 166.798 187.923 175.471 198.05 179.637C203.639 181.508 209.592 182.039 215.425 181.188C221.258 180.336 226.809 178.126 231.625 174.736C240.08 167.974 238.703 159.301 228.577 155.135Z'
        fill='#CA7B11'
      />
      <path
        d='M187.53 172.286C188.088 174.521 189.234 176.566 190.85 178.211C192.466 179.857 194.494 181.042 196.723 181.646C202.78 183.653 209.227 184.206 215.54 183.261C221.852 182.316 227.852 179.899 233.05 176.206C234.981 174.972 236.543 173.242 237.572 171.199C238.601 169.155 239.06 166.874 238.9 164.593'
        fill='#8E5D16'
      />
      <path
        d='M237.475 154.89L235.41 155.184C233.624 153.46 231.498 152.126 229.167 151.264C223.11 149.253 216.662 148.697 210.349 149.642C204.036 150.588 198.036 153.007 192.839 156.703C190.824 158.199 189.148 160.102 187.924 162.29L185.958 162.584L186.842 168.317C187.401 170.543 188.541 172.582 190.147 174.226C191.753 175.871 193.768 177.061 195.986 177.676C202.051 179.688 208.506 180.244 214.827 179.299C221.148 178.353 227.156 175.934 232.362 172.237C234.279 170.988 235.829 169.255 236.856 167.216C237.884 165.176 238.351 162.902 238.212 160.624L237.475 154.89Z'
        fill='url(#paint3_linear_2029_9286)'
      />
      <path
        d='M228.282 145.531C222.227 143.508 215.776 142.946 209.461 143.891C203.146 144.837 197.146 147.263 191.954 150.97C182.811 158.271 184.285 167.631 195.248 171.943C201.307 173.939 207.752 174.486 214.062 173.541C220.373 172.596 226.372 170.186 231.575 166.504C240.719 159.202 239.244 149.794 228.282 145.531Z'
        fill='#CA7B11'
      />
      <path
        d='M226.856 145.433C221.269 143.55 215.314 143.012 209.479 143.864C203.643 144.716 198.093 146.932 193.282 150.333C184.826 157.095 186.203 165.769 196.329 169.934C201.919 171.797 207.87 172.325 213.702 171.473C219.535 170.622 225.085 168.416 229.904 165.034C238.359 158.271 236.983 149.598 226.856 145.433Z'
        fill='#CA7B11'
      />
      <path
        d='M186.055 162.584C186.632 164.809 187.784 166.845 189.398 168.487C191.011 170.13 193.028 171.321 195.248 171.943C201.307 173.939 207.752 174.486 214.062 173.541C220.373 172.596 226.372 170.186 231.575 166.504C233.504 165.264 235.067 163.535 236.104 161.494C237.141 159.453 237.614 157.174 237.474 154.89'
        fill='#8E5D16'
      />
      <path
        d='M236 145.188L233.935 145.482C232.162 143.742 230.032 142.404 227.692 141.561C221.637 139.538 215.187 138.977 208.871 139.922C202.556 140.868 196.556 143.294 191.364 147.001C189.348 148.496 187.673 150.4 186.449 152.587L184.482 152.881L185.367 158.614C185.951 160.847 187.118 162.886 188.75 164.521C190.383 166.157 192.421 167.332 194.658 167.925C200.715 169.932 207.162 170.486 213.475 169.541C219.787 168.595 225.787 166.178 230.985 162.486C232.916 161.251 234.478 159.522 235.508 157.478C236.537 155.435 236.995 153.153 236.835 150.872L236 145.188Z'
        fill='url(#paint4_linear_2029_9286)'
      />
      <path
        d='M223.661 137.2C218.743 135.574 213.509 135.126 208.385 135.892C203.261 136.659 198.39 138.618 194.167 141.61C186.744 147.589 187.973 155.233 196.87 158.712C201.791 160.339 207.028 160.783 212.154 160.008C217.28 159.233 222.149 157.261 226.365 154.253C233.837 148.324 232.608 140.679 223.71 137.2'
        fill='#F4C887'
      />
      <path
        d='M261.808 98.341C260.238 87.8995 255.797 78.0956 248.977 70.0175C248.545 70.4375 248.046 70.7849 247.502 71.0465C244.995 72.1735 242.587 73.0066 241.899 75.9467C241.75 77.0854 241.75 78.2383 241.899 79.3769L242.538 80.063C243.733 80.9767 244.688 82.1665 245.32 83.5298C245.951 84.8932 246.241 86.3893 246.164 87.8891C246.086 89.3888 245.644 90.8473 244.875 92.1389C244.107 93.4304 243.034 94.5163 241.751 95.3028C240.555 95.985 239.138 96.1721 237.805 95.8239C236.472 95.4757 235.33 94.62 234.623 93.4407C233.689 91.5786 233.001 89.6185 232.018 87.7564C231.467 86.6801 230.808 85.6622 230.052 84.7183L229.609 84.3262C227.39 83.9137 225.261 83.1177 223.317 81.9741C223.081 82.144 222.867 82.3414 222.678 82.5621C221.941 83.2482 221.351 84.0322 220.564 84.7183C219.241 85.7504 217.69 86.4533 216.041 86.7686C214.391 87.0839 212.689 87.0026 211.077 86.5313C210.383 86.3495 209.737 86.0184 209.185 85.5612C208.633 85.1041 208.188 84.5321 207.881 83.8852C205.262 84.6187 202.897 86.0602 201.049 88.0504C199.132 90.9416 201.737 97.851 202.573 100.889C202.773 101.648 202.799 102.442 202.648 103.212C202.496 103.982 202.172 104.708 201.699 105.335C201.226 105.962 200.616 106.475 199.916 106.833C199.216 107.192 198.444 107.388 197.657 107.406C194.725 107.131 191.837 106.506 189.054 105.544C187.798 105.06 186.497 104.699 185.171 104.466C183.057 104.221 183.499 104.466 182.909 105.887C181.975 108.387 182.909 111.278 181.336 113.826C179.763 116.374 176.765 117.158 175.782 119.363C173.471 124.753 166.491 121.274 166.245 116.521C164.77 117.354 163.148 118.04 161.771 118.922C158.447 121.111 155.322 123.587 152.432 126.322C157.217 139.577 166.785 150.58 179.269 157.183C191.753 163.785 206.257 165.514 219.951 162.031C233.645 158.548 245.545 150.103 253.33 138.345C261.114 126.587 264.224 112.36 262.053 98.439L261.808 98.341Z'
        fill='url(#paint5_linear_2029_9286)'
      />
      <path
        d='M212.216 160.397C225.237 158.443 234.833 150.503 233.649 142.661C232.465 134.82 220.949 130.047 207.929 132.001C194.908 133.955 185.313 141.895 186.497 149.736C187.681 157.578 199.196 162.35 212.216 160.397Z'
        fill='#8E5D16'
      />
      <path
        d='M216.091 103.878V104.27C214.732 108.175 213.922 112.248 213.682 116.374C213.217 123.292 213.217 130.233 213.682 137.151C214.026 143.326 214.518 147.834 214.567 148.52C214.615 148.874 214.75 149.211 214.96 149.5C215.222 149.867 215.559 150.175 215.949 150.403C216.338 150.631 216.772 150.774 217.222 150.823C217.696 150.845 218.163 150.706 218.549 150.431C218.764 150.283 218.934 150.08 219.04 149.843C219.143 149.507 219.143 149.149 219.04 148.814C219.04 148.814 219.04 148.079 219.04 146.952C218.361 139.327 218.066 131.673 218.156 124.018C218.198 119.684 218.576 115.359 219.286 111.082C219.593 109.404 220.071 107.762 220.712 106.181C220.885 105.738 221.099 105.312 221.351 104.907L216.091 103.878Z'
        fill='white'
      />
      <path
        d='M219.09 104.956L218.156 104.466C217.291 104.085 216.347 103.917 215.403 103.976C214.554 103.994 213.724 104.23 212.994 104.662C212.539 105.004 212.126 105.399 211.765 105.838C211.608 106.055 211.426 106.252 211.224 106.426H210.978H210.291H209.897L209.16 105.937C208.609 105.53 208.016 105.185 207.39 104.907C206.524 104.53 205.581 104.362 204.637 104.417C203.788 104.435 202.959 104.671 202.229 105.103C201.762 105.432 201.348 105.828 201 106.279C200.829 106.5 200.631 106.698 200.41 106.868H199.721H199.279L198.591 106.426C198.029 106.01 197.419 105.665 196.772 105.397C195.907 105.017 194.963 104.848 194.019 104.907C193.17 104.925 192.34 105.161 191.61 105.593C191.155 105.935 190.742 106.33 190.381 106.77L189.841 107.358H189.595H189.251C188.834 107.367 188.433 107.523 188.12 107.799C187.951 107.966 187.817 108.166 187.727 108.387C187.654 108.676 187.654 108.979 187.727 109.269C187.829 109.513 187.961 109.744 188.12 109.955C188.398 110.247 188.733 110.48 189.103 110.641C189.46 110.715 189.828 110.715 190.185 110.641C191.032 110.613 191.859 110.377 192.594 109.955L193.036 109.563C193.334 109.34 193.599 109.076 193.822 108.779C193.98 108.563 194.161 108.365 194.363 108.191H195.051H195.445C195.709 108.325 195.956 108.489 196.182 108.681C196.743 109.097 197.355 109.443 198.001 109.71C198.866 110.091 199.81 110.259 200.754 110.2C201.588 110.188 202.403 109.95 203.113 109.514C203.589 109.196 204.004 108.798 204.342 108.338C204.526 108.129 204.723 107.932 204.932 107.75H205.67H206.063L206.751 108.24C207.319 108.646 207.929 108.991 208.57 109.269C209.436 109.646 210.379 109.814 211.323 109.759C212.171 109.741 213.001 109.505 213.731 109.073C214.187 108.731 214.599 108.336 214.96 107.897L215.501 107.309H215.747H216.435H216.828L217.517 107.75H217.91C218.045 106.73 218.452 105.765 219.09 104.956Z'
        fill='white'
      />
      <path
        d='M203.998 147.834C203.998 146.952 201.835 136.808 198.542 125.734C196.917 120.187 194.929 114.753 192.594 109.465C192.594 109.122 192.25 108.73 192.053 108.387C191.055 109.428 189.743 110.116 188.317 110.347C189.202 112.307 190.087 114.463 190.922 116.668C193.346 123.428 195.397 130.315 197.067 137.298C198.542 143.325 199.377 147.638 199.427 147.736C199.493 148.093 199.644 148.429 199.869 148.716C200.109 149.113 200.471 149.422 200.901 149.598C201.273 149.82 201.697 149.939 202.13 149.941C202.613 149.971 203.089 149.813 203.458 149.5C203.643 149.335 203.793 149.135 203.9 148.912C204.013 148.564 204.046 148.196 203.998 147.834Z'
        fill='white'
      />
      <path
        d='M105.093 44.4381L108.681 49.5834L100.718 62.8631L98.4072 50.6125L105.093 44.4381Z'
        fill='url(#paint6_linear_2029_9286)'
      />
      <path
        d='M108.927 85.1593L93.5405 87.7074L96.5883 97.41L104.847 103.29L102.536 96.3809L108.927 94.6168V85.1593Z'
        fill='url(#paint7_linear_2029_9286)'
      />
      <path
        d='M141.273 71.3406L138.962 84.3753L143.829 94.8619L151.793 90.0106L149.482 80.0141L141.273 71.3406Z'
        fill='#F4C887'
      />
      <path
        d='M121.02 103.584L115.367 107.946L124.854 124.313L133.309 126.616C133.309 126.616 136.161 125.342 136.161 124.558C136.161 123.773 131.785 115.345 131.785 115.345L126.87 114.316L121.02 103.584Z'
        fill='url(#paint8_linear_2029_9286)'
      />
      <path
        d='M152.038 96.4299C151.301 96.6749 142.797 103.584 142.797 103.584L137.881 103.339L145.599 113.14L144.566 116.472L150.465 112.405L151.006 110.102L154.84 100.644L152.038 96.4299Z'
        fill='#F4C887'
      />
      <path
        d='M207.194 43.0169L206.308 50.8574L203.556 52.5725L209.75 54.4346L220.859 53.4056L221.4 47.6232C221.4 47.6232 220.368 48.9953 220.368 48.4562C220.368 47.9172 216.435 36.8916 216.435 36.8916L207.194 43.0169Z'
        fill='url(#paint9_linear_2029_9286)'
      />
      <path d='M237.13 44.5361L238.31 54.4347L246.864 56.6398L243.619 50.5145L237.13 44.5361Z' fill='#F4C887' />
      <path
        d='M244.504 33.1184L251.484 47.4272L258.022 46.9372L270.312 45.3691L268.444 35.1765L259.694 34.1475L252.517 28.3162L244.504 33.1184Z'
        fill='#F4C887'
      />
      <path
        d='M265.888 7L258.71 11.6063L261.611 13.6644C262.14 14.4073 262.83 15.022 263.63 15.4631C264.43 15.9042 265.319 16.1603 266.231 16.2125C269.132 16.2125 270.312 12.8313 270.312 12.8313L265.888 7Z'
        fill='url(#paint10_linear_2029_9286)'
      />
      <path
        d='M141.125 71.2916C140.191 74.8688 139.405 78.4459 138.667 82.0721C138.667 82.3172 139.011 82.4152 139.061 82.0721C139.945 78.4949 140.732 74.8688 141.42 71.2916C141.42 71.2426 141.175 71.0956 141.125 71.2916Z'
        fill='url(#paint11_linear_2029_9286)'
      />
      <path
        d='M143.092 92.9017C141.863 90.3046 140.585 87.7564 139.356 85.2083C139.356 85.2083 139.061 85.2083 139.11 85.2083C140.29 87.7564 141.421 90.3536 142.6 92.9017C142.748 93.2937 143.19 93.1957 143.092 92.9017Z'
        fill='url(#paint12_linear_2029_9286)'
      />
      <path
        d='M151.104 90.6476C148.508 91.7907 145.98 93.0833 143.534 94.5188C143.534 94.5188 143.534 94.8129 143.534 94.5188C146.171 93.4334 148.748 92.2066 151.252 90.8436C151.645 90.9416 151.35 90.5496 151.104 90.6476Z'
        fill='#F4C887'
      />
      <path
        d='M145.599 113.385C143.78 110.102 141.617 106.966 139.552 103.584C139.552 103.584 139.208 103.584 139.552 103.584C141.42 106.77 143.288 110.102 145.451 113.385C145.353 114.022 145.746 113.679 145.599 113.385Z'
        fill='url(#paint13_linear_2029_9286)'
      />
      <path
        d='M155.528 100.987C154.545 99.223 153.415 97.5079 152.333 95.7928H152.038C148.253 98.88 144.665 102.114 141.027 105.593C141.027 105.593 141.027 105.887 141.027 105.593L148.794 99.076C149.58 98.439 150.809 96.7239 151.842 96.9199C152.874 97.1159 154.299 100.497 154.889 101.33C155.479 102.163 155.676 101.281 155.528 100.987Z'
        fill='#F4C887'
      />
      <path
        d='M142.994 104.221C141.622 103.886 140.224 103.673 138.815 103.584C138.471 103.584 138.815 104.074 138.815 104.172C140.175 104.434 141.56 104.549 142.944 104.515C142.944 104.515 143.141 104.221 142.994 104.221Z'
        fill='url(#paint14_linear_2029_9286)'
      />
      <path
        d='M252.173 28.6591C249.518 30.0312 246.913 31.5993 244.307 33.1184C244.307 33.1184 243.865 33.1184 244.013 33.1184V33.3634C246.225 37.3816 248.387 41.3998 250.649 45.32C250.649 45.614 251.288 45.32 251.14 45.32C248.978 41.3018 246.667 37.3326 244.406 33.3144C247.06 31.8933 249.715 30.4723 252.32 28.9042C252.615 28.8552 252.369 28.5611 252.173 28.6591Z'
        fill='#F4C887'
      />
      <path
        d='M260.579 34.9806C258.072 32.7264 255.663 30.5704 252.861 28.3652C255.417 30.6194 257.777 32.9225 260.529 35.0786C260.579 35.6176 260.775 35.1766 260.579 34.9806Z'
        fill='#F4C887'
      />
      <path
        d='M268.395 35.0295C268.395 35.0295 268.1 35.0295 268.149 35.0295V35.6175C265.789 35.0785 263.233 34.6865 261.021 34.3435C263.381 34.9315 265.937 35.4706 268.247 35.8626C268.641 38.5087 269.034 41.2038 269.624 43.801C269.624 43.801 270.017 44.046 270.017 43.801C269.624 40.8118 268.985 37.9207 268.395 35.0295Z'
        fill='#F4C887'
      />
      <path
        d='M269.771 45.0751C265.15 45.6631 260.48 46.4962 255.909 47.2803C255.909 47.2803 255.909 47.6722 255.909 47.6232C260.53 47.0352 265.2 46.4472 269.82 45.5651C270.214 45.4181 270.017 45.0751 269.771 45.0751Z'
        fill='#F4C887'
      />
      <path
        d='M270.656 43.1639C270.656 43.85 270.312 44.5361 270.164 45.2221C270.017 45.9082 270.164 45.5161 270.459 45.2221C270.754 44.9281 270.852 43.85 271 43.1639C271.147 42.4779 270.705 42.9189 270.656 43.1639Z'
        fill='#F4C887'
      />
      <path
        d='M136.308 123.822C134.932 120.98 133.457 118.138 132.031 115.345H132.572V115.002H128.05C125.788 111.376 123.134 107.848 120.922 104.27C120.922 104.27 120.578 104.27 120.676 104.27C122.986 107.799 125.198 111.425 127.558 114.904H126.722V115.149H127.705H128.05H131.638C133.064 117.991 134.44 120.882 135.915 123.725C136.112 123.823 136.406 123.822 136.308 123.822Z'
        fill='url(#paint15_linear_2029_9286)'
      />
      <path
        d='M134.587 125.782L133.997 126.42C131.638 125.832 129.082 125.489 126.722 125.047C126.722 125.047 126.722 125.292 126.722 125.341C129.131 125.832 131.638 126.518 133.997 126.861C133.997 126.861 134.292 126.861 133.997 126.616L134.686 126.028C134.686 126.028 134.735 125.635 134.587 125.782Z'
        fill='url(#paint16_linear_2029_9286)'
      />
      <path
        d='M109.222 85.2573C104.306 85.9434 99.3905 86.5314 94.4747 87.4135C94.1306 87.4135 94.4747 87.9524 94.4747 87.9034C99.3905 87.2664 104.306 86.3844 109.222 85.6494C109.517 85.6004 109.419 85.2573 109.222 85.2573Z'
        fill='url(#paint17_linear_2029_9286)'
      />
      <path
        d='M106.568 103.78C103.962 102.065 101.111 100.644 98.604 98.8801C96.097 97.116 94.573 91.8237 93.2457 88.1975H93L94.9664 93.9308C95.2692 95.2869 95.7311 96.6027 96.3427 97.851C97.759 98.988 99.2916 99.9735 100.915 100.791L106.322 104.172C106.322 104.172 106.764 103.927 106.568 103.78Z'
        fill='url(#paint18_linear_2029_9286)'
      />
      <path
        d='M109.075 85.2573H108.78C108.78 88.3935 108.78 91.5297 108.78 94.6658C108.78 94.9599 109.271 95.0579 109.271 94.6658C109.222 91.5787 109.124 88.3935 109.075 85.2573Z'
        fill='url(#paint19_linear_2029_9286)'
      />
      <path
        d='M107.698 94.4208C106.037 94.7725 104.396 95.2142 102.782 95.7439C102.488 95.7439 102.782 96.1849 102.782 96.2829C103.225 98.194 103.717 100.105 104.208 102.016C104.208 102.31 104.847 102.359 104.749 102.016C104.208 100.056 103.667 98.194 103.077 96.2829C104.745 95.855 106.386 95.3314 107.993 94.7148C107.993 94.7148 107.846 94.4208 107.698 94.4208Z'
        fill='url(#paint20_linear_2029_9286)'
      />
      <path
        d='M98.9972 51.8374C98.9972 51.5434 98.4565 51.4944 98.4565 51.8374C98.8498 55.2186 99.2921 58.6488 99.8328 62.03H100.128C99.8328 58.5998 99.4396 55.2186 98.9972 51.8374Z'
        fill='url(#paint21_linear_2029_9286)'
      />
      <path
        d='M109.714 50.7104C109.369 50.3184 109.074 49.8774 108.73 49.4364V49.1423V49.3873C107.501 47.7212 106.223 46.0061 104.945 44.4871H104.601C102.782 46.5452 100.914 48.5543 99.0464 50.5634C99.0464 50.5634 99.0464 50.8574 99.0464 50.5634L101.602 47.9172C102.192 47.2802 103.716 45.1731 104.552 45.2221C105.388 45.2711 106.322 47.2312 106.813 47.8683L108.288 49.8283C105.486 54.3366 102.684 58.7959 100.079 63.4021C100.079 63.6471 100.374 63.8921 100.521 63.6961C103.274 59.2369 105.83 54.6306 108.435 50.0734C108.725 50.4121 108.988 50.7725 109.222 51.1514C109.468 51.2494 109.91 51.0044 109.714 50.7104Z'
        fill='url(#paint22_linear_2029_9286)'
      />
      <path
        d='M264.167 7.88205C262.447 9.05811 260.677 10.3322 259.251 11.6063C259.251 11.6063 259.251 11.9983 259.251 11.9003C261.021 10.7242 262.742 9.49917 264.462 8.2251C264.708 8.07809 264.413 7.73504 264.167 7.88205Z'
        fill='url(#paint23_linear_2029_9286)'
      />
      <path
        d='M242.931 50.0244C241.014 48.0643 238.999 46.0552 236.983 44.1931C236.983 44.1931 236.541 44.1931 236.688 44.1931C238.605 46.2512 240.621 48.2603 242.636 50.2204C242.882 50.6615 243.324 50.3185 242.931 50.0244Z'
        fill='#F4C887'
      />
      <path
        d='M236.491 44.389C236.491 44.389 236.197 44.389 236.246 44.389C236.836 47.5742 237.278 50.8084 237.917 54.1896C237.917 54.4346 238.409 54.4346 238.36 54.1896C237.819 50.7594 237.081 47.5742 236.491 44.389Z'
        fill='#F4C887'
      />
      <path
        d='M247.306 56.1987C245.831 53.6506 244.307 51.2985 242.734 48.7993C242.734 48.7993 242.439 48.7993 242.734 48.7993C244.16 51.2985 245.585 53.6996 247.109 56.2477C247.06 56.5908 247.453 56.4437 247.306 56.1987Z'
        fill='#F4C887'
      />
      <path
        d='M216.877 36.8427C216.823 36.7945 216.753 36.7678 216.681 36.7678C216.608 36.7678 216.538 36.7945 216.484 36.8427C213.436 39.0478 210.29 41.1059 207.193 43.262V41.547C207.193 41.547 206.898 41.2529 206.849 41.547C206.603 44.7811 206.308 47.9663 206.062 51.3475C206.062 51.6415 206.456 51.7395 206.505 51.3475C206.505 48.8484 206.849 46.4472 207.046 43.801L211.961 40.6649C212.994 39.9298 215.501 37.5287 216.877 38.2637C218.254 38.9988 218.352 42.772 218.696 43.703L220.466 49.1423C220.466 49.4853 221.056 49.1423 220.957 49.1423C219.532 44.7321 218.205 40.7629 216.877 36.8427Z'
        fill='url(#paint24_linear_2029_9286)'
      />
      <path
        d='M219.68 52.8175C215.944 53.5035 212.257 54.2876 208.57 55.0717V55.3657C212.306 54.7286 216.042 54.0916 219.778 53.3076C220.171 53.2096 219.925 52.7685 219.68 52.8175Z'
        fill='url(#paint25_linear_2029_9286)'
      />
      <path
        d='M221.498 46.5452C221.498 46.4802 221.472 46.4179 221.426 46.372C221.38 46.326 221.318 46.3002 221.252 46.3002C221.187 46.3002 221.125 46.326 221.079 46.372C221.033 46.4179 221.007 46.4802 221.007 46.5452C220.884 48.7813 220.884 51.0225 221.007 53.2586C221.295 51.0317 221.459 48.7904 221.498 46.5452Z'
        fill='url(#paint26_linear_2029_9286)'
      />
      <path
        d='M205.67 52.2295H203.998C203.998 52.2295 203.998 52.6215 203.998 52.6705C203.998 52.7195 205.03 52.6705 205.522 52.6705C206.014 52.6705 205.915 52.2295 205.67 52.2295Z'
        fill='url(#paint27_linear_2029_9286)'
      />
    </g>
    <g clipPath='url(#clip1_2029_9286)'>
      <path
        d='M52.0631 160.069C53.1589 162.072 53.6997 164.33 53.6298 166.61C53.6298 168.998 52.8151 171.293 49.5093 169.826C45.4514 168.062 45.0127 161.63 45.1537 157.618C45.1829 156.684 45.5262 155.787 46.1286 155.071C46.7309 154.355 47.5574 153.861 48.4752 153.669L52.0631 160.069Z'
        fill='#8E5D16'
      />
      <path
        d='M60.0375 153.887L50.214 160.053C50.214 160.053 45.7801 159.382 46.5635 156.151L49.697 149.907L60.0375 153.887Z'
        fill='#CCBBA3'
      />
      <path
        d='M56.497 54.1514C53.4575 39.5555 36.1293 60.0834 34.3902 65.3754C30.8964 76.0374 38.9808 80.1742 39.5605 82.547C40.7512 87.2301 32.2438 85.8876 30.63 84.108C15.2759 66.9364 7.12887 71.1981 3.5567 75.3037C-4.90372 85.1539 3.40003 97.1584 11.0771 103.871C10.278 100.749 11.4844 94.5047 17.2657 95.5974C23.047 96.6901 26.7758 116.422 31.7894 118.264C44.0727 122.76 49.1803 102.31 56.8573 95.051C61.5575 90.6332 71.0363 87.7453 73.5118 81.6884C75.7366 76.2403 69.5323 71.3074 66.7905 66.7959C64.5657 63.1118 64.9417 47.2671 56.497 54.1514Z'
        fill='url(#paint28_linear_2029_9286)'
      />
      <path
        d='M60.7586 87.199C62.1432 90.0115 62.9084 93.0864 63.003 96.2175C63.0976 99.3487 62.5193 102.464 61.307 105.354L55.4317 112.8C55.4317 112.8 51.2955 117.936 41.5817 106.416C42.4931 105.609 43.1628 104.566 43.5167 103.404C43.8706 102.241 43.895 101.004 43.5871 99.828C42.318 96.0658 41.1743 93.0062 41.1743 93.0062L45.8745 82.2661L54.6326 82.953C55.9354 83.0714 57.1849 83.5251 58.2588 84.2694C59.3327 85.0137 60.1941 86.0232 60.7586 87.199Z'
        fill='#CCBBA3'
      />
      <path
        d='M65.2394 116.984C63.986 114.252 61.4792 106.853 60.6645 105.901C60.6645 105.901 57.1237 105.26 55.2593 104.979C55.3846 110.225 60.2885 113.362 62.6543 118.373C63.7824 117.686 64.2524 117.905 65.2394 116.984Z'
        fill='#CCBBA3'
      />
      <path
        d='M47.9583 83.9832C47.9583 83.9832 45.3576 77.3956 41.1744 81.1577C36.9912 84.9199 30.9592 97.58 30.9592 97.58C27.7897 100.193 24.8445 103.065 22.1541 106.166C19.9639 108.607 17.2033 110.473 14.1167 111.598L17.8926 114.049C17.8926 114.049 31.7269 107.149 34.594 102.544C37.4612 97.9391 46.6736 87.0741 46.6736 87.0741L47.9583 83.9832Z'
        fill='#CCBBA3'
      />
      <path
        d='M18.0488 113.643C17.6923 115.064 17.0877 116.412 16.2627 117.624C14.9466 119.497 14.9779 123.088 14.0222 121.589C13.0665 120.091 14.5236 118.717 14.0222 116.906C13.5209 115.095 9.14966 120.496 7.75526 120.356C7.75526 120.356 6.92487 120.091 8.78929 118.623C9.3155 118.304 9.73582 117.838 9.99775 117.283C10.2597 116.728 10.3516 116.108 10.262 115.501C10.262 115.501 4.96644 118.155 3.99506 117.249C3.02368 116.344 4.07341 117.624 8.85198 113.909C8.85198 113.909 2.30298 113.487 1.94263 111.38C1.73896 110.225 2.39699 112.473 7.69258 112.207C7.69258 112.207 3.80706 110.131 4.90378 109.085C6.37249 110.301 8.15196 111.084 10.0427 111.349C13.8029 112.176 14.8526 111.349 14.8526 111.349L18.0488 113.643Z'
        fill='#CCBBA3'
      />
      <path
        d='M62.6543 118.326C62.9193 119.408 63.3755 120.433 64.0017 121.355C65.0044 122.775 64.9731 125.507 65.7094 124.368C66.4458 123.228 65.3178 122.182 65.7094 120.793C66.1011 119.404 69.4226 123.54 70.535 123.431C70.535 123.431 71.1617 123.228 69.736 122.12C69.3441 121.877 69.0308 121.528 68.8335 121.112C68.6362 120.697 68.5633 120.234 68.6236 119.778C68.6236 119.778 72.6501 121.808 73.4178 121.105C74.1855 120.403 73.4178 121.402 69.7046 118.576C69.7046 118.576 74.7026 118.248 74.9689 116.656C75.1256 115.766 74.6242 117.483 70.5977 117.265C70.5977 117.265 73.5588 115.704 72.7128 114.923C71.5986 115.85 70.248 116.45 68.8116 116.656C65.9445 117.265 65.1454 116.656 65.1454 116.656L62.6543 118.326Z'
        fill='#CCBBA3'
      />
      <path
        d='M59.4425 68.9971C59.3015 68.6381 59.8185 68.1854 59.7245 66.9521C59.7245 66.5931 59.7245 66.2653 59.3642 66.078C57.8232 64.9186 56.5824 63.4093 55.745 61.6758C54.6483 66.8429 46.8146 66.9209 46.6422 68.9035C46.4229 67.8576 46.3759 67.4361 46.3759 67.4361C46.3759 67.4361 44.0884 66.1092 43.023 68.1073C41.9577 70.1055 44.7308 72.7905 46.8772 71.5885C47.1371 72.708 47.6759 73.7442 48.444 74.6013C48.9302 75.1284 49.2372 75.7948 49.3214 76.5058C49.4619 77.8331 49.3394 79.175 48.961 80.4553C48.3887 81.4468 47.4586 82.1836 46.3602 82.5159C52.5332 103.184 55.2436 83.031 55.2436 83.031C54.758 82.8125 54.5543 77.739 54.8676 77.7703C57.5937 78.1605 57.5781 76.4902 58.3614 75.3975C59.1131 74.5318 59.6115 73.477 59.8022 72.3482C59.9928 71.2195 59.8684 70.0602 59.4425 68.9971Z'
        fill='#CCBBA3'
      />
      <path
        d='M60.7583 87.199C59.7086 84.9979 47.911 84.7169 46.0466 84.6701C44.0568 83.9676 42.1454 83.1091 40.1713 82.2349C36.0821 86.918 30.9589 97.6425 30.9589 97.6425C31.5072 96.9556 26.6973 100.546 21.2764 107.227C21.9344 108.554 22.5924 109.881 23.2348 111.224C27.4493 108.788 32.9173 105.307 34.5937 102.607C35.9568 100.421 38.7456 96.8307 41.2993 93.6618C41.2993 93.6618 41.9887 95.2229 43.0071 98.1576C43.9158 97.9079 58.8469 96.5966 62.3564 102.17C63.6049 97.1472 63.0392 91.8473 60.7583 87.199Z'
        fill='#F5A422'
      />
      <path
        d='M43.681 147.456C44.0101 147.534 42.788 136.528 39.8895 127.037L50.7627 113.596L41.8793 107.664C41.8793 107.664 27.2773 112.816 26.1335 123.275C24.9898 133.734 33.5442 153.887 33.7322 153.591C34.0143 169.841 61.4009 194.35 61.4009 194.35H61.3069C62.7796 194.943 63.046 195.084 64.1584 193.085C58.8471 186.264 45.7335 159.491 43.681 147.456Z'
        fill='#CCBBA3'
      />
      <path
        d='M63.2341 191.837C63.2341 191.837 65.5529 192.867 68.8274 189.339C71.2558 186.748 74.922 185.14 73.8879 191.509C72.9009 197.55 69.5951 195.427 67.6993 199.205C67.3366 199.913 66.7305 200.467 65.9916 200.766C64.8792 201.187 63.3908 201.047 62.4664 197.94L61.354 194.49L63.2341 191.837Z'
        fill='#C17E1E'
      />
      <path
        d='M58.1734 73.1495L54.7266 72.8529C54.6782 72.8504 54.6299 72.8598 54.5859 72.8801C54.5419 72.9005 54.5036 72.9312 54.4743 72.9697C54.4449 73.0081 54.4254 73.0532 54.4176 73.1008C54.4097 73.1485 54.4136 73.1974 54.4289 73.2432C54.5024 73.6921 54.724 74.1038 55.0587 74.4131C55.3934 74.7225 55.822 74.9118 56.2768 74.9512C56.7316 74.9906 57.1865 74.8778 57.5698 74.6307C57.953 74.3835 58.2426 74.0162 58.3928 73.5866C58.4161 73.5445 58.4288 73.4974 58.4298 73.4493C58.4308 73.4012 58.42 73.3536 58.3985 73.3106C58.3769 73.2676 58.3451 73.2305 58.3059 73.2024C58.2667 73.1743 58.2212 73.1562 58.1734 73.1495Z'
        fill='white'
      />
      <path
        d='M55.4316 112.8L53.8649 123.821C53.8649 123.821 65.1924 139.994 62.1373 151.92C62.1373 151.92 60.5705 159.601 49.76 149.875C49.76 149.875 46.4228 136.372 39.4665 130.862L36.333 126.6L50.3397 112.832C50.3397 112.832 54.1625 114.236 55.4316 112.8Z'
        fill='#8E5D16'
      />
      <path
        d='M41.4875 106.072C41.4875 106.072 26.9481 111.192 25.8201 121.683C24.692 132.173 33.0898 152.295 33.0898 152.295C33.0898 152.295 34.6565 144.677 43.6966 146.207C42.9892 139.139 41.5828 132.159 39.4977 125.367L50.2926 112.878L41.4875 106.072Z'
        fill='#C17E1E'
      />
    </g>
    <g clipPath='url(#clip2_2029_9286)'>
      <path
        d='M65.4643 39.8339C69.3303 41.0697 73.502 40.9511 77.2915 39.4977C81.7256 37.7966 85.3032 34.4049 87.2381 30.0678C89.1731 25.7306 89.3072 20.8026 87.611 16.3666C86.7698 14.1702 85.5042 12.161 83.8865 10.4537C82.2688 8.74644 80.3307 7.3745 78.1827 6.41624C76.0348 5.45798 73.7192 4.93221 71.3681 4.86887C69.0169 4.80553 66.6764 5.2059 64.48 6.04713C62.2827 6.88725 60.2726 8.15217 58.5646 9.76965C56.8566 11.3871 55.4842 13.3255 54.5258 15.4737C53.5674 17.622 53.0418 19.9381 52.9791 22.2896C52.9164 24.6411 53.3179 26.9819 54.1604 29.1782C55.4254 32.4878 57.6488 35.3449 60.5463 37.3841L59.5587 45.1298L65.4643 39.8339Z'
        fill='url(#paint29_linear_2029_9286)'
      />
      <g clipPath='url(#clip3_2029_9286)'>
        <path
          d='M71 13C72.9778 13 74.9112 13.5865 76.5557 14.6853C78.2002 15.7841 79.4819 17.3459 80.2388 19.1732C80.9957 21.0004 81.1937 23.0111 80.8079 24.9509C80.422 26.8907 79.4696 28.6726 78.0711 30.0711C76.6725 31.4696 74.8907 32.422 72.9509 32.8079C71.0111 33.1937 69.0004 32.9957 67.1732 32.2388C65.3459 31.4819 63.7841 30.2002 62.6853 28.5557C61.5865 26.9112 61 24.9778 61 23C61 20.3478 62.0536 17.8043 63.9289 15.9289C65.8043 14.0536 68.3478 13 71 13ZM71 11C68.6266 11 66.3065 11.7038 64.3332 13.0224C62.3598 14.3409 60.8217 16.2151 59.9135 18.4078C59.0052 20.6005 58.7676 23.0133 59.2306 25.3411C59.6936 27.6689 60.8365 29.8071 62.5147 31.4853C64.193 33.1635 66.3311 34.3064 68.6589 34.7694C70.9867 35.2324 73.3995 34.9948 75.5922 34.0866C77.7849 33.1783 79.6591 31.6402 80.9776 29.6668C82.2962 27.6934 83 25.3734 83 23C83 19.8174 81.7357 16.7652 79.4853 14.5147C77.2349 12.2643 74.1826 11 71 11ZM72.25 28C72.25 28.2472 72.1767 28.4889 72.0393 28.6945C71.902 28.9 71.7068 29.0602 71.4784 29.1548C71.25 29.2495 70.9986 29.2742 70.7561 29.226C70.5137 29.1778 70.2909 29.0587 70.1161 28.8839C69.9413 28.7091 69.8223 28.4863 69.774 28.2439C69.7258 28.0014 69.7505 27.7501 69.8452 27.5217C69.9398 27.2932 70.1 27.098 70.3055 26.9607C70.5111 26.8233 70.7528 26.75 71 26.75C71.3315 26.75 71.6495 26.8817 71.8839 27.1161C72.1183 27.3505 72.25 27.6685 72.25 28ZM73.64 18C73.3037 17.6709 72.9046 17.4128 72.4666 17.241C72.0285 17.0692 71.5604 16.9872 71.09 17C68.91 17 67.5 18.55 67.5 20.95H69.5C69.5 19.47 70.33 18.95 71.04 18.95C71.3809 18.9362 71.7138 19.0559 71.9678 19.2838C72.2218 19.5116 72.3768 19.8296 72.4 20.17C72.47 21.02 72.01 21.45 71.4 22C70.8723 22.3946 70.4608 22.9243 70.2091 23.5333C69.9574 24.1423 69.8748 24.808 69.97 25.46H71.97C71.9436 25.0507 72.014 24.6409 72.1756 24.2638C72.3372 23.8868 72.5854 23.5532 72.9 23.29C73.7836 22.5281 74.336 21.4521 74.44 20.29C74.465 19.8759 74.407 19.4609 74.2697 19.0694C74.1323 18.6779 73.9183 18.3178 73.64 18.01V18Z'
          fill='white'
        />
      </g>
    </g>
    <g clipPath='url(#clip4_2029_9286)'>
      <path
        d='M115.68 163.7C120.36 163.57 122.31 165.52 122.31 169.7C122.31 170.09 123.09 170.09 123.09 169.7C122.96 165.54 124.39 163.59 129.45 163.46C129.84 163.46 129.84 162.68 129.45 162.68C124.26 162.81 122.83 160.48 122.83 156.68C122.83 156.29 122.05 156.29 122.05 156.68C122.18 161.09 120.36 162.78 115.68 162.91C115.26 163.05 115.42 163.7 115.68 163.7Z'
        fill='#1460AA'
      />
      <path
        d='M102.2 152.48C104.4 152.48 105.31 153.39 105.44 155.34C105.44 155.47 105.83 155.47 105.83 155.34C105.83 153.39 106.48 152.34 108.95 152.34C109.08 152.34 109.08 151.95 108.95 151.95C106.48 151.95 105.83 150.95 105.7 149.09C105.7 148.96 105.31 148.96 105.31 149.09C105.31 151.17 104.53 152.09 102.2 152.09C101.94 152.22 101.94 152.48 102.2 152.48Z'
        fill='#1460AA'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_2029_9286'
        x1='140.192'
        y1='29.7373'
        x2='215.255'
        y2='29.7373'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_2029_9286'
        x1='189.005'
        y1='183.9'
        x2='241.358'
        y2='183.9'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_2029_9286'
        x1='187.53'
        y1='174.197'
        x2='239.883'
        y2='174.197'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_2029_9286'
        x1='186.056'
        y1='164.495'
        x2='238.409'
        y2='164.495'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint4_linear_2029_9286'
        x1='184.581'
        y1='154.743'
        x2='236.983'
        y2='154.743'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint5_linear_2029_9286'
        x1='152.186'
        y1='116.913'
        x2='262.446'
        y2='116.913'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint6_linear_2029_9286'
        x1='98.4072'
        y1='53.6506'
        x2='108.681'
        y2='53.6506'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint7_linear_2029_9286'
        x1='93.5405'
        y1='94.2248'
        x2='108.927'
        y2='94.2248'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint8_linear_2029_9286'
        x1='115.367'
        y1='115.1'
        x2='136.161'
        y2='115.1'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint9_linear_2029_9286'
        x1='203.556'
        y1='45.6631'
        x2='221.4'
        y2='45.6631'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint10_linear_2029_9286'
        x1='258.71'
        y1='11.6062'
        x2='270.312'
        y2='11.6062'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint11_linear_2029_9286'
        x1='138.618'
        y1='76.7799'
        x2='141.42'
        y2='76.7799'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint12_linear_2029_9286'
        x1='139.11'
        y1='89.1775'
        x2='143.092'
        y2='89.1775'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint13_linear_2029_9286'
        x1='139.257'
        y1='108.73'
        x2='145.599'
        y2='108.73'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint14_linear_2029_9286'
        x1='138.569'
        y1='103.829'
        x2='143.141'
        y2='103.829'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint15_linear_2029_9286'
        x1='120.627'
        y1='113.973'
        x2='136.357'
        y2='113.973'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint16_linear_2029_9286'
        x1='126.575'
        y1='125.979'
        x2='134.882'
        y2='125.979'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint17_linear_2029_9286'
        x1='94.3764'
        y1='86.5804'
        x2='109.419'
        y2='86.5804'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint18_linear_2029_9286'
        x1='93'
        y1='96.087'
        x2='106.666'
        y2='96.087'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint19_linear_2029_9286'
        x1='108.731'
        y1='90.0106'
        x2='109.222'
        y2='90.0106'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint20_linear_2029_9286'
        x1='102.34'
        y1='98.439'
        x2='107.895'
        y2='98.439'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint21_linear_2029_9286'
        x1='98.4565'
        y1='56.8847'
        x2='100.128'
        y2='56.8847'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint22_linear_2029_9286'
        x1='98.8498'
        y1='53.8956'
        x2='109.763'
        y2='53.8956'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint23_linear_2029_9286'
        x1='258.957'
        y1='9.89118'
        x2='264.56'
        y2='9.89118'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint24_linear_2029_9286'
        x1='206.21'
        y1='43.9481'
        x2='220.81'
        y2='43.9481'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint25_linear_2029_9286'
        x1='208.472'
        y1='54.0916'
        x2='220.024'
        y2='54.0916'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint26_linear_2029_9286'
        x1='220.958'
        y1='49.8284'
        x2='221.547'
        y2='49.8284'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint27_linear_2029_9286'
        x1='203.998'
        y1='52.4255'
        x2='205.866'
        y2='52.4255'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <linearGradient
        id='paint28_linear_2029_9286'
        x1='0.00019042'
        y1='83.9207'
        x2='73.9662'
        y2='83.9207'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#9A8E7A' />
        <stop offset='1' stopColor='#332F29' />
      </linearGradient>
      <linearGradient
        id='paint29_linear_2029_9286'
        x1='48.8552'
        y1='8.90659'
        x2='83.0606'
        y2='-5.64177'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B456' />
        <stop offset='1' stopColor='#C17E1E' />
      </linearGradient>
      <clipPath id='clip0_2029_9286'>
        <rect width='178' height='199' fill='white' transform='translate(93 7)' />
      </clipPath>
      <clipPath id='clip1_2029_9286'>
        <rect width='75' height='152' fill='white' transform='translate(0 49)' />
      </clipPath>
      <clipPath id='clip2_2029_9286'>
        <rect width='35.94' height='35.82' fill='white' transform='translate(48 11.7229) rotate(-19.037)' />
      </clipPath>
      <clipPath id='clip3_2029_9286'>
        <rect width='24' height='24' fill='white' transform='translate(59 11)' />
      </clipPath>
      <clipPath id='clip4_2029_9286'>
        <rect width='27.74' height='20.97' fill='white' transform='translate(102 149)' />
      </clipPath>
    </defs>
  </svg>
);

export default GenericIllustration;
