import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  updateDevicesMode,
  updateElectricityCategory,
} from '@/containers/Consumption/Electricity/state/ElectricitySlice';
import { updateGasCategory } from '@/containers/Consumption/Gas/state/GasSlice';
import { AddressEntity } from '@/store/Common/Profile/Profile.interfaces';
import { selectCurrentAddress, selectProfileData } from '@/store/Common/Profile/ProfileSelectors';
import { updateCurrentAddress } from '@/store/Common/Profile/ProfileSlice';
import { Variant } from '@/ui-toolkit/common/types';
import IconErrorCircle from '@icons/IconErrorCircle';
import { PRICE } from '@utils/constants/category-types';
import { PATHS } from '@utils/constants/paths';

export interface AddressListItem {
  id: string;
  text: string;
  selected: boolean;
  icon?: ReactElement;
  variant: Variant;
}

const useAddressDropdown = ({ ulRef }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const profile = useSelector(selectProfileData);
  const currentAddress: AddressEntity | null = useSelector(selectCurrentAddress);

  const addressesList: AddressListItem[] | null =
    profile?.addresses?.map(({ id, name, containsExpiredSupplies }: AddressEntity) => {
      const item: AddressListItem = {
        id,
        text: name,
        icon: containsExpiredSupplies ? <IconErrorCircle /> : undefined,
        variant: containsExpiredSupplies ? 'danger' : 'primary',
        selected: id === currentAddress?.id,
      };

      return item;
    }) || null;

  useEffect(() => {
    if (ulRef?.current) ulRef.current.style.visibility = 'hidden';

    const timeout = setTimeout(() => {
      if (ulRef?.current) ulRef.current.style.visibility = 'visible';
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const onSelect = (nextAddressId: string) => {
    const addressDetected = profile?.addresses?.find((address: AddressEntity) => address.id === nextAddressId);

    if (addressDetected) {
      dispatch(updateCurrentAddress(addressDetected));
      dispatch(updateGasCategory({ category: PRICE }));
      dispatch(updateElectricityCategory({ category: PRICE }));
      dispatch(updateDevicesMode({ devicesMode: false }));
      const nextPath = pathname.includes('consumption') ? PATHS.GLOBAL_CONSUMPTION : PATHS.DASHBOARD;
      navigate(nextPath);
    }
  };

  const openAddNewAddressModal = () => {
    navigate(PATHS.DASHBOARD + '/?onboarding=1');
  };

  return {
    addressesList,
    currentAddress,
    onSelect,
    openAddNewAddressModal,
  };
};

export default useAddressDropdown;
