import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertEntity } from './AlertsInterfaces';

export interface AlertsState {
  alerts: {
    common: AlertEntity[];
    marketplace: AlertEntity[];
  };
  error: any;
  isLoading: boolean;
}

const initialState: AlertsState = {
  alerts: {
    marketplace: [],
    common: [],
  },
  error: {},
  isLoading: true,
};

const updateAlertsDataHandler = (state: AlertsState, action: PayloadAction<AlertEntity[]>) => {
  const marketplace: AlertEntity[] = [];
  const common: AlertEntity[] = [];

  action.payload.forEach(alert => {
    if (alert.type === 'new-tip') {
      marketplace.push(alert);
    } else {
      common.push(alert);
    }
  });

  state.alerts.marketplace = marketplace.slice(0, 3);
  state.alerts.common = common;
};

const updateMarketplaceAlertsHandler = (state: AlertsState, action: PayloadAction<AlertEntity[]>) => {
  state.alerts.marketplace = action.payload;
};

const updateAlertsIsLoadingHandler = (state: AlertsState, action: PayloadAction<boolean>) => {
  state.isLoading = action.payload;
};

const deleteAlertByIdHandler = (
  state: AlertsState,
  action: PayloadAction<{ group: 'common' | 'marketplace'; alertId: string }>,
) => {
  const group = action.payload.group;

  const items = [...state.alerts[group]];
  const indexToDelete = items.findIndex(item => item.id === action.payload.alertId);
  items.splice(indexToDelete, 1);

  state.alerts[group] = items;
};

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    deleteAlertById: deleteAlertByIdHandler,
    updateAlertsData: updateAlertsDataHandler,
    updateMarketplaceAlerts: updateMarketplaceAlertsHandler,
    updateAlertsIsLoading: updateAlertsIsLoadingHandler,
    clearAlerts: () => initialState,
  },
});

export const { deleteAlertById, updateAlertsData, updateMarketplaceAlerts, updateAlertsIsLoading, clearAlerts } =
  alertsSlice.actions;

export default alertsSlice.reducer;
