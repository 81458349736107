import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ENDPOINTS } from '@utils/constants/endpoints';
import { ResponseGenerator } from '@/shared/interfaces/ResponseGenerator';
import { runtimeConfig } from '@/runtime-config';
import { STATUS } from '@utils/constants/status';
import { updateEjpTempoData, updateEjpTempoStatus } from './EjpTempoSlice';

const { APP_API_URL } = runtimeConfig;

export const EjpTempoActions = {
  GET_EJP_TEMPO: 'GET_EJP_TEMPO',
};

export function* getEjpTempo({ contract_type }: any) {
  try {
    yield put(updateEjpTempoStatus(STATUS.LOADING));

    const params = { contract_type };
    const { data }: ResponseGenerator = yield call(axios.get, `${APP_API_URL}${ENDPOINTS.EJP_WIDGET}`, { params });

    yield put(updateEjpTempoData(data.data));
    yield put(updateEjpTempoStatus(STATUS.SUCCESS));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(updateEjpTempoStatus(STATUS.PENDING));
  }
}

export default function* alertsSagas() {
  yield takeLatest(EjpTempoActions.GET_EJP_TEMPO, getEjpTempo);
}
