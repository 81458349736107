import { HOUR, WEEK, MONTH, YEAR } from '@utils/constants/consumption-periods';
import { ChartCategory } from '@/ui-toolkit/common/types';
import { ConsumptionNodeValues } from '../Consumption.interfaces';
import { Period } from '../Consumption.interface';

export const getYaxis = (maxConsumptionOfThePeriod: number, category: ChartCategory) => {
  const fixedValue = maxConsumptionOfThePeriod > 2 ? 0 : 2;
  const divisionByFour = maxConsumptionOfThePeriod / 4;
  const suphix = category === 'price' ? '€' : 'kWh';

  const yaxis = Array.from(Array(4).keys())
    .map(index => {
      const multipliedByIndex = divisionByFour * (index + 1);
      const toFixed = parseFloat(multipliedByIndex.toFixed(fixedValue)).toString().replace(/\.00$/, '');
      const xaxisLabel = (toFixed + ` ${suphix}`).replace('.', ',');
      return xaxisLabel;
    })
    .reverse();

  yaxis.push(`0 ${suphix}`);

  return yaxis;
};

export const getXAxisByPeriod = ({ period, values }: { period: Period; values: ConsumptionNodeValues }) => {
  const handlers = {
    [HOUR]: (values: ConsumptionNodeValues) => {
      return Object.keys(values)
        .map(key => key.split('-')[2])
        .map(item => (parseInt(item) < 10 ? item.substring(1) : item))
        .map(item => item.split('T')[1] + 'h');
    },

    [MONTH]: (values: ConsumptionNodeValues) => {
      return Object.keys(values)
        .map(key => key.split('-')[2])
        .map(item => (parseInt(item) < 10 ? item.substring(1) : item));
    },

    [WEEK]: () => ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    [YEAR]: () => ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
  };

  return handlers[period](values);
};
