import { createSelector } from '@reduxjs/toolkit';

import { ELECTRICITY } from '@utils/constants/supply-types';
import { RootState } from '@/store/Reducer';

export const selectProfile = (state: RootState) => state?.profile;

export const selectProfileData = createSelector(selectProfile, profile => profile?.data);

export const selectProfileLastName = createSelector(selectProfile, profile => profile?.data?.lastName);

export const selectProfileFirstName = createSelector(selectProfile, profile => profile?.data?.firstName);

export const selectProfileIsLoading = createSelector(selectProfile, profile => profile?.isLoading);

export const selectProfileError = createSelector(selectProfile, profile => profile?.error);

export const selectAddressesProfileData = createSelector(selectProfileData, profile => {
  if (!profile?.addresses) return [];
  return [...profile.addresses].sort((a, b) => (a.name > b.name ? 1 : -1));
});

export const selectCurrentAddress = createSelector(selectProfile, profile => {
  return profile?.currentAddress;
});

export const selectUserHourlyConsumptionWasConsented = createSelector(selectCurrentAddress, currentAddress => {
  const electricitySupply = currentAddress?.supplies?.find((supply: any) => supply.type === ELECTRICITY);
  return electricitySupply?.hourly_consumption_is_consented;
});
