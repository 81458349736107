import { DynamicPageApiResponse, DynamicPageProps, UspItem } from './DynamicPage.interface';

const createImageModel = (imagePath: string) => {
  if (!imagePath) return null;

  return [
    {
      sm: {
        src: imagePath,
        alt: 'Selectra. Customer Space.',
      },
      md: {
        src: imagePath,
        alt: 'Selectra. Customer Space.',
      },
      lg: {
        src: imagePath,
        alt: 'Selectra. Customer Space.',
      },
    },
  ];
};

const parseContent = (content: string) => {
  return content;
};

const parseUsp = (uspArray: { caption: string; cta: string; text: string; title: string }[]): UspItem[] => {
  return uspArray.map(usp => ({
    images: [],
    link: usp?.caption?.length ? { label: usp.caption, href: usp.cta } : null,
    text: usp.text,
    title: usp.title,
  }));
};

export const apiResponseAdapter = ({ data }: DynamicPageApiResponse): DynamicPageProps => {
  const { title, content, feature_image, seo_description, seo_title, usp, show_register_form, caption_form } = data;

  return {
    caption_form,
    page_title: title,
    seo_description,
    seo_title,
    show_register_form,
    articles: [
      {
        title: 'THIS IS AN ARTICLE TITLE',
        content: parseContent(content),
        images: createImageModel(feature_image),
      },
    ],
    usp: parseUsp(usp),
  };
};
