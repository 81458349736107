import axios from 'axios';
import { call, takeLatest, put } from 'redux-saga/effects';
import { updatePceChecked, updatePceFormIsLoading } from './PceFormSlice';
import { updatePceFormError, clearPceFormError } from './PceFormSlice';
import { clearPceForm } from './PceFormSlice';
import { ENDPOINTS } from '@utils/constants/endpoints';
import { runtimeConfig } from '@/runtime-config';

const { VALIDATE_PCE } = ENDPOINTS;

export const pceFormActions = {
  CHECK_PCE: 'CHECK_PCE',
};

export function* checkPce({ pce }: ReturnType<any>) {
  try {
    yield put(clearPceFormError());
    yield put(updatePceFormIsLoading(true));

    const endpoint = runtimeConfig.APP_API_URL + VALIDATE_PCE;
    yield call(axios.get, endpoint, { params: { pce } });

    yield put(updatePceChecked(true));
  } catch (error: any) {
    yield put(clearPceForm());

    const status = error?.response?.status;

    if (status === 409) {
      yield put(updatePceFormError('pceInUse'));
      return;
    }

    if (status === 412) {
      yield put(updatePceFormError('pceInvalid'));
      return;
    }

    yield put(updatePceFormError('serverError'));
  } finally {
    yield put(updatePceFormIsLoading(false));
  }
}

export default function* pdlFormsSagas() {
  yield takeLatest(pceFormActions.CHECK_PCE, checkPce);
}
