import { useDispatch, useSelector } from 'react-redux';

import MenuItem from '@/containers/Common/Menu/MenuItem';
import useAnalytics from '@hooks/useAnalytics';
import IconDownload from '@icons/IconDownload';
import { selectInstallAppState } from './InstallAppCtaSelectors';
import { updateDeferredPrompt } from './InstallAppCtaSlice';

const InstallAppCta = () => {
  const { track } = useAnalytics();
  const dispatch = useDispatch();
  const { appIsInstalled, supportsPWA, deferredPrompt } = useSelector(selectInstallAppState);

  if (appIsInstalled || !supportsPWA || !deferredPrompt) {
    return null;
  }

  const handlePrompInstallApp = async () => {
    deferredPrompt.prompt();

    const { outcome } = await deferredPrompt.userChoice;

    track('pwaUserChoice', { outcome });

    dispatch(updateDeferredPrompt(null));
  };

  return (
    <MenuItem
      id={'button-install-app'}
      icon={<IconDownload />}
      label={'Installer l’app'}
      onClick={handlePrompInstallApp}
    />
  );
};

export default InstallAppCta;
