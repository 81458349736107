import { STATUS } from './constants/status';

const {
  AUTH_ERROR,
  DATA_ERROR,
  NO_CONTENT,
  PDL_NO_LINKY_ERROR,
  PDL_SERVICE_DOWN,
  SERVER_ERROR,
  SUCCESS,
  AUTH_USER_NOT_VERIFY_EMAIL,
  AUTH_PASS_RESET_NEEDED,
} = STATUS;

export const getStatusByCode = code => {
  switch (code) {
    case 200:
    case 201:
    case 202:
      return SUCCESS;

    case 204:
      return NO_CONTENT;

    case 400:
      return DATA_ERROR;

    case 401:
    case 402:
      return AUTH_ERROR;

    case 403:
      return AUTH_USER_NOT_VERIFY_EMAIL;

    case 406:
      return AUTH_PASS_RESET_NEEDED;

    case 422:
      return PDL_NO_LINKY_ERROR;

    case 424:
      return PDL_SERVICE_DOWN;

    default:
      return SERVER_ERROR;
  }
};
