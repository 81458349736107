export const ENDPOINTS = {
  ADDRESS_API: '/address',
  ALERTS: '/alerts',
  CHECK_PDL_CUSTOMER_CONSENTS: '/check-pdl/customer-consents',
  CONSENT_FORM_API: '/imports/customer-consents/customer-space/form',
  CONSENT: '/user/consent',
  CONTRACTS: '/user/contracts',
  DEVICES_CONSUMPTION: '/devices-consumption',
  DYNAMIC_PAGES: '/posts',
  EJP_WIDGET: '/address/electricity/day-colors',
  ELECTRICITY_CONSUMPTION: '/address/electricity',
  EMAIL_VERIFICATION: '/auth/email/verification-notification',
  EXTERNAL_REGISTER: '/auth/external-register',
  FAVOURITES: '/tips/my-saved-for-later',
  GAS_CONSUMPTION: '/address/gas',
  GET_PDL_BY_ADDRESS_CUSTOMER_CONSENTS: '/get-pdl-by-address/customer-consents',
  HINT: 'user/hint',
  HINTS: 'user/hints',
  HOME_TIPS: '/deals/latest',
  LOGIN_TRACKING: '/auth/login-tracking',
  LOGIN_WITH_APPLE: '/auth/apple',
  LOGIN_WITH_GOOGLE: '/auth/google',
  LOGIN_WITH_HASH: '/auth/login-with-hash',
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
  PASS_CHANGE: '/user/change-password',
  PASS_FORGOT: '/auth/forgot-password',
  PASS_RESET: '/auth/reset-password',
  PROPOSAL: '/advertisements',
  REGISTER: '/auth/register',
  REQUEST_EMAIL_CONTACT: '/address/request-email-contact',
  REVOKE_PDL: '/user/revoke-pdl',
  SINGLE_TIP: '/deal',
  TIPS: '/deals',
  TOGGLE_TIP_FAVOURITES: '/tip',
  USER_PROFILE: '/user/profile',
  USER_UPDATE_PROFILE: '/user/update-profile',
  VALIDATE_PCE: '/address/validate-pce',
};
