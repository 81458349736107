import { runtimeConfig } from '@/runtime-config';
import { ResponseGenerator } from '@/shared/interfaces/ResponseGenerator';
import { ENDPOINTS } from '@utils/constants/endpoints';
import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PdlByAddressRequestData } from './PdlForm.interface';
import {
  clearPdlForm,
  clearPdlFormError,
  updatePdlChecked,
  updatePdlFormData,
  updatePdlFormError,
  updatePdlFormIsLinky,
  updatePdlFormIsLoading,
} from './PdlFormSlice';

const { CHECK_PDL_CUSTOMER_CONSENTS, GET_PDL_BY_ADDRESS_CUSTOMER_CONSENTS } = ENDPOINTS;

export const pdlFormActions = {
  CHECK_PDL: 'CHECK_PDL',
  GET_PDL_BY_ADDRESS: 'GET_PDL_BY_ADDRESS',
  SEND_VALIDATION_SMS: 'SEND_VALIDATION_SMS',
  CONFIRM_VALIDATION_CODE: 'CONFIRM_VALIDATION_CODE',
};

export function* checkPdl({ pdl }: ReturnType<any>) {
  try {
    yield put(updatePdlFormIsLinky('unknown'));
    yield put(clearPdlFormError());
    yield put(updatePdlFormIsLoading(true));

    const response: ResponseGenerator = yield call(axios.get, CHECK_PDL_CUSTOMER_CONSENTS, { params: { pdl } });

    yield put(updatePdlChecked(response.data.data));
  } catch (error: any) {
    yield put(clearPdlForm());

    const status = error?.response?.status;

    if (status === 408) {
      yield put(updatePdlFormError('browseryTimeout' as any));
      return;
    }

    if (status === 400) {
      yield put(updatePdlFormError('invalidData' as any));
      return;
    }

    yield put(updatePdlFormError('pdlNotExists' as any));
  } finally {
    yield put(updatePdlFormIsLoading(false));
  }
}

export function* getPdlByAddress(data: ReturnType<any>) {
  const { address, citycode, lastName, postcode } = data as PdlByAddressRequestData;
  try {
    yield put(clearPdlForm());
    yield put(clearPdlFormError());
    yield put(updatePdlFormIsLoading(true));

    const endpoint = runtimeConfig.APP_API_URL + GET_PDL_BY_ADDRESS_CUSTOMER_CONSENTS;
    const params = { address, city_code: citycode, postal_code: postcode, surname: lastName };
    const response: ResponseGenerator = yield call(axios.get, endpoint, { params });

    if (response?.data?.data?.length === 0) {
      yield put(updatePdlFormError('notPdlFound' as any));
      return;
    }

    yield put(updatePdlFormData(response.data.data[0]));
  } catch (error: any) {
    yield put(clearPdlForm());

    const status = error?.response?.status;

    if (status === 404) {
      yield put(updatePdlFormError('notPdlFound' as any));
      return;
    }

    yield put(updatePdlFormError('serverError' as any));
  } finally {
    yield put(updatePdlFormIsLoading(false));
  }
}

export function* sendValidationSms({ callback, phone_number }: any) {
  try {
    const endpoint = runtimeConfig.APP_API_URL + '/user/generate-otp';

    yield call(axios.post, endpoint, { phone_number });

    callback('send-validation-sms-success');
  } catch (error: any) {
    console.error(error);

    callback('send-validation-sms-error');
  }
}

export function* confirmValidationCode({ callback, otp }: any) {
  try {
    const endpoint = runtimeConfig.APP_API_URL + '/user/check-otp';
    // const endpoint = 'http://localhost:8085/api/user/check-otp';

    yield call(axios.post, endpoint, { otp });

    callback('confirm-validation-code-success');
  } catch (error: any) {
    console.error(error);

    callback('confirm-validation-code-error');
  }
}

export default function* pdlFormsSagas() {
  yield takeLatest(pdlFormActions.CHECK_PDL, checkPdl);
  yield takeLatest(pdlFormActions.GET_PDL_BY_ADDRESS, getPdlByAddress);
  yield takeLatest(pdlFormActions.SEND_VALIDATION_SMS, sendValidationSms);
  yield takeLatest(pdlFormActions.CONFIRM_VALIDATION_CODE, confirmValidationCode);
}
