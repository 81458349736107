import { ChartCategory } from '@/ui-toolkit/common/types';
import { CONSUMPTION, PRICE } from '@utils/constants/category-types';
import { fromFloatToCommaFixedTwo, getTitle, units } from './generate-tooltip';
import { KeyValueString } from '../Consumption.utils';
import { Period } from '../../Consumption.interface';
import { TariffNode } from '../../Consumption.interfaces';

const getRows = ({ category, node }: { category: ChartCategory; node: TariffNode }): KeyValueString => {
  const unit = ' ' + units[category];

  if (category === PRICE) {
    return {
      base: fromFloatToCommaFixedTwo(node.base) + unit,
      subscription: fromFloatToCommaFixedTwo(node.subscription) + unit,
    };
  }

  if (category === CONSUMPTION) {
    return {
      base: fromFloatToCommaFixedTwo(node.base) + unit,
    };
  }

  return {};
};

const getTotal = ({ category, node }: { category: ChartCategory; node: TariffNode }): string => {
  let sum = 0;
  const unit = ' ' + units[category];

  if (category === PRICE) {
    sum = node.base + node.subscription;
  }

  if (category === CONSUMPTION) {
    sum = node.base;
  }

  return fromFloatToCommaFixedTwo(sum) + unit;
};

export const baseTooltip = ({
  category,
  date,
  node,
  period,
}: {
  category: ChartCategory;
  date: string;
  node: TariffNode;
  period: Period;
}) => {
  return {
    rows: getRows({ category, node }),
    total: getTotal({ category, node }),
    title: getTitle(date, period),
  };
};
