import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ENDPOINTS } from '@utils/constants/endpoints';
import {
  resetElectricity,
  updateElectricity,
  updateElectricityError,
  updateElectricityIsLoading,
} from './ElectricitySlice';
import { updateAddressComparatorProps } from '@/store/Common/Profile/ProfileSlice';
import { updateTalon } from '@/store/Common/Profile/ProfileSlice';
import {
  putAddressSupplyPricesSagaAction,
  GetConsumptionEndpointParams,
  getElectricityConsumptionAction,
  getTalonAction,
} from '@/containers/Consumption/Consumption.interfaces';
import { ResponseGenerator } from '@/shared/interfaces/ResponseGenerator';

export const electricityActions = {
  GET_ELECTRICITY_CONSUMPTION: 'GET_ELECTRICITY_CONSUMPTION',
  GET_TALON: 'GET_TALON',
  GET_COMPARATOR_PROPS: 'GET_COMPARATOR_PROPS',
  PUT_ADDRESS_SUPPLY_PRICES: 'PUT_ADDRESS_SUPPLY_PRICES',
};

export function* putAddressSupplyPricesSaga({
  addressId,
  supplyId,
  contract,
  values,
}: putAddressSupplyPricesSagaAction) {
  try {
    const endpoint = `${ENDPOINTS.ADDRESS_API}/${supplyId}/prices`;
    yield call(axios.put, endpoint, { ...values, contract_type: contract });

    const type = electricityActions.GET_ELECTRICITY_CONSUMPTION;
    yield call(getElectricityConsumption, { type, addressId, period: 'week' });
  } catch (error) {
    console.error(error);
  }
}

export function* getElectricityConsumption({ addressId, period }: getElectricityConsumptionAction) {
  // console.log(`%c Call ELECTRICITY ${period}`, 'background: #222; color: #bada55');

  try {
    yield put(resetElectricity());

    const params: GetConsumptionEndpointParams = { period };
    const endpoint = `${ENDPOINTS.ELECTRICITY_CONSUMPTION}/${addressId}/consumption`;
    const response: ResponseGenerator = yield call(axios.get, endpoint, { params });

    if (response.status === 204) {
      yield put(updateElectricityError({ error: 204 }));
    } else {
      yield put(updateElectricity({ data: response?.data?.data }));
    }
  } catch (error: any) {
    console.error(error);

    const parsedError = !error?.response ? { response: { status: 0 } } : error;

    yield put(updateElectricityError({ error: parsedError }));
  } finally {
    yield put(updateElectricityIsLoading({ isLoading: false }));
  }
}

export function* getComparatorProps({ addressId, postal_code, errorCallback }: any) {
  try {
    const endpoint = `${ENDPOINTS.ADDRESS_API}/${addressId}/comparator-params`;
    const response: ResponseGenerator = yield call(axios.get, endpoint, { params: { postal_code } });

    yield put(
      updateAddressComparatorProps({
        addressId,
        comparatorProps: response?.data?.data,
      }),
    );
  } catch (error: any) {
    console.error(error);
    errorCallback();
  }
}

export function* getTalon({ addressId }: getTalonAction) {
  try {
    const endpoint = `${ENDPOINTS.ADDRESS_API}/electricity/${addressId}/talon`;
    const response: ResponseGenerator = yield call(axios.get, endpoint);

    let talon = response?.data?.data;

    if (response?.status === 204) {
      talon = null;
    }

    yield put(updateTalon({ addressId, talon }));
  } catch (error: any) {
    console.error(error);
    yield put(updateTalon({ addressId, talon: null }));
  }
}

export default function* consumptionSagas() {
  yield takeLatest(electricityActions.GET_ELECTRICITY_CONSUMPTION, getElectricityConsumption);
  yield takeLatest(electricityActions.GET_COMPARATOR_PROPS, getComparatorProps);
  yield takeLatest(electricityActions.GET_TALON, getTalon);
  yield takeLatest(electricityActions.PUT_ADDRESS_SUPPLY_PRICES, putAddressSupplyPricesSaga);
}
