import { combineReducers } from 'redux';

import { authActions } from '@/containers/Auth/LoginModal/LoginSagas';
import addressForm from '@/store/Common/Address/AddressSlice';
import alerts from '@/containers/Alerts/AlertsSlice';
import auth from '@auth/AuthSlice';
import devices from '@/containers/Consumption/Devices/state/DevicesSlice';
import drawer from '@/containers/Common/Drawer/DrawerSlice';
import dynamicPage from '@/containers/DynamicPage/DynamicPageSlice';
import ejpTempo from '@/containers/Dashboard/components/EjpTempoWidget/EjpTempoSlice';
import electricity from '../containers/Consumption/Electricity/state/ElectricitySlice';
import favourites from '@/store/Common/Favourites/FavouritesSlice';
import gas from '@/containers/Consumption/Gas/state/GasSlice';
import hints from './Common/Hints/HintsSlice';
import home from '@/containers/Dashboard/state/DashboardSlice';
import householdComparison from '@/containers/Consumption/components/HouseholdComparison/HouseholdComparisonSlice';
import installAppCta from '@/containers/Common/MenuDesktop/components/InstallAppCta/InstallAppCtaSlice';
import modal from '@modal/ModalSlice';
import pceForm from '@modal/components/AddNewAddressModal/components/PceForm/PceFormSlice';
import pdlForm from '@modal/components/AddNewAddressModal/components/PdlForm/PdlFormSlice';
import powerAnalysis from '@/store/Common/PowerAnalysis/PowerAnalysisSlice';
import profile from './Common/Profile/ProfileSlice';
import proposals from '@/store/Common/Proposal/ProposalSlice';
import tariff from '@modal/components/TariffModal/TariffSlice';
import tips from '@tips/TipsSlice';
import tooltip from '@/containers/Common/Tooltip/TooltipSlice';
import windowResizeTool from './Common/WindowResizeTool/WindowResizeToolSlice';

const appReducer = combineReducers({
  addressForm,
  alerts,
  auth,
  drawer,
  dynamicPage,
  ejpTempo,
  favourites,
  hints,
  home,
  tips,
  householdComparison,
  installAppCta,
  modal,
  pceForm,
  pdlForm,
  powerAnalysis,
  profile,
  proposals,
  tariff,
  tooltip,
  windowResizeTool,
  //
  electricity,
  gas,
  devices,
});

export type RootState = ReturnType<typeof appReducer>;

export const rootReducer = (state: ReturnType<typeof appReducer>, action: any) => {
  // console.log(action.type); Uncomment to track redux actions
  if (action.type === authActions.LOGOUT) {
    return appReducer(undefined, { type: 'modal' });
  }

  return appReducer(state, action);
};
