const IconArrowForward = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_28_729)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25 -1H24V0H25V-1ZM11.2245 18.6C11.2332 18.8833 11.3292 19.1167 11.5125 19.3C11.6958 19.4833 11.9292 19.575 12.2125 19.575C12.4958 19.575 12.7292 19.4833 12.9125 19.3L19.5125 12.7C19.6125 12.6 19.6835 12.4917 19.7255 12.375C19.7668 12.2583 19.7875 12.1333 19.7875 12C19.7875 11.8667 19.7668 11.7373 19.7255 11.612C19.6835 11.4873 19.6125 11.3833 19.5125 11.3L12.9125 4.7C12.7292 4.51667 12.4958 4.425 12.2125 4.425C11.9292 4.425 11.6958 4.51667 11.5125 4.7C11.3292 4.88334 11.2332 5.11667 11.2245 5.4C11.2165 5.68334 11.3042 5.91667 11.4875 6.1L16.3875 11H5.21249C4.92916 11 4.69149 11.0957 4.49949 11.287C4.30816 11.479 4.21249 11.7167 4.21249 12C4.21249 12.2833 4.30816 12.5207 4.49949 12.712C4.69149 12.904 4.92916 13 5.21249 13H16.3875L11.4875 17.9C11.3042 18.0833 11.2165 18.3167 11.2245 18.6Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_28_729'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconArrowForward;
