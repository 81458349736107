import 'moment/dist/locale/fr';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { useEffect } from 'react';
import createStore from '@/store/Store';
import moment from 'moment';

import useAnalytics from './shared/hooks/useAnalytics';
import AppRoutes from './routes/AppRoutes';
import './App.scss';

moment.locale('fr');

const App = () => {
  const analytics = useAnalytics();

  const { store, persistor } = createStore();

  useEffect(() => {
    analytics.init();
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading='' persistor={persistor}>
        <AppRoutes />
      </PersistGate>
    </Provider>
  );
};

export default App;
