const IconPerson = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1920_3007)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.75 -1H23.75V0H24.75V-1ZM9.175 10.825C9.95833 11.6083 10.9 12 12 12C13.1 12 14.0417 11.6083 14.825 10.825C15.6083 10.0417 16 9.1 16 8C16 6.9 15.6083 5.95833 14.825 5.175C14.0417 4.39167 13.1 4 12 4C10.9 4 9.95833 4.39167 9.175 5.175C8.39167 5.95833 8 6.9 8 8C8 9.1 8.39167 10.0417 9.175 10.825ZM4.5875 19.4125C4.97917 19.8042 5.45 20 6 20H18C18.55 20 19.0208 19.8042 19.4125 19.4125C19.8042 19.0208 20 18.55 20 18V17.2C20 16.6333 19.8542 16.1125 19.5625 15.6375C19.2708 15.1625 18.8833 14.8 18.4 14.55C17.3667 14.0333 16.3167 13.6458 15.25 13.3875C14.1833 13.1292 13.1 13 12 13C10.9 13 9.81667 13.1292 8.75 13.3875C7.68333 13.6458 6.63333 14.0333 5.6 14.55C5.11667 14.8 4.72917 15.1625 4.4375 15.6375C4.14583 16.1125 4 16.6333 4 17.2V18C4 18.55 4.19583 19.0208 4.5875 19.4125ZM18 17.2V18H6V17.2C6 17.0167 6.04583 16.85 6.1375 16.7C6.22917 16.55 6.35 16.4333 6.5 16.35C7.4 15.9 8.30833 15.5625 9.225 15.3375C10.1417 15.1125 11.0667 15 12 15C12.9333 15 13.8583 15.1125 14.775 15.3375C15.6917 15.5625 16.6 15.9 17.5 16.35C17.65 16.4333 17.7708 16.55 17.8625 16.7C17.9542 16.85 18 17.0167 18 17.2ZM13.4125 9.4125C13.0208 9.80417 12.55 10 12 10C11.45 10 10.9792 9.80417 10.5875 9.4125C10.1958 9.02083 10 8.55 10 8C10 7.45 10.1958 6.97917 10.5875 6.5875C10.9792 6.19583 11.45 6 12 6C12.55 6 13.0208 6.19583 13.4125 6.5875C13.8042 6.97917 14 7.45 14 8C14 8.55 13.8042 9.02083 13.4125 9.4125Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1920_3007'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconPerson;
