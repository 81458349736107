import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/store/Reducer';

export const selectAuth = (state: RootState) => state.auth;

export const selectAuthToken = createSelector(selectAuth, auth => auth.token || localStorage?.getItem('selectra-auth'));

export const selectAuthParams = createSelector(selectAuth, auth => auth.params || {});

export const selectAuthIsLoading = createSelector(selectAuth, auth => auth.isLoading);

export const selectAuthStatus = createSelector(selectAuth, auth => auth.status || 'no-status');

export const selectAuthProvider = createSelector(selectAuth, auth => auth.provider);
