import { BASE, EJP, HPHC, TEMPO } from '@utils/constants/contract-types';
import { ChartCategory, SupplyType, ContractType } from '@/ui-toolkit/common/types';
import { ConsumptionNode, TariffNode } from '../Consumption.interfaces';
import { fromFloatToComma } from '@utils/converters';
import { GAS } from '@utils/constants/supply-types';

export interface KeyValueNumber {
  [key: string]: number;
}

export interface KeyValueString {
  [key: string]: string;
}

export const clone = (obj: object) => JSON.parse(JSON.stringify(obj));

export const perc = (value: number, max: number) => Math.round((value * 100) / max);

export const sumValues = (obj: object) => {
  let count = 0;

  Object.values(obj).forEach(value => {
    if (typeof value === 'number') count += value;
  });

  return count;
};

export const calculateMaxConsumptionOfThePeriod = (
  barsProvider: {
    consumption: TariffNode;
    price: TariffNode;
    date: string;
  }[],
  category: ChartCategory,
  contract: ContractType,
  supplyType: SupplyType,
): number => {
  if (!barsProvider) return 0;

  const _category = category as 'consumption' | 'price';

  const sum = barsProvider.map((bar: any) => {
    const node = bar[_category];
    const { base, subscription, peak, off_peak } = node;
    const _subscription = subscription || 0;

    if (supplyType === GAS) {
      return base + _subscription;
    }

    if (contract === EJP) {
      return node.ejp.red + node.ejp.blue + _subscription;
    }

    if (contract === TEMPO) {
      const sumTempo = sumValues(node.tempo);
      return sumTempo === 0 ? base + _subscription : sumTempo + _subscription;
    }

    if (contract === HPHC && peak === 0 && off_peak === 0) {
      return base + subscription;
    }

    if (contract === BASE) {
      return base + _subscription;
    }

    return peak + off_peak + _subscription;
  });

  const rawMax = Math.max(...sum);

  const minorThanFour = [0.2, 0.25, 0.5, 1, 2, 4].find(limit => rawMax < limit);

  const result = minorThanFour ? minorThanFour : Math.ceil(rawMax / 4) * 4;

  return result;
};

export const calculateTaxes = ({ total_price }: { total_price: TariffNode }) => {
  const total = total_price.base + total_price.subscription;
  let taxes = -1;

  if (total <= 36) {
    taxes = total_price.base * 0.2 + total_price.subscription * 0.55;
  } else {
    taxes = total_price.base * 0.2 + total_price.subscription * 0.2;
  }

  taxes = fromFloatToComma(taxes.toFixed(2));

  return `${taxes} € de TVA`;
};

export interface calculateTotalConsumptionProps {
  contract: ContractType;
  node: ConsumptionNode;
  supplyType: SupplyType;
}

export interface calculateTotalConsumptionReturn {
  price: string;
  consumption: string;
}

export const calculateTotalConsumption = (props: calculateTotalConsumptionProps): calculateTotalConsumptionReturn => {
  const { contract, node, supplyType } = props;
  const { total_consumption, total_price } = node;

  let price = 0;
  let consumption = 0;

  if (contract === BASE || supplyType === GAS) {
    price = total_price.base + total_price.subscription;
    consumption = total_consumption.base;
  }

  if (contract === HPHC) {
    const sumPrice = total_price.peak + total_price.off_peak;
    const sumCons = total_consumption.peak + total_consumption.off_peak;

    if (sumPrice === 0 || sumCons === 0) {
      // Return BASE
      price = total_price.base + total_price.subscription;
      consumption = total_consumption.base;
    } else {
      // Return total
      price = sumPrice + total_price.base + total_price.subscription;
      consumption = sumCons + total_consumption.base;
    }
  }

  if (contract === EJP && total_price?.ejp && total_consumption?.ejp) {
    const sumPrice = sumValues(total_price.ejp);
    const sumCons = sumValues(total_consumption.ejp);
    const returnBase = sumPrice === 0 || sumCons === 0;

    if (returnBase) {
      // Return BASE
      price = total_price.base + total_price.subscription;
      consumption = total_consumption.base;
    } else {
      // Return total
      price = sumPrice + total_price.subscription;
      consumption = sumCons;
    }
  }

  if (contract === TEMPO && total_price?.tempo && total_consumption?.tempo) {
    const sumPrice = sumValues(total_price.tempo);
    const sumCons = sumValues(total_consumption.tempo);
    const returnBase = sumPrice === 0 || sumCons === 0;

    if (returnBase) {
      // Return BASE
      price = total_price.base + total_price.subscription;
      consumption = total_consumption.base;
    } else {
      // Return total
      price = total_price.base + total_price.subscription + sumPrice;
      consumption = total_consumption.base + sumCons;
    }
  }

  return {
    price: fromFloatToComma(price.toFixed(2)),
    consumption: fromFloatToComma(consumption.toFixed(2)),
  };
};
