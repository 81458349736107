import { KeyValueString } from '@/containers/Consumption/utils/Consumption.utils';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const defaultSource = 'source-not-avaible';

const allowedSourceParam = ['ejp', 'esp', 'opt', 'pdl', 'pus', 'sui', 'tal', 'tpo'];

const descriptions: KeyValueString = {
  default: 'Profitez gratuitement des nombreux services de notre plateforme et bien plus encore !',
  ejp: "Accédez à votre suivi de consommation d'énergie en quelques clics et soyez alerté des jours EJP.",
  esp: "Accédez à votre espace client en quelques clics, suivez votre consommation d'énergie et bénéficiez des conseils des experts de Selectra.",
  opt: 'Inscrivez-vous en quelques clics et découvrez quelle option (Base, HPHC, Tempo) vous avez souscrite auprès de votre fournisseur.',
  pdl: "Découvrez quel est votre numéro PDL en quelques clics puis suivez votre consommation d'énergie et bénéficiez de conseils pour réduire vos factures.",
  pus: 'Inscrivez-vous en quelques clics et découvrez quelle puissance vous avez souscrit auprès de votre fournisseur.',
  sui: "Accédez à votre suivi de consommation d'énergie en quelques clics et bénéficiez de conseils pour réduire vos factures.",
  tal: "Inscrivez-vous en quelques clics et découvrez après quelques jours d'analyse quel est votre talon de consommation.",
  tpo: "Accédez à votre suivi de consommation d'énergie en quelques clics et soyez alerté des jours Tempo.",
};

const useSourceParam = () => {
  const [searchParams] = useSearchParams();
  const source = searchParams.get('source');
  const [sourceParam, setSourceParam] = useState(defaultSource);
  const descId = sourceParam?.split('-')?.[1];
  const description = allowedSourceParam.includes(descId) ? descriptions[descId] : descriptions['default'];

  useEffect(() => {
    if (!localStorage?.getItem || !localStorage.setItem) {
      return;
    }

    if (source) {
      localStorage.setItem('selectra-source-param', source);
      setSourceParam(source);
    } else {
      const storedSourceParam = localStorage.getItem('selectra-source-param');
      const s = storedSourceParam ? storedSourceParam : defaultSource;
      setSourceParam(s);
    }
  }, [source]);

  const deleteSourceParam = () => {
    localStorage.removeItem('selectra-source-param');
  };

  return {
    description,
    source: sourceParam,
    deleteSourceParam,
  };
};

export default useSourceParam;
