import { AddressFeaturesHeatingType, Comparison } from '@/store/Common/Profile/Profile.interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface HouseholdComparison {
  features: {
    user: {
      area: number;
      heating: AddressFeaturesHeatingType;
      inhabitants: number;
      layout: 'apartment' | 'house';
    };
    others: {
      area: number;
      heating: AddressFeaturesHeatingType;
      inhabitants: number;
      layout: 'apartment' | 'house';
    };
  };
  comparison: Comparison;
  consumption: number;
  month: string;
  percentage: number;
  similar_consumption: number;
}

export interface HouseholdComparisonState {
  addressId: string | null;
  data: HouseholdComparison | null;
  errorCode: number | null;
}

const initialState: HouseholdComparisonState = {
  addressId: null,
  data: null,
  errorCode: null,
};

const updateHouseholdComparisonHandler = (
  state: HouseholdComparisonState,
  action: PayloadAction<{ addressId: string; householdComparison: HouseholdComparison }>,
) => {
  const { addressId, householdComparison } = action.payload;
  const { address_features, percentage, current_profile, consumption, similar_consumption, date_evaluated } =
    householdComparison as any;

  const ref = new Date(date_evaluated);
  const month = ref.toLocaleDateString('fr-FR', { month: 'long' });
  const comparison: Comparison = percentage < 0 ? 'lower' : 'higher';

  const data: HouseholdComparison = {
    features: {
      user: {
        area: current_profile.area,
        heating: current_profile.heating,
        inhabitants: current_profile.inhabitants,
        layout: current_profile.layout,
      },
      others: {
        area: address_features.area,
        heating: address_features.heating,
        inhabitants: address_features.inhabitants,
        layout: address_features.layout,
      },
    },
    comparison,
    month,
    percentage,
    consumption,
    similar_consumption,
  };

  state.data = data;
  state.addressId = addressId;
  state.errorCode = null;
};

const updateHouseholdComparisonErrorHandler = (
  state: HouseholdComparisonState,
  action: PayloadAction<{ addressId: string; errorCode: number }>,
) => {
  state.addressId = action.payload.addressId;
  state.data = null;
  state.errorCode = action.payload.errorCode;
};

export const householdComparisonSlice = createSlice({
  name: 'householdComparison',
  initialState,
  reducers: {
    clearProfile: () => initialState,
    updateHouseholdComparison: updateHouseholdComparisonHandler,
    updateHouseholdComparisonError: updateHouseholdComparisonErrorHandler,
  },
});

export const { clearProfile, updateHouseholdComparison, updateHouseholdComparisonError } =
  householdComparisonSlice.actions;

export default householdComparisonSlice.reducer;
