import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface HintsState {
  data: {
    accepted: string[];
  };
  error: any;
  isLoading: boolean;
}

const initialState: HintsState = {
  data: {
    accepted: [],
  },
  error: null,
  isLoading: true,
};

const updateHintsDataHandler = (state: HintsState, action: PayloadAction<{ accepted: string[] }>) => {
  state.data = action.payload;
};

const updateHintHandler = (state: HintsState, action: PayloadAction<{ id: string }>) => {
  const { id } = action.payload;

  state.data.accepted.push(id);
};

const updateHintsIsLoadingHandler = (state: HintsState, action: PayloadAction<boolean>) => {
  state.isLoading = action.payload;
};

const updateHintsErrorHandler = (state: HintsState, action: PayloadAction<any>) => {
  state.error = action.payload;
};

export const hintsSlice = createSlice({
  name: 'hints',
  initialState,
  reducers: {
    updateHintsData: updateHintsDataHandler,
    updateHint: updateHintHandler,
    updateHintsIsLoading: updateHintsIsLoadingHandler,
    updateHintsError: updateHintsErrorHandler,
    clearHints: () => initialState,
  },
});

export const { clearHints, updateHintsData, updateHint, updateHintsError, updateHintsIsLoading } = hintsSlice.actions;

export default hintsSlice.reducer;
