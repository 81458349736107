import { all, fork } from 'redux-saga/effects';

import addressFormSagas from '@/store/Common/Address/AddressSagas';
import alertsSagas from '@/containers/Alerts/AlertsSagas';
import consentSagas from './Common/Consent/ConsentSagas';
import devicesSagas from '@/containers/Consumption/Devices/state/DevicesSagas';
import dynamicPageSagas from '@/containers/DynamicPage/DynamicPageSagas';
import ejpTempoSagas from '@/containers/Dashboard/components/EjpTempoWidget/EjpTempoSagas';
import electricitySagas from '../containers/Consumption/Electricity/state/ElectricitySagas';
import favouritesSagas from '@/store/Common/Favourites/FavouritesSagas';
import gasSagas from '../containers/Consumption/Gas/state/GasSagas';
import hintsSagas from './Common/Hints/HintsSagas';
import homeSagas from '@/containers/Dashboard/state/DashboardSagas';
import householdComparisonSagas from '@/containers/Consumption/components/HouseholdComparison/HouseholdComparisonSagas';
import loginSagas from '@/containers/Auth/LoginModal/LoginSagas';
import passwordSagas from '@/containers/Auth/ForgotPasswordModal/ForgotPasswordSagas';
import pceFormSagas from '@modal/components/AddNewAddressModal/components/PceForm/PceFormSagas';
import pdlFormSagas from '@modal/components/AddNewAddressModal/components/PdlForm/PdlFormSagas';
import powerAnalysisSagas from '@/store/Common/PowerAnalysis/PowerAnalysisSagas';
import profileSagas from './Common/Profile/ProfileSagas';
import proposalSagas from '@/store/Common/Proposal/ProposalSagas';
import registerSagas from '@auth/RegisterSagas';
import TariffSagas from '@/containers/Common/Modal/components/TariffModal/TariffSagas';
import tipsSagas from '@tips/TipsSagas';

export default function* rootSaga() {
  yield all([fork(addressFormSagas)]);
  yield all([fork(alertsSagas)]);
  yield all([fork(consentSagas)]);
  yield all([fork(dynamicPageSagas)]);
  yield all([fork(ejpTempoSagas)]);
  yield all([fork(favouritesSagas)]);
  yield all([fork(hintsSagas)]);
  yield all([fork(homeSagas)]);
  yield all([fork(tipsSagas)]);
  yield all([fork(householdComparisonSagas)]);
  yield all([fork(loginSagas)]);
  yield all([fork(passwordSagas)]);
  yield all([fork(pceFormSagas)]);
  yield all([fork(pdlFormSagas)]);
  yield all([fork(powerAnalysisSagas)]);
  yield all([fork(profileSagas)]);
  yield all([fork(proposalSagas)]);
  yield all([fork(registerSagas)]);
  yield all([fork(TariffSagas)]);
  //
  yield all([fork(electricitySagas)]);
  yield all([fork(gasSagas)]);
  yield all([fork(devicesSagas)]);
}
