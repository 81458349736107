import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AddressState {
  data: any;
  cache: any;
  error: any;
  isLoading: boolean;
}

const initialState: AddressState = {
  data: null,
  cache: null,
  error: null,
  isLoading: false,
};

const clearAddressErrorHandler = (state: AddressState) => {
  state.error = null;
};

const updateAddressIsLoadingHandler = (state: AddressState, action: PayloadAction<boolean>) => {
  state.isLoading = action.payload;
};

const updateAddressDataHandler = (state: AddressState, action: any) => {
  const features = action.payload;

  state.data = features?.map(({ properties }: any) => properties);
};

const updateAddressErrorHandler = (state: AddressState, action: any) => {
  state.error = action.payload;
};

const resetEnedisResponseHandler = (state: AddressState) => {
  state.error = false;
  state.isLoading = false;

  if (state?.data) {
    state.cache = state.data;
  }

  state.data = null;
};

export const addressFormSlice = createSlice({
  name: 'addressForm',
  initialState,
  reducers: {
    updateAddressIsLoading: updateAddressIsLoadingHandler,
    updateAddressData: updateAddressDataHandler,
    updateAddressError: updateAddressErrorHandler,
    clearAddressError: clearAddressErrorHandler,
    resetEnedisResponse: resetEnedisResponseHandler,
    clearAddress: () => initialState,
  },
});

export const {
  clearAddress,
  clearAddressError,
  resetEnedisResponse,
  updateAddressData,
  updateAddressError,
  updateAddressIsLoading,
} = addressFormSlice.actions;

export default addressFormSlice.reducer;
