import { ChartCategory, ContractType } from '@/ui-toolkit/common/types';
import { EJP, HPHC, TEMPO } from '@utils/constants/contract-types';
import { KeyValueNumber, perc } from '../Consumption.utils';

export interface createDisabledProps {
  category: ChartCategory;
  contract: ContractType;
  averageSubscription: number;
}

interface resultReturn {
  percentages: KeyValueNumber;
  totalPercentage: number;
  disabled: boolean;
}

export const createPriceDisabledBar = (averageSubscription: number, max: number, contract: ContractType) => {
  const _b = max / 2;
  const _s = averageSubscription;
  const _sum = _b + _s;

  let totalPercentage = perc(_sum, max);

  if (totalPercentage > 100) {
    totalPercentage = 70;
  }

  const result: resultReturn = {
    percentages: {
      base: perc(_b, _sum),
      subscription: perc(_s, _sum),
    },
    totalPercentage,
    disabled: true,
  };

  if (contract === EJP) {
    result.percentages['blue'] = result.percentages.base;
    delete result.percentages.base;
  }

  if (contract === TEMPO) {
    result.percentages['blue_peak'] = result.percentages.base;
    delete result.percentages.base;
  }

  if (contract === HPHC) {
    result.percentages['peak'] = result.percentages.base / 2;
    result.percentages['off_peak'] = result.percentages.base / 2;
    delete result.percentages.base;
  }

  return result;
};

export const createConsumptionDisabledBar = () => {
  const result = {
    percentages: {
      base: 100,
    },
    totalPercentage: 50,
    disabled: true,
  };

  return result;
};
