import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@store/Reducer';

import { AlertsState } from './AlertsSlice';

export const selectAlertState = (state: RootState) => state.alerts;

export const selectAlertsIsLoading = createSelector(selectAlertState, (state: AlertsState) => state.isLoading);

export const selectAlerts = createSelector(selectAlertState, (state: AlertsState) => state.alerts);

export const selectTotalUnreadedAlerts = createSelector(selectAlertState, (state: AlertsState) => {
  let count = 0;

  if (!state?.alerts) return 0;

  const { marketplace, common } = state.alerts;

  [...marketplace, ...common].forEach(alert => !alert.has_been_read && count++);

  return count;
});

export const selectAlertsCount = createSelector(selectAlertState, (state: AlertsState) => {
  if (!state?.alerts) return 0;

  const { marketplace, common } = state.alerts;

  return marketplace.length + common.length;
});
