import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import { updateHouseholdComparison, updateHouseholdComparisonError } from './HouseholdComparisonSlice';
import { ENDPOINTS } from '@utils/constants/endpoints';
import { ResponseGenerator } from '@/shared/interfaces/ResponseGenerator';
export const householdComparisonActions = {
  GET_HOUSEHOLD_COMPARISON: 'GET_HOUSEHOLD_COMPARISON',
};

export function* getHouseholdComparison({ addressId }: any) {
  try {
    const endpoint = `${ENDPOINTS.ADDRESS_API}/electricity/${addressId}/household-comparison`;
    const response: ResponseGenerator = yield call(axios.get, endpoint);

    if (response.status === 204) {
      yield put(updateHouseholdComparisonError({ addressId, errorCode: 204 }));
      return;
    }

    yield put(updateHouseholdComparison({ addressId, householdComparison: response?.data?.data }));
  } catch (error: any) {
    console.error(error);
    yield put(updateHouseholdComparisonError({ addressId, errorCode: error?.response?.status || 500 }));
  }
}

export default function* consumptionSagas() {
  yield takeLatest(householdComparisonActions.GET_HOUSEHOLD_COMPARISON, getHouseholdComparison);
}
