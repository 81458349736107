import axios from 'axios';
import { call, takeLatest, put } from 'redux-saga/effects';
import { ENDPOINTS } from '@utils/constants/endpoints';
import { ResponseGenerator } from '@/shared/interfaces/ResponseGenerator';
import { updateDynamicPageData, updateDynamicPageIsLoading, updateDynamicPageError } from './DynamicPageSlice';
import { clearDynamicPage } from './DynamicPageSlice';
import { apiResponseAdapter } from './DynamicPage.utils';
import { runtimeConfig } from '@/runtime-config';

export const dynamicPageActions = {
  GET_DYNAMIC_PAGE_CONTENTS: 'GET_DYNAMIC_PAGE_CONTENTS',
};

export function* getDynamicPageContents({ slug }: ReturnType<any>) {
  try {
    yield put(clearDynamicPage());
    yield put(updateDynamicPageIsLoading(true));

    const endpoint = `${runtimeConfig.APP_API_URL}${ENDPOINTS.DYNAMIC_PAGES}/${slug}`;
    const { data }: ResponseGenerator = yield call(axios.get, endpoint);

    const adaptedData = apiResponseAdapter(data) as any;
    yield put(updateDynamicPageData(adaptedData));
  } catch (error: any) {
    console.error('error', error);

    yield put(updateDynamicPageError(error));
  } finally {
    yield put(updateDynamicPageIsLoading(false));
  }
}

export default function* tipsSagas() {
  yield takeLatest(dynamicPageActions.GET_DYNAMIC_PAGE_CONTENTS, getDynamicPageContents);
}
