const IconLightBulb = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_784_8960)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.75 -1H23.75V0H24.75V-1ZM10.588 21.413C10.9793 21.8043 11.45 22 12 22C12.55 22 13.021 21.8043 13.413 21.413C13.8043 21.021 14 20.55 14 20H10C10 20.55 10.196 21.021 10.588 21.413ZM8.288 18.712C8.47933 18.904 8.71667 19 9 19H15C15.2833 19 15.521 18.904 15.713 18.712C15.9043 18.5207 16 18.2833 16 18C16 17.7167 15.9043 17.4793 15.713 17.288C15.521 17.096 15.2833 17 15 17H9C8.71667 17 8.47933 17.096 8.288 17.288C8.096 17.4793 8 17.7167 8 18C8 18.2833 8.096 18.5207 8.288 18.712ZM5.513 13.25C6.18767 14.4 7.1 15.3167 8.25 16H15.75C16.9 15.3167 17.8127 14.4 18.488 13.25C19.1627 12.1 19.5 10.85 19.5 9.5C19.5 7.41667 18.7707 5.646 17.312 4.188C15.854 2.72933 14.0833 2 12 2C9.91667 2 8.146 2.72933 6.688 4.188C5.22933 5.646 4.5 7.41667 4.5 9.5C4.5 10.85 4.83767 12.1 5.513 13.25ZM15.15 14H8.85C8.1 13.4667 7.521 12.8083 7.113 12.025C6.70433 11.2417 6.5 10.4 6.5 9.5C6.5 7.96667 7.03333 6.66667 8.1 5.6C9.16667 4.53333 10.4667 4 12 4C13.5333 4 14.8333 4.53333 15.9 5.6C16.9667 6.66667 17.5 7.96667 17.5 9.5C17.5 10.4 17.296 11.2417 16.888 12.025C16.4793 12.8083 15.9 13.4667 15.15 14Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_784_8960'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconLightBulb;
