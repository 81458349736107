const IconErrorCircle = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_147_1947)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25 -1H24V0H25V-1ZM12.713 12.712C12.521 12.904 12.2833 13 12 13C11.7167 13 11.4793 12.9083 11.288 12.725C11.096 12.5417 11 12.3083 11 12.025V8C11 7.71667 11.096 7.479 11.288 7.287C11.4793 7.09567 11.7167 7 12 7C12.2833 7 12.521 7.09167 12.713 7.275C12.9043 7.45833 13 7.69167 13 7.975V12C13 12.2833 12.9043 12.5207 12.713 12.712ZM12.713 16.712C12.521 16.904 12.2833 17 12 17C11.7167 17 11.4793 16.904 11.288 16.712C11.096 16.5207 11 16.2833 11 16C11 15.7167 11.096 15.479 11.288 15.287C11.4793 15.0957 11.7167 15 12 15C12.2833 15 12.521 15.0957 12.713 15.287C12.9043 15.479 13 15.7167 13 16C13 16.2833 12.9043 16.5207 12.713 16.712ZM8.1 21.212C9.31667 21.7373 10.6167 22 12 22C13.3833 22 14.6833 21.7373 15.9 21.212C17.1167 20.6873 18.175 19.975 19.075 19.075C19.975 18.175 20.6873 17.1167 21.212 15.9C21.7373 14.6833 22 13.3833 22 12C22 10.6167 21.7373 9.31667 21.212 8.1C20.6873 6.88333 19.975 5.825 19.075 4.925C18.175 4.025 17.1167 3.31233 15.9 2.787C14.6833 2.26233 13.3833 2 12 2C10.6167 2 9.31667 2.26233 8.1 2.787C6.88333 3.31233 5.825 4.025 4.925 4.925C4.025 5.825 3.31267 6.88333 2.788 8.1C2.26267 9.31667 2 10.6167 2 12C2 13.3833 2.26267 14.6833 2.788 15.9C3.31267 17.1167 4.025 18.175 4.925 19.075C5.825 19.975 6.88333 20.6873 8.1 21.212ZM17.663 17.663C16.1043 19.221 14.2167 20 12 20C9.78333 20 7.896 19.221 6.338 17.663C4.77933 16.1043 4 14.2167 4 12C4 9.78333 4.77933 7.89567 6.338 6.337C7.896 4.779 9.78333 4 12 4C14.2167 4 16.1043 4.779 17.663 6.337C19.221 7.89567 20 9.78333 20 12C20 14.2167 19.221 16.1043 17.663 17.663Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_147_1947'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconErrorCircle;
