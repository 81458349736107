export const STATUS = {
  AUTH_ERROR: 'auth-error',
  AUTH_PASS_RESET_NEEDED: 'auth-pass-reset-needed',
  AUTH_USER_NOT_VERIFY_EMAIL: 'auth-user-not-verify-email-error',
  AUTH_WITH_GOOGLE_ERROR: 'AUTH_WITH_GOOGLE_ERROR',
  AUTH_WITH_APPLE_ERROR: 'AUTH_WITH_APPLE_ERROR',
  DATA_ERROR: 'data-error',
  ERROR: 'error',
  LOADING: 'loading',
  NO_CONTENT: 'no-content',
  PCE_IN_USE: 'pce-in-use-error',
  PCE_INVALID: 'pce-invalid-error',
  PDL_NO_LINKY_ERROR: 'pdl-no-linky-error',
  PDL_SERVICE_DOWN: 'pdl-service-down',
  PENDING: 'pending',
  READY: 'ready',
  SENDING: 'sending',
  SERVER_ERROR: 'server-error',
  SUCCESS: 'success',
};
