import { createPriceDisabledBar } from './disabled-bar';
import { generateBarsProps, generateBarsReturn } from './generate-bars';
import { perc } from '../Consumption.utils';
import { PRICE, CONSUMPTION } from '@utils/constants/category-types';
import { TEMPO } from '@utils/constants/contract-types';

export const tempoBar = (props: generateBarsProps): generateBarsReturn => {
  const { category, node, max, peak_hours_can_be_used, averageSubscription } = props;
  const { base, subscription, tempo } = node;

  if (!tempo) {
    return createPriceDisabledBar(averageSubscription, max, TEMPO);
  }

  let sum = 0;
  const { blue_peak, blue_off_peak, red_peak, red_off_peak, white_peak, white_off_peak } = tempo;

  const tempoNodeSum = Object.values(tempo).reduce((_sum, _curr) => _sum + _curr, 0);

  if (category === PRICE) {
    if (base + tempoNodeSum === 0) {
      return createPriceDisabledBar(averageSubscription, max, TEMPO);
    }

    if (tempoNodeSum === 0 || !peak_hours_can_be_used) {
      // Return BASE:
      sum = base + subscription;

      return {
        percentages: {
          base: perc(base, sum),
          subscription: perc(subscription, sum),
        },
        totalPercentage: perc(sum, max),
      };
    }

    // Node has values:
    sum = tempoNodeSum + subscription;

    return {
      percentages: {
        blue_peak: perc(blue_peak, sum),
        blue_off_peak: perc(blue_off_peak, sum),
        red_peak: perc(red_peak, sum),
        red_off_peak: perc(red_off_peak, sum),
        white_peak: perc(white_peak, sum),
        white_off_peak: perc(white_off_peak, sum),
        subscription: perc(subscription, sum),
      },
      totalPercentage: perc(sum, max),
    };
  }

  if (category === CONSUMPTION) {
    if (base + tempoNodeSum === 0) {
      return createPriceDisabledBar(0, max, TEMPO);
    }

    if (tempoNodeSum === 0 || !peak_hours_can_be_used || base + tempoNodeSum === 0) {
      // Return BASE:
      return {
        percentages: {
          base: 100,
        },
        totalPercentage: perc(base, max),
      };
    }

    // Node has values:
    sum = tempoNodeSum as number;

    return {
      percentages: {
        blue_peak: perc(blue_peak, sum),
        blue_off_peak: perc(blue_off_peak, sum),
        red_peak: perc(red_peak, sum),
        red_off_peak: perc(red_off_peak, sum),
        white_peak: perc(white_peak, sum),
        white_off_peak: perc(white_off_peak, sum),
      },
      totalPercentage: perc(sum, max),
    };
  }

  // Default:
  return {
    percentages: {},
    totalPercentage: 0,
  };
};
