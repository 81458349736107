import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PceFormState {
  pceIsValid: boolean | undefined;
  pceError: string | null;
  isLoading: boolean;
}

const initialState: PceFormState = {
  pceIsValid: undefined,
  pceError: null,
  isLoading: false,
};

const clearPceFormErrorHandler = (state: PceFormState) => {
  state.pceError = null;
};

const updatePceFormIsLoadingHandler = (state: PceFormState, action: PayloadAction<boolean>) => {
  state.isLoading = action.payload;
};

const updatePceFormErrorHandler = (state: PceFormState, action: PayloadAction<string>) => {
  state.pceError = action.payload;
};

const updatePceCheckedHandler = (state: PceFormState, action: PayloadAction<boolean>) => {
  state.pceIsValid = action.payload;
};

export const pceFormSlice = createSlice({
  name: 'pceForm',
  initialState,
  reducers: {
    updatePceChecked: updatePceCheckedHandler,
    updatePceFormIsLoading: updatePceFormIsLoadingHandler,
    updatePceFormError: updatePceFormErrorHandler,
    clearPceFormError: clearPceFormErrorHandler,
    clearPceForm: () => initialState,
  },
});

export const { clearPceForm, clearPceFormError, updatePceChecked, updatePceFormError, updatePceFormIsLoading } =
  pceFormSlice.actions;

export default pceFormSlice.reducer;
