import { baseTooltip } from './base-tooltip';
import { ChartCategory, SupplyType, ContractType } from '@/ui-toolkit/common/types';
import { CONSUMPTION, PRICE } from '@utils/constants/category-types';
import { EJP, HPHC, TEMPO } from '@utils/constants/contract-types';
import { ejpTooltip } from './ejp-tooltip';
import { fromFloatToComma } from '@utils/converters';
import { HOUR, WEEK, MONTH, YEAR } from '@utils/constants/consumption-periods';
import { hphcTooltip } from './hphc-tooltip';
import { KeyValueString } from '../Consumption.utils';
import { Period } from '../../Consumption.interface';
import { TariffNode } from '../../Consumption.interfaces';
import { tempoTooltip } from './tempo-tooltip';

export interface generateTooltipProps {
  category: ChartCategory;
  contract: ContractType;
  date: string;
  node: TariffNode;
  peak_hours_can_be_used: boolean;
  period: Period;
  supplyType: SupplyType;
}

export const units: KeyValueString = {
  [CONSUMPTION]: 'kWh',
  [PRICE]: '€',
};

export const labels: { [key: string]: string } = {
  base: 'Consommation',
  blue_off_peak: 'Jour bleu HC',
  blue_peak: 'Jour bleu HP',
  blue: 'Jour bleu',
  disabled: 'Non disponible',
  off_peak: 'HC',
  peak: 'HP',
  red_off_peak: 'Jour rouge HC',
  red_peak: 'Jour rouge HP',
  red: 'Jour rouge',
  subscription: 'Abonnement',
  white_off_peak: 'Jour blanc HC',
  white_peak: 'Jour blanc HP',
};

export const formatPrice = (v: number): string => fromFloatToComma((v || 0) + ' €');

export const formatCons = (v: number): string => fromFloatToComma((v || 0) + ' kWh');

export const fromFloatToCommaFixedTwo = (value: number) => fromFloatToComma(parseFloat(value.toFixed(2)));

export const getTitle = (date: string, period: Period): string => {
  const dateIncludesHour = date.includes('T');
  const dateParsed = dateIncludesHour ? date + ':00:00.000Z' : date;
  const nd = new Date(dateParsed);
  dateIncludesHour && nd.setHours(parseFloat(date.toString().split('T')[1]));

  const options: KeyValueString = {
    day: 'numeric',
    hour: '2-digit',
    month: 'long',
    weekday: 'long',
    year: 'numeric',
  };

  switch (period) {
    case HOUR:
      options.month = 'short';
      break;
    case WEEK:
    case MONTH:
      delete options.hour;
      break;
    case YEAR:
      delete options.day;
      delete options.hour;
      delete options.weekday;
      break;
  }

  return nd.toLocaleDateString('fr-FR', options);
};

export const getTooltip = (props: generateTooltipProps) => {
  let generator;

  switch (props.contract) {
    case EJP:
      generator = ejpTooltip;
      break;
    case HPHC:
      generator = hphcTooltip;
      break;
    case TEMPO:
      generator = tempoTooltip;
      break;
    default:
      generator = baseTooltip;
      break;
  }

  return generator(props);
};
