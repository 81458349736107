import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSearchParams, useLocation } from 'react-router-dom';
import metadataProvider from './PageHeaderTag.data.json';

const provider = metadataProvider as { [key: string]: any };

const PageHeaderTag = () => {
  const [searchParams] = useSearchParams();
  const categories = searchParams.get('categories');
  const { pathname } = useLocation();
  const [metadata, setMetadata] = useState(provider.default);
  const { title, description } = metadata;

  useEffect(() => {
    const path = pathname.replace(/\/$/, '');
    let node;

    if (path.includes('conseils')) {
      const nodeId = categories ? `${path}?categories=${categories}` : path;
      node = provider[nodeId] || provider['/tips'];
    } else {
      node = provider[path] || provider.default;
    }

    setMetadata(node);
  }, [pathname, categories]);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <link rel='canonical' href={location.href} />
    </Helmet>
  );
};

export default PageHeaderTag;
