import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ENDPOINTS } from '@utils/constants/endpoints';
import { PATHS } from '@utils/constants/paths';
import { ResponseGenerator } from '@/shared/interfaces/ResponseGenerator';
import { STATUS } from '@utils/constants/status';
import { updateAuthStatus, updateAuthIsLoading } from './AuthSlice';

export const registerActions = {
  REGISTER: 'REGISTER',
};

export function* registerSaga({ values, navigate }: any) {
  try {
    yield put(updateAuthIsLoading(true));
    yield put(updateAuthStatus(STATUS.LOADING));

    const { firstName, lastName, email, password, consent, source } = values;

    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      password_confirmation: password,
      subscribe_newsletter: consent,
      source,
    };

    const response: ResponseGenerator = yield call(axios.post, ENDPOINTS.REGISTER, { ...data });

    if (response.data.error) {
      yield put(updateAuthStatus(STATUS.AUTH_ERROR));
      return;
    }

    yield put(updateAuthStatus('register-success'));

    if (navigate) {
      navigate(PATHS.SIGNUP_SUCCESS);
    }
  } catch (error: any) {
    console.error('ERROR', error);

    const status = error?.response?.status === 400 ? STATUS.AUTH_ERROR : STATUS.SERVER_ERROR;
    yield put(updateAuthStatus(status));
  } finally {
    yield put(updateAuthIsLoading(false));
  }
}

export default function* registerSagas() {
  yield takeLatest(registerActions.REGISTER, registerSaga);
}
