import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Proposal, ProposalState } from './Proposal.interfaces.d';

const initialState: ProposalState = {
  items: {},
  loading: {},
};

const updateProposalsHandler = (
  state: ProposalState,
  action: PayloadAction<{ sectionId: string; proposal: Proposal }>,
) => {
  const sectionId = action.payload.sectionId;
  const proposalData = action.payload.proposal;

  state.items[sectionId] = proposalData;
};

const updateProposalIsLoadingHandler = (
  state: ProposalState,
  action: PayloadAction<{ sectionId: string; isLoading: boolean }>,
) => {
  const sectionId = action.payload.sectionId;
  const isLoading = action.payload.isLoading;

  state.loading[sectionId] = isLoading;
};

export const proposalSlice = createSlice({
  name: 'proposal',
  initialState,
  reducers: {
    clearProposals: () => initialState,
    updateProposals: updateProposalsHandler,
    updateProposalIsLoading: updateProposalIsLoadingHandler,
  },
});

export const { clearProposals, updateProposals, updateProposalIsLoading } = proposalSlice.actions;

export default proposalSlice.reducer;
