import { ChartCategory, ContractType, SupplyType } from '@/ui-toolkit/common/types';
import { Period } from '../../Consumption.interface';
import { TariffNode } from '../../Consumption.interfaces';
import { fromFloatToCommaFixedTwo, getTitle, units } from './generate-tooltip';
import { PRICE, CONSUMPTION } from '@utils/constants/category-types';
import { KeyValueString } from '../Consumption.utils';

interface helperProps {
  category: ChartCategory;
  node: TariffNode;
  peak_hours_can_be_used: boolean;
}

const parseRes = ({
  subscription,
  tempo,
  category,
}: {
  subscription: number;
  tempo: any;
  category: ChartCategory;
}): KeyValueString => {
  const unit = ' ' + units[category];
  const { blue_peak, blue_off_peak, red_peak, red_off_peak, white_peak, white_off_peak } = tempo;

  const res: KeyValueString = {
    blue_peak: fromFloatToCommaFixedTwo(blue_peak) + unit,
    blue_off_peak: fromFloatToCommaFixedTwo(blue_off_peak) + unit,
    red_peak: fromFloatToCommaFixedTwo(red_peak) + unit,
    red_off_peak: fromFloatToCommaFixedTwo(red_off_peak) + unit,
    white_peak: fromFloatToCommaFixedTwo(white_peak) + unit,
    white_off_peak: fromFloatToCommaFixedTwo(white_off_peak) + unit,
  };

  for (const i in res) {
    if (res[i] === '0 kWh' || res[i] === '0 €') {
      delete res[i];
    }
  }

  if (category === PRICE) {
    res['subscription'] = fromFloatToCommaFixedTwo(subscription) + unit;
  }

  return res;
};

const getRows = ({ category, node, peak_hours_can_be_used }: helperProps): KeyValueString => {
  const { base, subscription, tempo } = node;
  const unit = ' ' + units[category];

  if (!tempo) return {};

  const tempoNodeSum = Object.values(tempo).reduce((_sum, _curr) => (_sum as number) + (_curr as number), 0);
  const displayBase = tempoNodeSum === 0 || !peak_hours_can_be_used;

  if (category === PRICE) {
    if (displayBase) {
      // Return BASE:
      return {
        base: fromFloatToCommaFixedTwo(base) + unit,
        subscription: fromFloatToCommaFixedTwo(subscription) + unit,
      };
    }

    return parseRes({ subscription, tempo, category });
  }

  if (category === CONSUMPTION) {
    if (displayBase) {
      // Return BASE:
      return {
        base: fromFloatToCommaFixedTwo(base) + unit,
      };
    }

    return parseRes({ subscription, tempo, category });
  }

  return {};
};

const getTotal = ({ category, node, peak_hours_can_be_used }: helperProps): string => {
  const unit = ' ' + units[category];
  const { base, subscription, tempo } = node;
  let sum = 0;

  if (!tempo) return 'n/a';

  const tempoNodeSum = Object.values(tempo).reduce((_sum, _curr) => (_sum as number) + (_curr as number), 0);
  const displayBase = tempoNodeSum === 0 || !peak_hours_can_be_used;

  if (category === PRICE) {
    if (displayBase) {
      // Return BASE:
      sum = base + subscription;
    } else {
      sum = tempoNodeSum + subscription;
    }
  }

  if (category === CONSUMPTION) {
    if (displayBase) {
      // Return BASE:
      sum = base;
    } else {
      sum = tempoNodeSum;
    }
  }

  return fromFloatToCommaFixedTwo(sum) + unit;
};

export const tempoTooltip = ({
  category,
  date,
  node,
  peak_hours_can_be_used,
  period,
}: {
  category: ChartCategory;
  contract: ContractType;
  date: string;
  node: TariffNode;
  peak_hours_can_be_used: boolean;
  period: Period;
  supplyType: SupplyType;
}) => {
  return {
    rows: getRows({ category, node, peak_hours_can_be_used }),
    total: getTotal({ category, node, peak_hours_can_be_used }),
    title: getTitle(date, period),
  };
};
