const IconDownload = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_46_1287)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25 -1H24V0H25V-1ZM11.625 15.512C11.7417 15.554 11.8667 15.575 12 15.575C12.1333 15.575 12.2583 15.554 12.375 15.512C12.4917 15.4707 12.6 15.4 12.7 15.3L16.3 11.7C16.4833 11.5167 16.575 11.2833 16.575 11C16.575 10.7167 16.4833 10.4833 16.3 10.3C16.1167 10.1167 15.8793 10.0207 15.588 10.012C15.296 10.004 15.0583 10.0917 14.875 10.275L13 12.15V5C13 4.71667 12.9043 4.479 12.713 4.287C12.521 4.09567 12.2833 4 12 4C11.7167 4 11.4793 4.09567 11.288 4.287C11.096 4.479 11 4.71667 11 5V12.15L9.125 10.275C8.94167 10.0917 8.704 10.004 8.412 10.012C8.12067 10.0207 7.88333 10.1167 7.7 10.3C7.51667 10.4833 7.425 10.7167 7.425 11C7.425 11.2833 7.51667 11.5167 7.7 11.7L11.3 15.3C11.4 15.4 11.5083 15.4707 11.625 15.512ZM4.588 19.413C4.97933 19.8043 5.45 20 6 20H18C18.55 20 19.021 19.8043 19.413 19.413C19.8043 19.021 20 18.55 20 18V16C20 15.7167 19.904 15.479 19.712 15.287C19.5207 15.0957 19.2833 15 19 15C18.7167 15 18.4793 15.0957 18.288 15.287C18.096 15.479 18 15.7167 18 16V18H6V16C6 15.7167 5.90433 15.479 5.713 15.287C5.521 15.0957 5.28333 15 5 15C4.71667 15 4.479 15.0957 4.287 15.287C4.09567 15.479 4 15.7167 4 16V18C4 18.55 4.196 19.021 4.588 19.413Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_46_1287'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconDownload;
