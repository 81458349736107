import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';

import { ENDPOINTS } from '@utils/constants/endpoints';
import { updateHourlyConsentBySupplyId } from '../Profile/ProfileSlice';

export const consentActions = {
  POST_CONSENT: 'POST_CONSENT',
};

export function* postConsentSaga({ id, supplyId }) {
  try {
    yield call(axios.post, ENDPOINTS.CONSENT, { type: id, supply_id: supplyId });
    yield put(updateHourlyConsentBySupplyId({ supplyId }));
  } catch (error) {
    console.error(error);
  }
}

export default function* ConsentSagas() {
  yield takeLatest(consentActions.POST_CONSENT, postConsentSaga);
}
