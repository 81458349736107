const IconPowerSwitch = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1920_3011)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.75 -1H23.75V0H24.75V-1ZM11.2875 12.7125C11.4792 12.9042 11.7167 13 12 13C12.2833 13 12.5208 12.9042 12.7125 12.7125C12.9042 12.5208 13 12.2833 13 12V4C13 3.71667 12.9042 3.47917 12.7125 3.2875C12.5208 3.09583 12.2833 3 12 3C11.7167 3 11.4792 3.09583 11.2875 3.2875C11.0958 3.47917 11 3.71667 11 4V12C11 12.2833 11.0958 12.5208 11.2875 12.7125ZM8.4875 20.2875C9.57917 20.7625 10.75 21 12 21C13.25 21 14.4208 20.7625 15.5125 20.2875C16.6042 19.8125 17.5542 19.1708 18.3625 18.3625C19.1708 17.5542 19.8125 16.6042 20.2875 15.5125C20.7625 14.4208 21 13.25 21 12C21 10.85 20.7917 9.75 20.375 8.7C19.9583 7.65 19.3583 6.7 18.575 5.85C18.3917 5.65 18.1583 5.55 17.875 5.55C17.5917 5.55 17.35 5.65 17.15 5.85C16.9667 6.03333 16.8792 6.25833 16.8875 6.525C16.8958 6.79167 16.9917 7.025 17.175 7.225C17.7583 7.875 18.2083 8.6125 18.525 9.4375C18.8417 10.2625 19 11.1167 19 12C19 13.95 18.3208 15.6042 16.9625 16.9625C15.6042 18.3208 13.95 19 12 19C10.05 19 8.39583 18.3208 7.0375 16.9625C5.67917 15.6042 5 13.95 5 12C5 11.1167 5.15417 10.2708 5.4625 9.4625C5.77083 8.65417 6.21667 7.925 6.8 7.275C6.98333 7.05833 7.08333 6.8125 7.1 6.5375C7.11667 6.2625 7.03333 6.03333 6.85 5.85C6.65 5.65 6.40833 5.55 6.125 5.55C5.84167 5.55 5.60833 5.65 5.425 5.85C4.64167 6.7 4.04167 7.65 3.625 8.7C3.20833 9.75 3 10.85 3 12C3 13.25 3.2375 14.4208 3.7125 15.5125C4.1875 16.6042 4.82917 17.5542 5.6375 18.3625C6.44583 19.1708 7.39583 19.8125 8.4875 20.2875Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1920_3011'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconPowerSwitch;
