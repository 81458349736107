import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DrawerState {
  componentId: string | null;
  props?: { [key: string]: any };
}

const initialState = {
  componentId: null,
  props: {},
};

const updateDrawerComponentHandler = (state: DrawerState, action: PayloadAction<DrawerState>) => {
  const { componentId, props } = action.payload;
  state.componentId = componentId;
  state.props = props;
};

const updateDrawerPropsHandler = (state: DrawerState, action: PayloadAction<{ [key: string]: any }>) => {
  state.props = { ...state.props, ...action.payload };
};

export const DrawerSlice = createSlice({
  name: 'Drawer',
  initialState,
  reducers: {
    updateDrawerComponent: updateDrawerComponentHandler,
    updateDrawerProps: updateDrawerPropsHandler,
    clearDrawer: () => initialState,
  },
});

export const { updateDrawerComponent, clearDrawer, updateDrawerProps } = DrawerSlice.actions;

export default DrawerSlice.reducer;
