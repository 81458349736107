import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Proposal } from '../../../store/Common/Proposal/Proposal.interfaces.d';
import { IDashboardSummaryList } from '../misc/HomeSummary.interfaces';

interface IDashboardState {
  data: {
    rows: IDashboardSummaryList | null;
    tips: TipsItem[] | null;
    ad: Proposal | null;
  };
  error: any;
  tipsIsLoading: boolean;
}

const initialState: IDashboardState = {
  data: {
    rows: null,
    tips: null,
    ad: null,
  },
  error: {},
  tipsIsLoading: false,
};

const updateTipsIsLoadingHandler = (state: IDashboardState, action: PayloadAction<boolean>) => {
  state.tipsIsLoading = action.payload;
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    updateTipsIsLoading: updateTipsIsLoadingHandler,
    clearDashboard: () => initialState,
  },
});

export const { clearDashboard, updateTipsIsLoading } = homeSlice.actions;

export default homeSlice.reducer;
