import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DevicesData, DevicesEstimatedConsumption } from '../misc/DevicesConsumption.interfaces';

export interface DevicesState {
  data: DevicesData | null;
  currentNodeIndex: number;
  isLoading: boolean;
  error: any;
}

const initialState = {
  data: null,
  currentNodeIndex: 0,
  isLoading: false,
  error: '',
};

const updateDevicesHandler = (state: DevicesState, action: PayloadAction<any>) => {
  const data = action.payload;

  const consumptionNodes = (data.estimated_consumption as DevicesEstimatedConsumption[])
    .filter(node => node.power_consumption > 0)
    .sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf());

  state.data = { ...data, estimated_consumption: consumptionNodes };
  state.currentNodeIndex = consumptionNodes.length - 1;
};

const updateDevicesIsLoadingHandler = (state: DevicesState, action: PayloadAction<any>) => {
  state.isLoading = action.payload;
};

const updateDevicesErrorHandler = (state: DevicesState, action: PayloadAction<any>) => {
  state.error = action.payload;
};

const updateDevicesSelectedHandler = (state: DevicesState, action: PayloadAction<number>) => {
  state.currentNodeIndex = action.payload;
};

export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    clearConsumption: () => initialState,
    updateDevices: updateDevicesHandler,
    updateDevicesError: updateDevicesErrorHandler,
    updateDevicesIsLoading: updateDevicesIsLoadingHandler,
    updateDevicesSelected: updateDevicesSelectedHandler,
  },
});

export const { clearConsumption, updateDevices, updateDevicesError, updateDevicesIsLoading, updateDevicesSelected } =
  devicesSlice.actions;

export default devicesSlice.reducer;
