import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import LogoSelectraMobile from '@/assets/LogoSelectraMobile';
import { selectCurrentDeviceType } from '@/store/Common/WindowResizeTool/WindowResizeToolSelector';
import AddressDropdown from '@/ui-toolkit/components/AddressDropdown/AddressDropdown';
import Button from '@/ui-toolkit/components/Button/Button';
import ButtonCircular from '@/ui-toolkit/components/ButtonCircular/ButtonCircular';
import MySelectra from '@/ui-toolkit/logos/MySelectra';
import IconAccountCircle from '@icons/IconAccountCircle';
import IconArrowForward from '@icons/IconArrowForward';
import IconHome from '@icons/IconHome';
import IconPerson from '@icons/IconPerson';
import IconPowerSwitch from '@icons/IconPowerSwitch';
import IconStarCircle from '@icons/IconStarCircle';
import { PATHS } from '@utils/constants/paths';
import styles from './Header.module.scss';
import useHeader from './useHeader';

const Header = () => {
  const { isMobile, isMobileSmall } = useSelector(selectCurrentDeviceType);

  const navigate = useNavigate();

  const buttonSize = (() => {
    if (isMobileSmall) return 'xs';
    if (isMobile) return 'sm';
    return 'md';
  })();

  const { isLoggedIn, showShadow, openLogoutModal, openLoginModal, openModal, openRegisterModal } = useHeader();
  const shadowStyle = showShadow ? styles.shadow : '';

  return (
    <div className={`${styles.Header} ${shadowStyle}`}>
      <div className={styles.Header__logo}>
        <Link to={'/home'}>{window.innerWidth > 1024 ? <MySelectra /> : <LogoSelectraMobile />}</Link>
      </div>

      {isLoggedIn ? (
        <div className={styles.sideContent}>
          <AddressDropdown />

          {!isMobile && (
            <Button
              id='button-mpc-cta'
              label='Mon conseiller perso'
              border='pill'
              size={buttonSize}
              iconLeft={<IconStarCircle />}
              onClick={openModal}
            />
          )}

          <ButtonCircular icon={<IconHome />} onClick={() => navigate(PATHS.HOME)} />

          <ButtonCircular icon={<IconPerson />} onClick={() => navigate(PATHS.PROFILE)} />

          <ButtonCircular icon={<IconPowerSwitch />} onClick={openLogoutModal} />
        </div>
      ) : (
        <div className={styles.sideContent}>
          <Button
            id='button-connect-cta'
            label='Se connecter'
            border='pill'
            fill='ghost'
            size={buttonSize}
            variant='secondary'
            iconLeft={<IconAccountCircle />}
            onClick={openLoginModal}
          />

          <Button
            id='button-register-cta'
            label="S'inscrire"
            border='pill'
            size={buttonSize}
            iconRight={<IconArrowForward />}
            onClick={openRegisterModal}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
