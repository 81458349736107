import { RootState } from '@/store/Reducer';
import { createSelector } from '@reduxjs/toolkit';
import { TipsState } from './TipsSlice';

export const selectTips = (state: RootState) => state.tips;

export const selectTipsData = createSelector(selectTips, (tips: TipsState) => tips.data || {});

export const selectTipsError = createSelector(selectTips, (tips: TipsState) => tips.error);

export const selectTipsIsLoading = createSelector(selectTips, (tips: TipsState) => tips.isLoading);

export const selectTipsPage = createSelector(selectTips, (tips: TipsState) => tips.page);

export const selectTipsCurrentTip = createSelector(selectTips, (tips: TipsState) => {
  return tips.currentTip;
});
