import { useEffect, useState } from 'react';

export interface useBrowserDetectionProps {
  isChrome: boolean;
  isFirefox: boolean;
  isIE: boolean;
  isOpera: boolean;
  isSafari: boolean;
  isEdge: boolean;
}

type BrowserTypes = 'chrome' | 'edge' | 'firefox' | 'ie' | 'opera' | 'safari' | 'not-detected';

const useBrowserDetection = () => {
  const [browserType, setBrowserType] = useState<BrowserTypes>('not-detected');

  useEffect(() => {
    const checkBrowser = () => {
      const userAgentString = navigator.userAgent;

      const isChrome = userAgentString.indexOf('Chrome') > -1;
      const isEdge = userAgentString.indexOf('Edg') > -1;
      const isFirefox = userAgentString.indexOf('Firefox') > -1;
      const isIE = !isFirefox && (userAgentString.indexOf('MSIE') > -1 || userAgentString.indexOf('rv:') > -1);
      const isOpera = userAgentString.indexOf('OP') > -1;
      const isSafari = !isChrome && userAgentString.indexOf('Safari') > -1;

      if (isOpera) return 'opera';
      if (isChrome) return 'chrome';
      if (isEdge) return 'edge';
      if (isFirefox) return 'firefox';
      if (isIE) return 'ie';
      if (isSafari) return 'safari';

      return 'not-detected';
    };

    setBrowserType(checkBrowser());
  }, []);

  return {
    browserType,
  };
};

export default useBrowserDetection;
