import axios from 'axios';
import { takeLatest, put, call } from 'redux-saga/effects';

import { ENDPOINTS } from '@utils/constants/endpoints';
import { ResponseGenerator } from '@/shared/interfaces/ResponseGenerator';
import { runtimeConfig } from '@/runtime-config';
import { updateHintsData, updateHintsError, updateHintsIsLoading, updateHint } from './HintsSlice';

export const hintsActions = {
  GET_HINTS: 'GET_HINTS',
  UPDATE_HINTS: 'UPDATE_HINTS',
};

export function* getHintsSaga() {
  try {
    yield put(updateHintsIsLoading(true as any));

    const response: ResponseGenerator = yield call(axios.get, `${runtimeConfig.APP_API_URL}/${ENDPOINTS.HINTS}`);

    const { data } = response?.data || {};

    yield put(updateHintsData(data));
  } catch (error) {
    console.error(error);

    yield put(updateHintsError(error as any));
  } finally {
    yield put(updateHintsIsLoading(false as any));
  }
}

export function* updateHintsSaga({ id }: any) {
  try {
    yield put(updateHintsIsLoading(true as any));
    yield put(updateHint({ id } as any));

    yield call(axios.post, `${runtimeConfig.APP_API_URL}/${ENDPOINTS.HINT}`, { slug: id });
  } catch (error) {
    console.error(error);

    yield put(updateHintsError(error as any));
  } finally {
    yield put(updateHintsIsLoading(false as any));
  }
}

export default function* hintsSagas() {
  yield takeLatest(hintsActions.GET_HINTS, getHintsSaga);
  yield takeLatest(hintsActions.UPDATE_HINTS, updateHintsSaga);
}
