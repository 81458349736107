import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AddressEntity, ProfileEntity } from '@/store/Common/Profile/Profile.interfaces';
import { selectCurrentAddress, selectProfileData } from '@/store/Common/Profile/ProfileSelectors';
import { updateCurrentAddress } from '@/store/Common/Profile/ProfileSlice';

const useCurrentAddress = () => {
  const dispatch = useDispatch();
  const profile: ProfileEntity | null = useSelector(selectProfileData);
  const currentAddress: AddressEntity | null = useSelector(selectCurrentAddress);

  useEffect(() => {
    if (profile && !currentAddress) {
      dispatch(updateCurrentAddress(profile?.addresses?.[0]));
    }
  }, [profile]);

  return {};
};

export default useCurrentAddress;
