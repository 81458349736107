import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AddressEntity, TalonEntity } from '@/store/Common/Profile/Profile.interfaces';
import { ProfileEntity, SupplyEntity, ComparatorProps } from '@/store/Common/Profile/Profile.interfaces';
import { PROFESSIONAL } from '@utils/constants/address-types';
import { TVA, TTC } from '@utils/constants/taxes-types';

const compare = (a: SupplyEntity, b: SupplyEntity) => {
  if (a.type < b.type) return -1;
  if (a.type > b.type) return 1;
  return 0;
};

export interface ProfileState {
  currentAddress: AddressEntity | null;
  data: null | ProfileEntity;
  error: number | null;
  isLoading: boolean;
}

const initialState: ProfileState = {
  data: null,
  currentAddress: null,
  error: null,
  isLoading: false,
};

const updateProfileHandler = (state: ProfileState, action: PayloadAction<ProfileEntity>) => {
  const addresses = action.payload?.addresses?.map((address: AddressEntity) => {
    let containsExpiredSupplies = false;

    address.supplies.forEach(({ expired }) => {
      if (expired) containsExpiredSupplies = true;
    });

    address.tax = address?.type === PROFESSIONAL ? TVA : TTC;

    return {
      ...{
        ...address,
        supplies: [...address.supplies].sort(compare),
      },
      containsExpiredSupplies,
    };
  });

  state.data = {
    ...action.payload,
    addresses,
  };

  const updatedCurrentAddress = addresses.filter(address => address.id === state.currentAddress?.id)?.[0];

  if (updatedCurrentAddress) {
    state.currentAddress = updatedCurrentAddress;
  }
};

const updateCurrentAddressHandler = (state: ProfileState, action: PayloadAction<AddressEntity>) => {
  if (!action.payload) {
    return;
  }

  state.currentAddress = action.payload;
};

const updateCurrentAddressByIdHandler = (state: ProfileState, action: PayloadAction<string>) => {
  if (!state?.data?.addresses) return;

  const addresses = [...state.data.addresses];
  const address = addresses.filter(address => address.id === action.payload)?.[0];

  if (!address) return;

  state.currentAddress = address;
};

const updateProfileIsLoadingHandler = (state: ProfileState, action: PayloadAction<boolean>) => {
  state.isLoading = action.payload;
};

const updateProfileErrorHandler = (state: ProfileState, action: PayloadAction<number | null>) => {
  state.error = action.payload;
};

const updateHourlyConsentBySupplyIdHandler = (state: ProfileState, action: PayloadAction<{ supplyId: string }>) => {
  //
  const _address: AddressEntity | undefined = state.data?.addresses.filter(
    ({ id }) => id === state.currentAddress?.id,
  )[0];

  if (!_address || !state?.data?.addresses) {
    return;
  }

  const _supplies = _address.supplies.map(supply => {
    return supply.id === action.payload.supplyId ? { ...supply, hourly_consumption_is_consented: true } : supply;
  });

  const _addresses: AddressEntity[] = state.data.addresses.map(_a => {
    return _a.id === _address.id ? { ..._a, supplies: _supplies } : _a;
  });

  state.data = { ...state.data, addresses: _addresses };
  state.currentAddress = { ..._address, supplies: _supplies };
};

const updateAddressComparatorPropsHandler = (
  state: ProfileState,
  action: PayloadAction<{ addressId: string; comparatorProps: ComparatorProps }>,
) => {
  if (!state.data) return;

  const { addressId, comparatorProps } = action.payload;

  const addresses = state.data?.addresses.map(address => {
    if (address.id === addressId) return { ...address, comparatorProps };
    return address;
  });

  state.data = { ...state.data, addresses };

  if (state.currentAddress?.id === addressId) {
    state.currentAddress = { ...state.currentAddress, comparatorProps };
  }
};

const updateTalonHandler = (
  state: ProfileState,
  action: PayloadAction<{ addressId: string; talon: TalonEntity | null }>,
) => {
  if (!state.data) return;

  const { addressId, talon } = action.payload;

  const addresses = state.data?.addresses.map(address => (address.id === addressId ? { ...address, talon } : address));

  state.data = { ...state.data, addresses };

  if (state.currentAddress?.id === addressId) {
    state.currentAddress = { ...state.currentAddress, talon };
  }
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    clearProfile: () => initialState,
    updateAddressComparatorProps: updateAddressComparatorPropsHandler,
    updateCurrentAddress: updateCurrentAddressHandler,
    updateCurrentAddressById: updateCurrentAddressByIdHandler,
    updateHourlyConsentBySupplyId: updateHourlyConsentBySupplyIdHandler,
    updateProfile: updateProfileHandler,
    updateProfileError: updateProfileErrorHandler,
    updateProfileIsLoading: updateProfileIsLoadingHandler,
    updateTalon: updateTalonHandler,
  },
});

export const {
  clearProfile,
  updateAddressComparatorProps,
  updateCurrentAddress,
  updateCurrentAddressById,
  updateHourlyConsentBySupplyId,
  updateProfile,
  updateProfileError,
  updateProfileIsLoading,
  updateTalon,
} = profileSlice.actions;

export default profileSlice.reducer;
