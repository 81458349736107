import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import { runtimeConfig } from '@/runtime-config';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';

if (!runtimeConfig.isDevelopment) {
  Sentry.init({
    dsn: runtimeConfig.APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0.2,
    environment: runtimeConfig.env,
  });
}

if (runtimeConfig.isProduction) {
  try {
    datadogRum.init({
      clientToken: 'pub7eef85d94386e5e2b025ecbcb50e814d',
      applicationId: '27423e0d-bfed-4578-aeb8-6007fce0ffa0',
      site: 'datadoghq.com',
      service: 'customer-space',
      env: 'production',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackFrustrations: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: ['https://my.selectra.com'],
      traceSampleRate: 20,
    });
  } catch (error) {
    console.error('Datadog init error.');
    console.error(error);
  }
}

if ('serviceWorker' in navigator) {
  // Register a service worker hosted at the root of the
  // site using the default scope.
  navigator.serviceWorker.register('/custom-sw.js').then(
    registration => {
      const sw = registration.installing || registration.waiting || registration.active;
      sw.postMessage({ milliseconds: Date.now() });
    },
    error => {
      console.error(`Service worker registration failed: ${error}`);
    },
  );
} else {
  console.error('Service workers are not supported.');
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <GoogleOAuthProvider clientId='306796390255-keb0cd4mo423pf36tbtvs57sih2isd1f.apps.googleusercontent.com'>
    <App />
  </GoogleOAuthProvider>,
);
