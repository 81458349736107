const ENV = {
  development: 'development',
  staging: 'staging',
  production: 'production',
};

const modeEnv = {
  isStaging: import.meta.env.VITE_APP_ENV === 'staging',
  isDevelopment: import.meta.env.VITE_APP_ENV === 'development',
  isProduction: import.meta.env.VITE_APP_ENV === 'production',
};

const commonConfig = {
  APP_API_URL: `https://customer-service.app.${import.meta.env.VITE_APP_ENV}.aws.selectra.io/api`,
  APP_SENTRY_DSN: '',
  GOV_ADDRESS_API: 'https://api-adresse.data.gouv.fr/search/',
  GTM_KEY: 'GTM-5HBCDGB',
  TOKEN_ID: 'selectra-auth',
};

const stagingConfig = {
  ...commonConfig,
  env: ENV.staging,
};

const developmentConfig = {
  ...stagingConfig,
  // APP_API_URL: `http://localhost:8080/api`,
  // APP_API_URL: `http://localhost:8085/api`,
  APP_API_URL: `https://customer-service.app.staging.aws.selectra.io/api`,
  env: ENV.development,
  APP_SENTRY_DSN: '',
};

const productionConfig = {
  ...commonConfig,
  env: ENV.production,
  APP_SENTRY_DSN: 'https://3bd3c5a886d648bead5bfb90a512cbde@o1158305.ingest.sentry.io/6368293',
  GTM_KEY: 'GTM-5HBCDGB',
};

const runtimeConfigByEnv = {
  [ENV.development]: developmentConfig,
  [ENV.staging]: stagingConfig,
  [ENV.production]: productionConfig,
};

function getRuntimeConfig() {
  const allowedEnv = Object.values(ENV);
  if (!allowedEnv.includes(import.meta.env.VITE_APP_ENV)) {
    console.warn(
      `
      The desired env '${
        import.meta.env.VITE_APP_ENV
      }' has no runtime config. The valid environments are: ${allowedEnv}.
      Thus, 'development' environment config is given as fallback
      `,
    );
    return stagingConfig;
  }

  const envRuntimeConfig = runtimeConfigByEnv[import.meta.env.VITE_APP_ENV];
  return { ...envRuntimeConfig, ...modeEnv };
}

export const runtimeConfig = getRuntimeConfig();
