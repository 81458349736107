import { StatusType } from '@/ui-toolkit/common/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { STATUS } from '@utils/constants/status';

export interface AuthState {
  token: string | null;
  params: any;
  isLoading: boolean;
  status: StatusType;
  provider: string;
}

const initialState = {
  token: '',
  params: {},
  isLoading: false,
  status: STATUS.PENDING,
  provider: 'web',
};

const updateTokenHandler = (state: AuthState, action: PayloadAction<string | null>) => {
  state.token = action.payload;
};

const updateParamsHandler = (state: AuthState, action: PayloadAction<any>) => {
  state.params = action.payload;
};

const updateAuthIsLoadingHandler = (state: AuthState, action: PayloadAction<boolean>) => {
  state.isLoading = action.payload;
};

const updateAuthStatusHandler = (state: AuthState, action: PayloadAction<StatusType>) => {
  state.status = action.payload;
};

const updateAuthProviderHandler = (state: AuthState, action: PayloadAction<string>) => {
  state.provider = action.payload;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateToken: updateTokenHandler,
    updateParams: updateParamsHandler,
    updateAuthIsLoading: updateAuthIsLoadingHandler,
    updateAuthStatus: updateAuthStatusHandler,
    updateAuthProvider: updateAuthProviderHandler,
    clearAuth: () => initialState,
  },
});

export const { updateAuthProvider, updateToken, updateParams, updateAuthIsLoading, updateAuthStatus, clearAuth } =
  authSlice.actions;

export default authSlice.reducer;
