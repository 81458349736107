import axios from 'axios';
import { call, takeLatest, put } from 'redux-saga/effects';
import { ResponseGenerator } from '@/shared/interfaces/ResponseGenerator';
import { updatePowerAnalysisData, updatePowerAnalysisIsLoading } from './PowerAnalysisSlice';
import { updatePowerAnalysisError, clearPowerAnalysisError } from './PowerAnalysisSlice';
import { clearPowerAnalysis } from './PowerAnalysisSlice';
import { ENDPOINTS } from '@utils/constants/endpoints';

export const powerAnalysisActions = {
  GET_POWER_ANALYSIS: 'GET_POWER_ANALYSIS',
};

export interface putAddressSupplyPricesSagaAction {
  type: typeof powerAnalysisActions.GET_POWER_ANALYSIS;
  addressId: string;
}

export function* getPowerAnalysis({ addressId }: putAddressSupplyPricesSagaAction) {
  try {
    yield put(clearPowerAnalysis());
    yield put(clearPowerAnalysisError());
    yield put(updatePowerAnalysisIsLoading(true));

    const endpoint = `${ENDPOINTS.ADDRESS_API}/electricity/${addressId}/power`;
    const response: ResponseGenerator = yield call(axios.get, endpoint);

    yield put(updatePowerAnalysisData(response?.data?.data));
  } catch (error: any) {
    console.error(error);

    yield put(clearPowerAnalysis());
    yield put(updatePowerAnalysisError(error));
  } finally {
    yield put(updatePowerAnalysisIsLoading(false));
  }
}

export default function* powerAnalysisSagas() {
  yield takeLatest(powerAnalysisActions.GET_POWER_ANALYSIS, getPowerAnalysis);
}
