import { BarDateType } from '../Charts/BarChart/useBarChart';
import { BarSelectedCoords } from '../Charts/BarChart/BarChart.interfaces';
import { ChartCategory } from '@/ui-toolkit/common/types';
import { DevicesConsumptionNode } from '@/containers/Consumption/Devices/misc/DevicesConsumption.interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface TooltipState {
  category?: ChartCategory; // Se usa solo en Devices...
  barDate?: BarDateType;
  position: BarSelectedCoords;
  rows: any | DevicesConsumptionNode | null;
  title?: string | null;
  total?: string;
  type?: string;
  estimated?: boolean;
}

const initialState: TooltipState = {
  category: 'price',
  position: { x: 0, y: 0 },
  rows: null,
  title: null,
  total: 'n/a',
  type: 'basic',
  estimated: false,
};

const updateTooltipHandler = (state: any, action: PayloadAction<TooltipState>) => {
  const { type, title, position, rows, category, total, barDate, estimated } = action.payload;

  state.category = category; // Se usa solo en Devices...
  state.position = position;
  state.rows = rows;
  state.title = title || null;
  state.total = total || 'n/a';
  state.type = type || initialState.type;
  state.barDate = barDate;
  state.estimated = estimated;
};

export const tooltipSlice = createSlice({
  name: 'tooltip',
  initialState,
  reducers: { updateTooltip: updateTooltipHandler },
});

export const { updateTooltip } = tooltipSlice.actions;

export default tooltipSlice.reducer;
