import { baseBar } from './base-bar';
import { ChartCategory, ContractType, SupplyType } from '@/ui-toolkit/common/types';
import { ejpBar } from './ejp-bar';
import { HPHC, EJP, TEMPO } from '@utils/constants/contract-types';
import { hphcBar } from './hphc-bar';
import { KeyValueNumber } from '../Consumption.utils';
import { TariffNode } from '../../Consumption.interfaces';
import { tempoBar } from './tempo-bar';

export interface generateBarsProps {
  category: ChartCategory;
  contract: ContractType;
  peak_hours_can_be_used: boolean;
  max: number;
  node: TariffNode;
  supplyType: SupplyType;
  averageSubscription: number;
}

export interface generateBarsReturn {
  percentages: KeyValueNumber;
  totalPercentage: number;
  disabled?: boolean;
  estimated?: boolean;
}

export const generateSingleBar = (props: generateBarsProps): generateBarsReturn => {
  let barsGenerator;

  switch (props.contract) {
    case EJP:
      barsGenerator = ejpBar;
      break;
    case HPHC:
      barsGenerator = hphcBar;
      break;
    case TEMPO:
      barsGenerator = tempoBar;
      break;
    default:
      barsGenerator = baseBar;
      break;
  }

  return barsGenerator(props);
};
