import axios from 'axios';
import { call, takeLatest, put } from 'redux-saga/effects';

import { ENDPOINTS } from '@utils/constants/endpoints';
import { ProfileEntity } from './Profile.interfaces';
import { runtimeConfig } from '@/runtime-config';
import { STATUS } from '@utils/constants/status';
import { updateProfile, updateProfileError, updateProfileIsLoading } from './ProfileSlice';

const { APP_API_URL } = runtimeConfig;

export type requestEmailContactProps = {
  type: typeof profileActions.REQUEST_EMAIL_CONTACT;
  email: string;
  callback: (v: any) => void;
};

export const profileActions = {
  ADD_NEW_ADRESS: 'ADD_NEW_ADRESS',
  GET_USER_PROFILE: 'GET_USER_PROFILE',
  POST_ADDRESS_FEATURES: 'POST_ADDRESS_FEATURES',
  POST_ADDRESS_NOTIFICATIONS: 'POST_ADDRESS_NOTIFICATIONS',
  REQUEST_EMAIL_CONTACT: 'REQUEST_EMAIL_CONTACT',
  UPDATE_ADDRESS: 'UPDATE_ADDRESS',
};

export function* getProfile(): any {
  try {
    yield put(updateProfileIsLoading(true));
    yield put(updateProfileError(null));

    const response = yield call(axios.get, APP_API_URL + ENDPOINTS.USER_PROFILE);

    const { data } = response.data;

    const profile: ProfileEntity = {
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      customerReferred: data.customer_referred,
      addresses: data.addresses,
    };

    yield put(updateProfile(profile));
    yield put(updateProfileIsLoading(false));

    localStorage.setItem('selectra-user-id', data.id || 'n/a');
  } catch (error: any) {
    yield put(updateProfileError(error?.response?.status || 500));
    yield put(updateProfileIsLoading(false));
  }
}

export function* postAddressFeaturesSaga({ addressId, values, callback }: any): any {
  try {
    yield put(updateProfileIsLoading(true));
    const endpoint = `${ENDPOINTS.ADDRESS_API}/${addressId}/features`;

    yield call(axios.post, endpoint, { ...values });
    yield call(getProfile);

    callback(STATUS.SUCCESS);
  } catch (error) {
    console.error(error);

    callback(STATUS.SERVER_ERROR);
  } finally {
    yield put(updateProfileIsLoading(false));
  }
}

export function* postAddressNotificationsSaga({ addressId, values, callback }: any) {
  try {
    const endpoint = `${ENDPOINTS.ADDRESS_API}/${addressId}/notifications`;

    yield call(axios.post, endpoint, { ...values });
    yield call(getProfile);

    callback(200);
  } catch (error) {
    console.error(error);
    callback(404);
  }
}

export function* addNewAddress({ values, callback }: any): any {
  try {
    yield call(axios.post, ENDPOINTS.ADDRESS_API, { ...values });

    callback('add-new-address-success');
  } catch (error: any) {
    console.error(error);

    if (values?.pce && error?.status === 409) {
      callback('pce-in-use');
      return;
    }

    callback('add-new-address-error');
  }
}

export function* updateAddress({ addressId, name, callback }: any): any {
  try {
    const endpoint = `${runtimeConfig.APP_API_URL}${ENDPOINTS.ADDRESS_API}/${addressId}/edit`;
    yield call(axios.put, endpoint, { name });

    callback('update-address-success');
  } catch (error: any) {
    console.error(error);

    callback('update-address-error');
  }
}

export function* requestEmailContact({ email, callback }: requestEmailContactProps): any {
  try {
    yield call(axios.post, ENDPOINTS.REQUEST_EMAIL_CONTACT, { email });

    callback('request-email-contact-success');
  } catch (error: any) {
    console.error(error);

    // callback('request-email-contact-error'); // TODO: Descomentar esto cuando esté la API.
    callback('request-email-contact-success');
  }
}

export default function* profileSagas() {
  yield takeLatest(profileActions.ADD_NEW_ADRESS, addNewAddress);
  yield takeLatest(profileActions.GET_USER_PROFILE, getProfile);
  yield takeLatest(profileActions.POST_ADDRESS_FEATURES, postAddressFeaturesSaga);
  yield takeLatest(profileActions.POST_ADDRESS_NOTIFICATIONS, postAddressNotificationsSaga);
  yield takeLatest(profileActions.REQUEST_EMAIL_CONTACT, requestEmailContact);
  yield takeLatest(profileActions.UPDATE_ADDRESS, updateAddress);
}
