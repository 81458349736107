import Menu from '@/containers/Common/Menu/Menu';
import useAppTypeFlag from '@/shared/hooks/useAppTypeFlag';
import useLoginTracking from '@/shared/hooks/useLoginTracking';
import useSourceParam from '@hooks/useSourceParam';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import FloatingMcp from '../MenuDesktop/components/FloatingMcp/FloatingMcp';
import styles from './Layout.module.scss';
import useGoogleToken from '@/shared/hooks/useGoogleToken';

export interface LayoutProps {
  children: any;
}

const Layout = ({ children }: LayoutProps) => {
  useLoginTracking();
  useSourceParam();
  useAppTypeFlag();
  useGoogleToken();

  return (
    <div className={styles.Layout}>
      <FloatingMcp />

      <div className={styles.Layout__wrapper}>
        <div className={styles.Layout__wrapper__menu}>
          <Menu />
        </div>

        <div className={styles.Layout__wrapper__content}>
          <Header />

          {children}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Layout;
