import { BASE } from '@utils/constants/contract-types';
import { calculateComparison } from './Comparison.utils';
import { calculateMaxConsumptionOfThePeriod, calculateTaxes } from './Consumption.utils';
import { calculateTotalConsumption, clone } from './Consumption.utils';
import { CONSUMPTION, PRICE } from '@utils/constants/category-types';
import { ConsumptionNode } from '../Consumption.interfaces';
import { ContractType, SupplyType } from '@/ui-toolkit/common/types';
import { generateSingleBar, generateBarsProps, generateBarsReturn } from './bars/generate-bars';
import { getTooltip } from './tooltips/generate-tooltip';
import { getXAxisByPeriod, getYaxis } from './Axis.utils';
import { KeyValueNumber, KeyValueString } from './Consumption.utils';
import { Period } from '../Consumption.interface';

export interface ChartSetup {
  id: string;
  consumption: ChartSetupNode;
  comparison: number;
  price: ChartSetupNode;
  taxes: string | undefined;
}

export interface ChartSetupNode {
  bars: { percentages: KeyValueNumber; disabled?: boolean; date: string }[];
  tooltip: { title: string; rows: KeyValueString; total: string }[];
  xaxis: string[];
  yaxis: string[];
  total: string;
}

export const setupConsumptionChart = ({ data, supplyType }: { data: any; supplyType: SupplyType }) => {
  if (!data?.consumption) {
    return null;
  }

  const allNodes: ConsumptionNode[] = data.consumption;
  const contract: ContractType = data.contract_details?.type || BASE;
  const has_peak_hours: boolean = data.contract_details?.has_peak_hours;
  const peak_hours_can_be_used: boolean = data.contract_details?.peak_hours_can_be_used;
  const period: Period = data.period;
  const averageSubscription = Number((data.prices.subscription / 365).toFixed(2));
  const common = { has_peak_hours, peak_hours_can_be_used, period, supplyType, contract, averageSubscription };

  // ----------------------------
  // Iterate ALL received nodes:
  // ----------------------------
  const chartSetupArray = allNodes.map((node, index) => {
    const barsProvider = Object.keys(node.values).map(key => ({ date: key, ...node.values[key] }));

    const setup: ChartSetup = {
      id: barsProvider[0].date + '__' + barsProvider[barsProvider.length - 1].date,
      comparison: 0,
      consumption: { bars: [], xaxis: [], yaxis: [], tooltip: [], total: '0' },
      price: { bars: [], xaxis: [], yaxis: [], tooltip: [], total: '0' },
      taxes: undefined,
    };

    // -------
    // Totals:
    // -------
    const totals = calculateTotalConsumption({ contract, supplyType, node });
    setup.price.total = totals.price;
    setup.consumption.total = totals.consumption;

    // -------
    // Taxes:
    // -------
    setup.taxes = calculateTaxes({ total_price: node.total_price });

    // ------------
    // Comparison:
    // ------------
    setup.comparison = calculateComparison({ contract, prevNode: allNodes[index + 1], currNode: node });

    // -------
    // Max:
    // -------
    const priceMax = calculateMaxConsumptionOfThePeriod(barsProvider, 'price', contract, supplyType);
    const consMax = calculateMaxConsumptionOfThePeriod(barsProvider, 'consumption', contract, supplyType);

    // -------
    // X Axis:
    // -------
    const xaxis = getXAxisByPeriod({ period, values: node.values });
    setup.price.xaxis = xaxis;
    setup.consumption.xaxis = xaxis;

    // -------
    // Y Axis:
    // -------
    setup.price.yaxis = getYaxis(priceMax, 'price');
    setup.consumption.yaxis = getYaxis(consMax, 'consumption');

    // -------------------
    // Bars & tooltips:
    // -------------------
    let mostRecentPriceBarEnable: generateBarsReturn | null = null;

    barsProvider.forEach(({ price, consumption, date, is_disabled, is_estimated }) => {
      // -------
      // Price:
      // -------
      const priceProps = { category: PRICE, max: priceMax, node: clone(price), ...common } as generateBarsProps;
      let priceBar = generateSingleBar(priceProps);
      priceBar.disabled = is_disabled;

      if (is_disabled) {
        if (mostRecentPriceBarEnable) priceBar = { ...(mostRecentPriceBarEnable as any) };
        priceBar.disabled = is_disabled;
      } else {
        mostRecentPriceBarEnable = priceBar;
      }

      setup.price.bars.push({ ...priceBar, date });

      const priceTooltip = getTooltip({ category: PRICE, date, node: price, ...common }) as any;
      priceTooltip.estimated = is_estimated;
      setup.price.tooltip.push(priceTooltip);

      // -------------
      // Consumption:
      // -------------
      const consumptionProps = { category: CONSUMPTION, max: consMax, node: clone(consumption), ...common } as any;
      const consumptionBar = generateSingleBar(consumptionProps);
      consumptionBar.disabled = is_disabled;
      setup.consumption.bars.push({ ...consumptionBar, date });

      const consumptionTooltip = getTooltip({ category: CONSUMPTION, date, node: consumption, ...common }) as any;
      consumptionTooltip.estimated = is_estimated;
      setup.consumption.tooltip.push(consumptionTooltip);
    });

    return setup;
  });

  return chartSetupArray;
};
