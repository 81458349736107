import { createPriceDisabledBar } from './disabled-bar';
import { EJP } from '@utils/constants/contract-types';
import { generateBarsProps, generateBarsReturn } from './generate-bars';
import { perc } from '../Consumption.utils';
import { PRICE, CONSUMPTION } from '@utils/constants/category-types';

export const ejpBar = (props: generateBarsProps): generateBarsReturn => {
  const { category, node, max, averageSubscription } = props;
  const { base, subscription, ejp } = node;

  if (!ejp) {
    return createPriceDisabledBar(averageSubscription, max, EJP);
  }

  let sum = 0;
  const { red, blue } = ejp;

  if (base + red + blue === 0) {
    return createPriceDisabledBar(averageSubscription, max, EJP);
  }

  if (category === PRICE) {
    // Check if node is empty:
    if (red + blue === 0) {
      sum = base + subscription;

      // Return BASE:
      return {
        percentages: {
          base: perc(base, sum),
          subscription: perc(subscription, sum),
        },
        totalPercentage: perc(sum, max),
      };
    }

    // Node has values:
    sum = red + blue + subscription;
    return {
      percentages: {
        red: perc(red, sum),
        blue: perc(blue, sum),
        subscription: perc(subscription, sum),
      },
      totalPercentage: perc(sum, max),
    };
  }

  if (category === CONSUMPTION) {
    // Check if node is empty:
    if (red + blue === 0) {
      // Return BASE:
      return {
        percentages: {
          base: 100,
        },
        totalPercentage: perc(base, max),
      };
    }

    // Node has values:
    sum = red + blue;
    return {
      percentages: {
        red: perc(red, sum),
        blue: perc(blue, sum),
      },
      totalPercentage: perc(sum, max),
    };
  }

  // Default:
  return {
    percentages: {},
    totalPercentage: 0,
  };
};
