import ButtonIcon from '@/ui-toolkit/components/ButtonIcon/ButtonIcon';
import { updateModalComponent } from '@modal/ModalSlice';
import { useDispatch, useSelector } from 'react-redux';
import styles from './FloatingMcp.module.scss';

import { selectAuthToken } from '@/store/Common/Auth/AuthSelectors';
import { selectCurrentDeviceType } from '@/store/Common/WindowResizeTool/WindowResizeToolSelector';
import IconPhone from '@icons/IconPhone';

const FloatingMcp = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectAuthToken);
  const { isMobile } = useSelector(selectCurrentDeviceType);

  const openModal = () => {
    dispatch(updateModalComponent({ component: 'myPersonalAdviser' }));
  };

  if (!token || !isMobile) {
    return null;
  }

  return (
    <div className={styles.FloatingMcp}>
      <ButtonIcon id='button-mcp-cta-mobile' icon={<IconPhone />} onClick={openModal} variant='primary' border='pill' />
    </div>
  );
};

export default FloatingMcp;
