import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FavouritesCountItem {
  [tipId: string]: number;
}

export interface ViewsCountList {
  [tipId: string]: { count: number; alreadyViewed: boolean };
}

export interface FavouritesState {
  countList: FavouritesCountItem;
  userFavourites: string[];
  views: ViewsCountList;
}

const initialState: FavouritesState = {
  countList: {},
  userFavourites: [],
  views: {},
};

const fillFavouritesStateHandler = (state: FavouritesState, action: PayloadAction<{ tipsList: TipsItem[] }>) => {
  const countList: FavouritesCountItem = {};
  const userFavourites: string[] = [];
  const views: ViewsCountList = {};

  // Append to the dictionaries after receiving the api response tips list:
  action.payload.tipsList.forEach((tip: TipsItem) => {
    countList[tip.id] = tip.number_favourites || 0;

    views[tip.id] = { count: tip.number_views || 0, alreadyViewed: false };

    tip.is_favourite && userFavourites.push(tip.id);

    // Do the same with the nested related items:
    tip?.related?.forEach((related: TipsItem) => {
      if (!countList[related.id]) countList[related.id] = related.number_favourites || 0;

      if (!views[related.id]) views[related.id] = { count: related.number_views || 0, alreadyViewed: false };

      if (userFavourites.indexOf(related.id) === -1 && related.is_favourite) userFavourites.push(related.id);
    });
  });

  state.countList = countList;
  state.userFavourites = userFavourites;
  state.views = views;
};

const updateFavouriteHandler = (state: FavouritesState, action: PayloadAction<{ tipId: string }>) => {
  const { tipId } = action.payload;
  const index = state.userFavourites.indexOf(tipId);
  const operation = index === -1 ? 'add' : 'remove';

  // Add or subtract a unit by id:
  const currentCount = state.countList[tipId] || 0;
  const nextCount = operation === 'add' ? currentCount + 1 : currentCount - 1;
  state.countList[tipId] = nextCount;

  // Add or remove a user favorite:
  operation === 'add' ? state.userFavourites.push(tipId) : state.userFavourites.splice(index, 1);
};

const updateTipViewCountHandler = (state: FavouritesState, action: PayloadAction<{ tipId: string }>) => {
  const { tipId } = action.payload;

  state.views[tipId] = {
    count: (state.views[tipId]?.count || 0) + 1,
    alreadyViewed: true,
  };
};

export const favouritesSlice = createSlice({
  name: 'favourites',
  initialState,
  reducers: {
    fillFavouritesState: fillFavouritesStateHandler,
    updateFavourite: updateFavouriteHandler,
    updateTipViewCount: updateTipViewCountHandler,
    clearFavourites: () => initialState,
  },
});

export const { updateFavourite, fillFavouritesState, updateTipViewCount, clearFavourites } = favouritesSlice.actions;

export default favouritesSlice.reducer;
