import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateDrawerComponent } from '@/containers/Common/Drawer/DrawerSlice';
import { updateCurrentDeviceType } from '@/store/Common/WindowResizeTool/WindowResizeToolSlice';
import { clearGas } from '@/containers/Consumption/Gas/state/GasSlice';
import { clearElectricity } from '@/containers/Consumption/Electricity/state/ElectricitySlice';

const useAppInitializer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Reset drawer:
    dispatch(updateDrawerComponent({ componentId: null }));

    // Reset consumption:
    dispatch(clearElectricity());
    dispatch(clearGas());

    // Setup device type tool:
    const handleResize = () => dispatch(updateCurrentDeviceType(window.innerWidth));
    handleResize();
    window.addEventListener('resize', handleResize);

    // Stop all listenners when app close:
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
};

export default useAppInitializer;
