import { useEffect } from 'react';

const PRODUCTION_URL = 'https://my.selectra.com';
const STAGING_URL = 'https://customer-space.s3.staging.aws.selectra.io';
const DEVELOP_URL = 'https://1db0-88-26-68-114.ngrok-free.app';

const useAppleLogin = () => {
  useEffect(() => {
    const redirectURI = (() => {
      const environment = import.meta.env.VITE_APP_ENV;

      if (environment === 'development') {
        return DEVELOP_URL;
      }

      if (environment === 'production') {
        return PRODUCTION_URL;
      }

      return STAGING_URL;
    })();

    const initApple = () => {
      window.AppleID.auth.init({
        clientId: 'com.selectra.apple.signin',
        scope: 'name email',
        redirectURI,
        state: 'origin:web',
        usePopup: true,
      });
    };

    const onSuccess = (event: any) => {
      const user = event?.detail?.user;

      console.log('Apple login success', user);

      if (user) {
        localStorage?.setItem('selectra-apple-login', JSON.stringify(user));
      }
    };

    const onError = (event: any) => {
      console.log('Apple login error: ', event.detail.error);
    };

    document.addEventListener('AppleIDSignInOnSuccess', onSuccess);
    document.addEventListener('AppleIDSignInOnFailure', onError);

    try {
      initApple();
    } catch (error) {
      console.error(error);
    }

    return () => {
      document.removeEventListener('AppleIDSignInOnSuccess', onSuccess);
      document.removeEventListener('AppleIDSignInOnFailure', onError);
    };
  }, []);
};

export default useAppleLogin;
