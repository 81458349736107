import { STATUS } from '@utils/constants/status';
import { EjpVariant, StatusType } from '@/ui-toolkit/common/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface EjpTempoApiResponse {
  color_today: EjpVariant;
  color_tomorrow: EjpVariant;
  red_remaining_days: number;
  white_remaining_days: number;
}

export interface EjpTempoState {
  isLoading: boolean;
  status: StatusType;
  data: EjpTempoApiResponse | null;
}

const initialState = {
  isLoading: false,
  status: STATUS.PENDING,
  data: null,
};

const updateEjpTempoDataHandler = (state: EjpTempoState, action: PayloadAction<EjpTempoApiResponse>) => {
  state.data = action.payload;
};

const updateEjpTempoStatusHandler = (state: EjpTempoState, action: PayloadAction<StatusType>) => {
  state.status = action.payload;
};

export const ejpTempoSlice = createSlice({
  name: 'ejpTempo',
  initialState,
  reducers: {
    updateEjpTempoData: updateEjpTempoDataHandler,
    updateEjpTempoStatus: updateEjpTempoStatusHandler,
    clearejpTempo: () => initialState,
  },
});

export const { updateEjpTempoData, updateEjpTempoStatus, clearejpTempo } = ejpTempoSlice.actions;

export default ejpTempoSlice.reducer;
