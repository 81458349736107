const IconPhone = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_46_1255)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25 -1H24V0H25V-1ZM13.663 19.562C15.7043 20.5207 17.8 21 19.95 21C20.25 21 20.5 20.9 20.7 20.7C20.9 20.5 21 20.25 21 19.95V15.9C21 15.6667 20.925 15.4623 20.775 15.287C20.625 15.1123 20.4333 15 20.2 14.95L16.75 14.25C16.5167 14.2167 16.2793 14.2373 16.038 14.312C15.796 14.3873 15.6 14.5 15.45 14.65L13.1 17C11.8333 16.2333 10.671 15.325 9.613 14.275C8.55433 13.225 7.675 12.1 6.975 10.9L9.4 8.45C9.55 8.3 9.646 8.129 9.688 7.937C9.72933 7.74567 9.73333 7.53333 9.7 7.3L9.05 3.8C9.01667 3.56667 8.90833 3.375 8.725 3.225C8.54167 3.075 8.33333 3 8.1 3H4.05C3.75 3 3.5 3.1 3.3 3.3C3.1 3.5 3 3.75 3 4.05C3 6.2 3.47933 8.29567 4.438 10.337C5.396 12.379 6.66267 14.1873 8.238 15.762C9.81267 17.3373 11.621 18.604 13.663 19.562ZM7.675 7.35L6.025 9C5.74167 8.35 5.525 7.69167 5.375 7.025C5.225 6.35833 5.10833 5.68333 5.025 5H7.25L7.675 7.35ZM19 16.75V18.95C18.3167 18.9 17.6377 18.7917 16.963 18.625C16.2877 18.4583 15.625 18.2333 14.975 17.95L16.65 16.275L19 16.75Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_46_1255'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconPhone;
