import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { selectAuthToken } from '@/store/Common/Auth/AuthSelectors';
import { profileActions } from '@/store/Common/Profile/ProfileSagas';
import { selectProfile } from '@/store/Common/Profile/ProfileSelectors';
import { updateModalComponent } from '../Modal/ModalSlice';

const useHeader = () => {
  const dispatch = useDispatch();
  const [showShadow, setShowShadow] = useState(false);
  const token = useSelector(selectAuthToken) || localStorage?.getItem('selectra-auth');
  const { pathname } = useLocation();
  const profile = useSelector(selectProfile);

  useEffect(() => {
    const handlePageScroll = () => setShowShadow(window.scrollY > 0);
    window.addEventListener('scroll', handlePageScroll);

    return () => {
      window.removeEventListener('scroll', handlePageScroll);
    };
  }, []);

  useEffect(() => {
    if (!token || profile?.data !== null) return;

    const timeout = setTimeout(() => dispatch({ type: profileActions.GET_USER_PROFILE }), 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [token, pathname]);

  const openModal = () => {
    dispatch(updateModalComponent({ component: 'myPersonalAdviser' }));
  };

  const openLoginModal = () => {
    dispatch(updateModalComponent({ component: 'loginModal', props: { goTopOnClose: true, wide: 'sm' } }));
  };

  const openRegisterModal = () => {
    dispatch(updateModalComponent({ component: 'accountCreation' }));
  };

  const openLogoutModal = () => {
    dispatch(updateModalComponent({ component: 'logoutModal', props: { goTopOnClose: true } }));
  };

  return {
    isLoggedIn: token,
    showShadow,
    openLogoutModal,
    openLoginModal,
    openModal,
    openRegisterModal,
  };
};

export default useHeader;
