import { authActions } from '@/containers/Auth/LoginModal/LoginSagas';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const useAppTypeFlag = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const userDeviceIsMobile = searchParams.get('mobile') === 'true';

    if (userDeviceIsMobile) {
      localStorage?.setItem('selectra-app-type', 'mobile');
    }

    const googleParam = searchParams.get('show_google_auth');
    // const googleCookie = localStorage?.getItem('selectra-show-google-auth') || 'null';

    if (googleParam === null || googleParam === undefined) {
      // do nothing?
      //
    } else {
      localStorage?.setItem('selectra-show-google-auth', googleParam);
    }

    // NATIVE GOOGLE AUTH:
    const nativeToken = searchParams.get('token');

    if (userDeviceIsMobile && nativeToken) {
      dispatch({ type: authActions.LOGIN_WITH_GOOGLE, access_token: nativeToken, source: 'native-app-login' });
    }
  }, [searchParams]);
};

export default useAppTypeFlag;
