import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateModalComponent } from '@/containers/Common/Modal/ModalSlice';
import { ProfileEntity } from '@/store/Common/Profile/Profile.interfaces';
import { selectProfileData } from '@/store/Common/Profile/ProfileSelectors';

const useLastNameDetection = () => {
  const dispatch = useDispatch();
  const profile: ProfileEntity | null = useSelector(selectProfileData);

  useEffect(() => {
    if (!profile) {
      return;
    }

    if (!profile?.lastName || profile.lastName.length === 0 || !profile?.firstName || profile.firstName.length === 0) {
      dispatch(updateModalComponent({ component: 'updateProfileModal', props: { preventClose: true } }));
    }
  }, [profile]);

  return {};
};

export default useLastNameDetection;
